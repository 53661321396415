const popupInformation = {
    MobilityIndicators: {
        nbHabitants: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre d'habitants est issu d'une projection de données à partir des recensements généraux de population du HCP et en analysant l'évolution de la tâche urbaine de la conurbation.
                </p>
                <p class="popup-information-text-conurbation">Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi</p>
                `,
                source: `<p class="popup-information-text-source">RGPH HCP  + Images aériennes</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The population figure is derived from a data projection based on the general population censuses conducted by the HCP and by analyzing the urban development trends within the conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">RGPH (General Population and Housing Census) by HCP (High Commission for Planning) + Aerial images</p`,
            },
        },
        tauxCroissanceAnnualise: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le taux de croissance annualisé est issu des projections de croissance à partir du dernier recensement général de population du HCP.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">RGPH HCP</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The annualized growth rate is derived from growth projections based on the latest general population census conducted by the HCP.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">RGPH (General Population and Housing Census) by HCP (High Commission for Planning)</p`,
            },
        },
        kmBatisConstruits: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre de km² construits correspond au nombre de km² urbanisés (surface au sol), identifiés par image satellite dans la conurbation* et par préfecture.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">image aérienne</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The number of Square kilometers built corresponds to the number of urbanized square kilometers (ground surface), identified by aerial imagery in the conurbation* and by prefecture.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Aerial images</p`,
            },
        },
        partActifsHabitants: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre d'actifs correspond aux personnes résidant dans  la conurbation* ayant un emploi à temps partiel ou à temps plein, ou en apprentissage.
                 Les actifs non occupés tels que les chômeurs ne sont pas pris en compte.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The number of active individuals refers to residents of the conurbation* who are employed either part-time or full-time, or engaged in an apprenticeship. Unoccupied active individuals such as the unemployed are not considered.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household Mobility Survey</p`,
            },
        },
        densiteNette: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                La densité nette correspond au nombre de km² construits (surface au sol) divisé par la superficie du territoire (soit la conurbation*, soit la préfecture).
                Cet indicateur permet d'observer l'évolution de l'urbanisation et l'étalement urbain, pour savoir si la ville grandit en se densifiant et/ou en s'étendant.
                Une ville compacte utilisera moins d'espace et il sera plus facile d'y développer des transports efficaces. Les temps de parcours seront réduits ainsi que les émissions.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">image aérienne</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                Net density is the number of built square kilometers (ground surface) divided by the total land area (either of the conurbation* or the prefecture). This indicator is used to observe the evolution of urbanization and urban sprawl, to determine whether the city is growing by densification and/or expansion. A compact city will use less space, making it easier to develop efficient transportation systems. Travel times and emissions will be reduced as a result.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Aerial images</p`,
            },
        },
        nbEmplois: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre d'emplois dans Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                <p>
                Cet indicateur sert à observer les pôles d'attractivité et générateurs de déplacements dans la conurbation.
                </p>
                <p class="popup-information-text-conurbation">Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi</p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The number of jobs in Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>
                <p class="popup-information-text-speech">
                This indicator is used to observe the poles of attraction and trip generators within the conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household travel survey</p`,
            },
        },
        nbPersonnesMenage: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre de personnes par ménage correspond au nombre moyen de personnes vivant dans un même foyer.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The number of people per household corresponds to the average number of individuals living in the same housing.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household Mobility survey</p`,
            },
        },
        partPopulationDesserteTc: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit de la part de la population résidant à moins de 500 mètres d'une desserte de lignes en transports en commun (stations tramway, arrêts de bus) de fréquence inférieure ou égale à 10 minutes.
                C'est un indicateur stratégique pour rendre compte de l'efficacité et de l'attractivité des transports publics.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">croisement de données cartographiques des réseaux de transports avec la répartition de la population (estimée à partir des données RGPH HCP et de l'analyse d'images aériennes). </p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                It refers to the proportion of the population living within 500 meters of a public transportation service (tramway stations, bus stops) with a frequency of less than or equal to 10 minutes. It's a strategic indicator for assessing the efficiency and attractiveness of public transportation.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Cross-referencing of transportation network data with population distribution (estimated from RGPH HCP data and analysis of aerial images)</p`,
            },
        },
        vitesseCommercialeTransportPublic: {
            fr: {
                speech: `<p>
                </p>
                <p>
                </p>
                `,
                source: `<p class="popup-information-text-source"></p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                </p>
                <p class="popup-information-text-conurbation">
                </p>`,
                source: `<p class="popup-information-text-source">source to be translated</p`,
            },
        },
        nbPetitsGrandsTaxis: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du nombre de véhicules immatriculés dans  la conurbation*. Le lieu d'immatriculation ne correspond pas nécessairement à la zone où le taxi circule.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">Source de données : divisions économiques et sociales des préfectures de Rabat, Salé et Skhirat-Témara</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the number of registered vehicles within the conurbation*. The place of registration may not necessarily correspond to the area where the taxi operates.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Economic and social divisions within the prefectures of Rabat, Salé, and Skhirat-Témara.</p`,
            },
        },
        parcBusElectrique: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit de la flotte de bus opérée dans  la conurbation* par le secteur public (depuis 2018 : Alsa-City bus). Ainsi la flotte du transport de personnel ou scolaire n'est pas comptée.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">exploitant</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the fleet of buses operated within the conurbation* by the public sector (since 2018: Alsa-City bus). Therefore, the fleet for corporate or school transportation is not included.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Operator data </p`,
            },
        },
        kmTcsp: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du nombre de km de voies de transport en commun en site propre (TCSP), c'est-à-dire des modes de transport rapides avec voies réservées tels que le tramway et le BHNS (ou ligne express).
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This is the number of kilometers of dedicated lanes for public transportation, which are rapid transit modes with reserved lanes such as tramways and Bus Rapid Transit (BRT) or express bus lines.
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p`,
            },
        },
        kmVoiesCyclables: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du linéaire de voies cyclables existantes.
                </p>
                `,
                source: `<p class="popup-information-text-source"></p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the length of existing bicycle paths.
                </p>
                `,
                source: `<p class="popup-information-text-source"></p`,
            },
        },
        nbBornesRechargePublique: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du nombre de bornes de recharge électrique accessibles sur la voie publique.
                </p>
                `,
                source: `<p class="popup-information-text-source"></p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the number of publicly accessible electric charging stations on the public road network.
                </p>
                `,
                source: `<p class="popup-information-text-source"></p`,
            },
        },
        nbParkingsOuvrage: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du nombre de parkings souterrains ou en silos dans la conurbation*. Les parkings en surface ne sont donc pas comptés, tels que ceux sur voirie.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the number of underground or silo parking facilities within the conurbation*. Surface parking lots, such as those along streets, are not included.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">RRM</p`,
            },
        },
        nbPlacesParkingsOuvrage: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit de la capacité des parkings souterrains ou en silos dans la conurbation*. Les places dans les parkings en surface ne sont donc pas comptées, tels que celles en voirie.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the capacity of underground or silo parking facilities within the conurbation*. Surface parking lots, such as those along streets, are not included.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">RRM</p`,
            },
        },
        nbParcsRelais: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Les parcs relais sont des parkings situés au niveau d'une desserte en transports commun offrant un service de stationnement combiné à un ticket de transport. Exemple pour les parcs relais situés aux terminus des tramway, le stationnement y est gratuit sur justificatif d'un ticket acheté.
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                Park-and-ride facilities are parking areas located at public transportation hubs, offering combined parking and transit ticket services. For example, at park-and-ride facilities located at tramway terminals, parking is free upon presentation of a purchased ticket.
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p`,
            },
        },
        nbPlacesParcsRelais: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du nombre de places dans les parcs relais.
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This refers to the number of spaces available in park-and-ride facilities.
                </p>
                `,
                source: `<p class="popup-information-text-source">RRM</p`,
            },
        },
        tauxImmobilite: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le taux d'immobilité correspond à la part de la population n'effectuant aucun déplacement durant la journée. C'est le cas pour certaines personnes travaillant dans leur foyer (y compris télétravail), les personnes âgées,  les enfants ou lorsque des personnes tombent malade.
                Distinguer les taux d'immobilité selon le genre renseigne sur les dynamiques sociales et de mobilité des territoires.  Dans la société marocaine, les femmes jouent un rôle central dans la sphère privée et dans la gestion de la vie familiale avec comme effet une vie locale, à proximité du domicile, plus marquée chez les femmes et une mobilité liée au travail beaucoup plus marquée chez les hommes.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The immobility rate corresponds to the proportion of the population not making any trips during the day. This includes individuals working from home (including Remote), the elderly, children, or when individuals fall ill.
Distinguishing immobility rates by gender provides insight into the social and mobility dynamics of territories. In Moroccan society, women play a central role in the private sphere and in managing family life, resulting in a more localized life near the home for women and a much more pronounced work-related mobility for men.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household Mobility survey</p`,
            },
        },
        distanceMoyenneDeplacements: {
            fr: {
                speech: `<p>
                </p>
                <p>
                </p>
                `,
                source: `<p class="popup-information-text-source"></p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                </p>
                <p class="popup-information-text-conurbation">
                </p>`,
                source: `<p class="popup-information-text-source">source to be translated</p`,
            },
        },
        frequentationTransportPublic: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                La fréquentation des transports en commun (TC) relatifs au tramway et au bus correspond au nombre de voyages effectués par jour sur les lignes du réseau incluant les correspondances.
                Aide à la lecture : en 2022, 105000 voyages en tramway ont été effectués chaque jour en moyenne durant l'année.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">exploitants</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">
                The ridership of public transportation (PT) corresponds to the number of journeys made per day on the network lines including connections.
                </p>
                <p class="popup-information-text-speech">
                Reading assistance: In 2022, an average of 105,000 tram trips were made per day throughout the year.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Operators data</p`,
            },
        },
        motorisation: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Les taux de motorisation correspondent au nombre de véhicules possédés par les ménages de la conurbation*. Il s'agit des voitures ou camionnettes pour le taux automobile, et des motos, scooters ou équivalents motorisés pour le taux de deux-roues motorisés. Il est exprimé en nombre de véhicules pour 1000 habitants.
                Le taux de motorisation automobile est un indicateur essentiel pour déterminer l'importance du véhicule individuel dans le territoire. Il suit souvent les tendances du pouvoir intérieur brut (PIB) : plus il est élevé, plus il y aura de véhicules. Avec la conjoncture actuelle (hausse des prix du carburant et des véhicules, transition énergétique), il est intéressant de suivre l'évolution du taux de motorisation en deux-roues motorisés où il est possible d'observer un report modal de la voiture vers ce mode moins onéreux pour les ménages.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The motorization rates correspond to the number of vehicles owned by households in the conurbation*. It means cars or vans for the automobile rate, and motorcycles, scooters, or similar motorized vehicles for the motorized two-wheelers rate. It is expressed as the number of vehicles per 1000 inhabitants.
                The car ownership rate is a key indicator for determining the importance of individual vehicles in a territory. It often follows trends in Gross Domestic Product (GDP): the higher it is, the more vehicles there will be. With the current situation (rise of fuel and vehicle prices, energy transition), tracking the evolution of the motorized two-wheelers ownership rate might be significant to observe a possible modal shift from cars to this less expensive mode for households.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household Mobility survey</p`,
            },
        },
        tauxMobiliteQuotidien: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le taux de mobilité quotidien correspond au nombre de déplacements effectués par jour par les habitants âgés de 6 ans et plus (les moins de 6 ans n'étant pas interrogés dans l'enquête) de la conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The daily mobility rate corresponds to the number of trips made per day by inhabitants aged 6 and older (children under 6 are not surveyed) of the conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household Mobility survey</p`,
            },
        },
        repartitionModaleDeplacementsQuotidiens: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                La part modale des déplacements quotidiens correspond au nombre de déplacements effectués chaque jour dans  conurbation* par ses habitants pour chacun des modes existants.
                Cet indicateur permet d'observer l'équilibre entre les modes collectifs et capacitaires (le transport public notamment) et les modes non motorisés comme la marche et le vélo, VS  les modes motorisés tels que la voiture individuelle ou les deux-roues motorisés, générateurs de plus de nuisances (congestion, pollution, consommateurs d'espaces et de ressources). Suivre l'évolution des parts modales permet d'observer s'il y a un report modal.
                Habituellement, la part modale du transport public représente entre 10 et 60% de l'ensemble des modes.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The modal share of daily trips corresponds to the number of trips made each day in the conurbation* by its residents for each of the existing modes. This indicator shows the balance between collective and capacity modes (public transportation in particular) and non-motorized modes such as walking and cycling, considered as more virtuous modes, versus motorized modes such as private cars or motorized two-wheelers, which generate more nuisances (congestion, pollution, consumption of space and resources). Tracking the evolution of modal shares reveals if there is a modal shift.
                </p>
                <p class="popup-information-text-speech">
                Usually, the modal share of public transportation represent     s between 10 and 60% of all modes.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household travel survey</p`,
            },
        },
        nbDeplacementsQuotidiens: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le volume de déplacements quotidiens correspond au nombre total de trajets effectués chaque jour par les habitants de la conurbation*.
                Un déplacement correspond au trajet réalisé jusqu'à la destination finale pour un motif donné, peu importe le nombre de modes de transport utilisés lors de ce trajet. Ainsi un déplacement peut inclure plusieurs modes, avec des correspondances. En revanche, un déplacement ne peut avoir plusieurs motifs à la fois. Exemple de cas pour un parent allant au travail qui dépose son enfant à l'école durant son trajet en voiture, deux déplacements seront comptés : un premier du domicile à l'école pour le motif "Accompagnement" et un second pour le motif "Travail".
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The volume of daily trips corresponds to the total number of journeys made each day by the residents of the conurbation*. A trip refers to the journey made to the final destination for a given purpose, regardless of the number of modes of transportation used during that journey. Thus, a trip may include multiple modes, with connections. However, a trip cannot have multiple purposes at the same time. For example, in the case of a parent going to work who drops off their child at school during their car journey, two trips will be counted: the first from home to school for the "Accompanying" purpose and the second for the "Work" purpose.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">Household Mobility survey </p`,
            },
        },
        emissionsGes: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Les émissions de gaz à effet de serre (GES) sont estimées en prenant en compte le type de véhicule et sa consommation énergétique (Diesel, Essence, GNV, Hybride, Électrique), associée à un facteur d'émission par type d'énergie. Les émissions par mode sont ensuite combinées au nombre total de passagers par kilomètre. Ce nombre est ensuite divisé par la population totale pour obtenir un ratio de GES par habitant et par an. L'indicateur est exprimé en équivalent kgCO2/an/habitant.
                </p>
                `,
                source: `<p class="popup-information-text-source">Données officielles sur la consommation d'énergie et le facteur d'émission, Durée moyenne du trajet, répartition modale et occupation (EMD, comptages, données d'exploitation) par mode pour calculer le nombre de véhicules km par mode.</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                Greenhouse gas (GHG) emissions are estimated by taking into account the type of vehicle and its energy consumption (Diesel, Petrol, CNG, Hybrid, Electric), associated with an emission factor per type of energy. Emissions by mode are then combined with the total number of passengers per kilometer. This number is then divided by the total population to obtain a GHG ratio per capita per year.The indicator is expressed in equivalent kgCO2/year/inhabitant.
                </p>
                `,
                source: `<p class="popup-information-text-source">Official data on energy consumption and emission factors, Average journey duration, modal split, and occupancy (Household Mobility Survey, traffic counts, operational data) by mode to calculate vehicle kilometers per mode</p`,
            },
        },
        emissionsCo2: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Les émissions de gaz à effet de serre (GES) sont estimées en prenant en compte le type de véhicule et sa consommation énergétique (Diesel, Essence, GNV, Hybride, Électrique), associée à un facteur d'émission par type d'énergie. Les émissions par mode s'obtiennent ainsi en multipliant par le nombre de kilomètres effectués par an pour chaque véhicule.  L'indicateur est exprimé en équivalent kgCO2/an.
                </p>
                `,
                source: `<p class="popup-information-text-source">Données officielles sur la consommation d'énergie et le facteur d'émission, Durée moyenne du trajet, répartition modale et occupation (EMD, comptages, données d'exploitation) par mode pour calculer le nombre de véhicules km par mode.</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                Greenhouse gas (GHG) emissions are estimated by taking into account the type of vehicle and its energy consumption (Diesel, Petrol, CNG, Hybrid, Electric), associated with an emission factor per type of energy. Emissions by mode are thus obtained by multiplying the number of kilometers traveled per year for each vehicle. The indicator is expressed in equivalent kgCO2/year.
                </p>
                `,
                source: `<p class="popup-information-text-source">Official data on energy consumption and emission factors, Average journey duration, modal split, and occupancy (Household Mobility Survey, traffic counts, operational data) by mode to calculate vehicle kilometers per mode</p`,
            },
        },
        nbAccidents: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Cet indicateur renseigne le nombre d'accidents corporels (mortels, blessés graves, blessés légers) survenus sur le réseau routier dans la conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">NARSA</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                This indicator provides information on the number of injury accidents (fatal, serious injuries, minor injuries) occurring on the road network in the conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi.
                </p>`,
                source: `<p class="popup-information-text-source">NARSA</p`,
            },
        },
        budgetMenageMobilite: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du montant des revenus des ménages annuels et de la part du budget alloué à la mobilité tels que le coût des tickets de transport, le carburant, l'assurance automobile, etc. Les normes internationales recommandent pour les ménages modestes de ne pas dépasser les 10%.
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                It refers to the annual household income and the portion of the budget allocated to mobility expenses such as transportation tickets, fuel, car insurance, etc. International standards recommend that low-income households should not exceed 10% of their budget on transportation.
                </p>
                `,
                source: `<p class="popup-information-text-source">Household Mobility Survey</p`,
            },
        },
        tempsMoyenTransport: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Texte à définir
                </p>
                `,
                source: `<p class="popup-information-text-source">Source à définir</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                </p>
                <p class="popup-information-text-conurbation">
                </p>`,
                source: `<p class="popup-information-text-source">source to be translated</p`,
            },
        },
    },
    SumpTrajectory: {
        volumeDeplacementsJournaliersMode: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le volume de déplacements quotidiens correspond au nombre total de trajets effectués chaque jour par les habitants de la conurbation*.
                Un déplacement correspond au trajet réalisé jusqu'à la destination finale pour un motif donné, peu importe le nombre de modes de transport utilisés lors de ce trajet. Ainsi un déplacement peut inclure plusieurs modes, avec des correspondances. En revanche, un déplacement ne peut avoir plusieurs motifs à la fois. Exemple de cas pour un parent allant au travail qui dépose son enfant à l'école durant son trajet en voiture, deux déplacements seront comptés : un premier du domicile à l'école pour le motif "Accompagnement" et un second pour le motif "Travail".
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD, scénario PMUD 2040</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">The volume of daily trips corresponds to the total number of journeys made each day by the residents of the conurbation*. A trip refers to the journey made to the final destination for a given purpose, regardless of the number of modes of transportation used during that journey. Thus, a trip may include multiple modes, with connections. However, a trip cannot have multiple purposes at the same time. For example, in the case of a parent going to work who drops off their child at school during their car journey, two trips will be counted: the first from home to school for the "Accompanying" purpose and the second for the "Work" purpose.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Household travel survey, SUMP 2040 scenario
                </p>`,
            },
        },
        evolutionPartModaleDeplacementsQuotidiens: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                La part modale des déplacements quotidiens correspond au nombre de déplacements effectués chaque jour dans  conurbation* par ses habitants pour chacun des modes existants.
                Cet indicateur permet d'observer l'équilibre entre les modes collectifs et capacitaires (le transport public notamment) et les modes non motorisés comme la marche et le vélo, considérés comme des modes plus vertueux, VS  les modes motorisés tels que la voiture individuelle ou les deux-roues motorisés, générateurs de plus de nuisances (congestion, pollution, consommateurs d'espaces et de ressources). Suivre l'évolution des parts modales permet d'observer s'il y a un report modal.
                Habituellement, la part modale du transport public représente entre 10 et 60% de l'ensemble des modes.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD, scénario PMUD & fil de l'eau 2040</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">Usually, the modal share of public transportation represents between 10 and 60% of all modes.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Household travel survey, 2040 baseline and SUMP scenario
                </p>`,
            },
            },
        evolutionDemographieDeplacements: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre d'habitants est issu d'une projection de données à partir des rencensements généraux de population du HCP et en analysant l'évolution de la tâche urbaine de la conurbation.
                Le volume de déplacements quotidiens correspond au nombre total de trajets effectués chaque jour par les habitants de la conurbation*.
                Un déplacement correspond au trajet réalisé jusqu'à la destination finale pour un motif donné, peu importe le nombre de modes de transport utilisés lors de ce trajet. Ainsi un déplacement peut inclure plusieurs modes, avec des correspondances. En revanche, un déplacement ne peut avoir plusieurs motifs à la fois. Exemple de cas pour un parent allant au travail qui dépose son enfant à l'école durant son trajet en voiture, deux déplacements seront comptés : un premier du domicile à l'école pour le motif "Accompagnement" et un second pour le motif "Travail".
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">pour les données de population : RGPH HCP  + Images aériennes et projections démographiques issues des plans d'aménagement, pour les déplacements : EMD, scénario PMUD 2040. </p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">The population figure is derived from a data projection based on the general population censuses of the HCP (High Commission for Planning) and by analyzing the evolution of the urban area of the conurbation.</p>
                <p class="popup-information-text-speech">The volume of daily trips corresponds to the total number of journeys made each day by the residents of the conurbation*. A trip refers to the journey made to the final destination for a given purpose, regardless of the number of modes of transportation used during that journey. Thus, a trip may include multiple modes, with connections. However, a trip cannot have multiple purposes at the same time. For example, in the case of a parent going to work who drops off their child at school during their car journey, two trips will be counted: the first from home to school for the "Accompanying" purpose and the second for the "Work" purpose.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                for population data: RGPH (General Population and Housing Census) by HCP (High Commission for Planning) + Aerial images and demographic projections from development plans; for trips: Household Mobility Survey, SUMP 2040 scenario.
                </p`,
            },
        },
        evolutionTauxMobilite: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le taux de mobilité quotidien correspond au nombre de déplacements effectués par jour par les habitants âgés de 6 ans et plus (les moins de 6 ans n'étant pas interrogés dans l'enquête) de la conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD, scénario PMUD 2040</p>`,
            },
            en: {
                speech: `<p class="popup-information-text-speech">
                The daily mobility rate corresponds to the number of trips made per day by residents aged 6 and older (children under 6 are not surveyed) of the conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Household Mobility Survey
                </p>`,
            },
        },
        evolutionTauxMotorisation: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Les taux de motorisation correspondent au nombre de véhicules possédés par les ménages de la conurbation*. Il s'agit des voitures ou camionnettes pour le taux automobile, et des motos, scooters ou équivalents motorisés pour le taux de deux-roues motorisés. Il est exprimé en nombre de véhicules pour 1000 habitants.
                Le taux de motorisation automobile est un indicateur essentiel pour déterminer l'importance du véhicule individuel dans le territoire. Il suit souvent les tendances du pouvoir intérieur brut (PIB) : plus il est élevé, plus il y aura de véhicules. Avec la conjoncture actuelle (hausse des prix du carburant et des véhicules, transition énergétique), il est intéressant de suivre l'évolution du taux de motorisation en deux-roues motorisés où il est possible d'observer un report modal de la voiture vers ce mode moins onéreux pour les ménages.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source"></p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">The motorization rates correspond to the number of vehicles owned by households in the conurbation*. It means cars or vans for the automobile rate, and motorcycles, scooters, or similar motorized vehicles for the motorized two-wheelers rate. It is expressed as the number of vehicles per 1000 inhabitants.</p>
                <p class="popup-information-text-speech">The car ownership rate is a key indicator for determining the importance of individual vehicles in a territory. It often follows trends in Gross Domestic Product (GDP): the higher it is, the more vehicles there will be. With the current situation (rise of fuel and vehicle prices, energy transition), tracking the evolution of the motorized two-wheelers ownership rate might be significant to observe a possible modal shift from cars to this less expensive mode for households.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Household Mobility Survey, official statistics
                </p>`,
            },
        },
        evolutionDemographie: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le nombre d'habitants est issu d'une projection de données à partir des rencensements généraux de population du HCP et en analysant l'évolution de la tâche urbaine de la conurbation.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source"> RGPH HCP  + Images aériennes, projections démographiques issues des plans d'aménagement</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">The population figure is derived from a data projection based on the general population censuses conducted by the HCP (High Commission for Planning) and by analyzing the urban development trends within the conurbation*.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                RGPH (General Population and Housing Census) by HCP (High Commission for Planning) + Aerial images and demographic projections from development plans                </p>`,
            },
        },
        evolutionOffreDemandeTc: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                La fréquentation des transports en commun (TC) correspond au nombre de voyages effectués par jour sur les lignes du réseau incluant les correspondances.
                Aide à la lecture : en 2022, 105000 voyages en tramway ont été effectués chaque jour en moyenne durant l'année.
                </p>
                <p class="popup-information-text-speech">
                Le nombre de kilomètre de lignes TCSP correspond au nombre de km de voies de transport en commun en site propre (TCSP), c'est-à-dire des modes de transport rapides avec voies réservées tels que le tramway et le BHNS (ou ligne express).
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">enquêtes, données opérateurs, scénario PMUD 2040</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">The ridership of public transportation (PT) corresponds to the number of journeys made per day on the network lines including connections.</p>
                <p class="popup-information-text-speech">Reading assistance: In 2022, an average of 105,000 tram trips were made per day throughout the year.</p>
                <p class="popup-information-text-speech">The number of kilometers of PT lines corresponds to the length of public transportation lanes, such as tramways rails, BRT (Bus Rapid Transit) or express or bus lines.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                surveys, operator data, SUMP 2040 scenario
                </p>`,
            },
        },
        accesTc: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit de la part de la population résidant à moins de 500 mètres d'une desserte de lignes en transports en commun (stations tramway, arrêts de bus) de fréquence inférieure ou égale à 10 minutes. C'est un indicateur stratégique pour rendre compte de l'efficacité et de l'attractivité des transports publics.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">croisement de données cartographiques des réseaux de transports avec la répartition de la population (estimée à partir des données RGPH HCP et de l'analyse d'images aériennes), scénario PMUD</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">It refers to the proportion of the population living within 500 meters of a public transportation service (tramway stations, bus stops) with a frequency equal to or less than 10 minutes. It's a strategic indicator for assessing the efficiency and attractiveness of public transportation.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Cross-referencing of transportation network data with population distribution (estimated from RGPH HCP data and analysis of aerial images), SUMP 2040 scenario
                </p>`,
            },
        },
        tauxMobiliteGenre: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Le taux de mobilité quotidien correspond au nombre de déplacements effectués par jour par les habitants âgés de 6 ans et plus (les moins de 6 ans n'étant pas interrogés dans l'enquête) de la conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">The daily mobility rate corresponds to the number of trips made per day by residents aged 6 and above (those under 6 are not surveyed) of the conurbation*.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Household Mobility Survey
                </p>`,
            },
        },
        partBudgetMoyenMobilite: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Il s'agit du montant des revenus des ménages annuels et de la part du budget alloué à la mobilité tels que le coût des tickets de transport, le carburant, l'assurance automobile, etc. Les normes internationales recommandent pour les ménages modestes de ne pas dépasser les 10%.
                </p>
                `,
                source: `<p class="popup-information-text-source">EMD</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">It refers to the annual household income and the portion of the budget allocated to mobility expenses such as transportation tickets, fuel, car insurance, etc. International standards recommend that low-income households should not exceed 10% of their budget on transportation.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Household Mobility Survey
                </p>`,
            },
        },
        accidentsCirculation: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Cet indicateur renseigne le nombre d'accidents corporels (mortels, blessés graves, blessés légers) survenus sur le réseau routier dans la conurbation*.
                </p>
                <p class="popup-information-text-conurbation">
                Conurbation * regroupe les préfectures de Rabat , Salé, Skhirat-Témara ainsi que la commune de Sidi Taibi
                </p>
                `,
                source: `<p class="popup-information-text-source">NARSA, scénario PMUD 2040</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">This indicator provides information on the number of injury accidents (fatal, serious injuries, minor injuries) occurring on the road network in the conurbation*.</p>
                <p class="popup-information-text-conurbation">
                Conurbation* includes the prefectures of Rabat, Sale, Skhirat-Temara, as well as the municipality of Sidi Taibi
                </p>`,
                source: `<p class="popup-information-text-source">
                Official data, SUMP 2040 scenario
                </p>`,
            },
        },
        emissionCo2Mode: {
            fr: {
                speech: `<p class="popup-information-text-speech">
                Les émissions de gaz à effet de serre (GES) sont estimées en prenant en compte le type de véhicule et sa consommation énergétique (Diesel, Essence, GNV, Hybride, Électrique), associée à un facteur d'émission par type d'énergie. Les émissions par mode s'obtiennent ainsi en multipliant par le nombre de kilomètres effectués par an pour chaque véhicule.  L'indicateur est exprimé en équivalent kgCO2/an.
                </p>
                `,
                source: `<p class="popup-information-text-source">Données officielles sur la consommation d'énergie et le facteur d'émission, Durée moyenne du trajet, répartition modale et occupation (EMD, comptages, données d'exploitation) par mode pour calculer le nombre de véhicules km par mode, scénario PMUD & fil de l'eau 2040</p>`,
            },
            en: {
                speech: `
                <p class="popup-information-text-speech">Greenhouse gas (GHG) emissions are estimated by taking into account the type of vehicle and its energy consumption (Diesel, Petrol, CNG, Hybrid, Electric), associated with an emission factor per type of energy. Emissions by mode are thus obtained by multiplying the number of kilometers traveled per year for each vehicle. The indicator is expressed in equivalent kgCO2/year.</p`,
                source: `<p class="popup-information-text-source">
                Official data on energy consumption and emission factors, Average journey duration, modal split, and occupancy (Household Mobility Survey, traffic counts, operational data) by mode to calculate vehicle kilometers per mode, 2040 SUMP scenario & baseline.
                </p`,
            },
        },
    },
    SumpManagement: {
        Gantt: {
            fr: {
                speech: `Ce tableau présente l'état d'avancement du plan global de mobilité ou du plan de mobilité urbaine durable (PMUD)
                sur la base d'une mise à jour annuelle. Chaque action est colorée en fonction de son degré d'avancement.`,
                source: ``,
            },
            en: {
                speech: `This table presents the progress of the Comprehensive Mobility Plan (CMP) or Sustainable Urban Mobility Plan (SUMP)
                based on an annual update. Each action is filled with color according to its level of completion.`,
                source: ``,
            },
        },
    },
};

module.exports = {
    popupInformation,
};
