import { Route, Routes } from "react-router";
import "../styles/App.css";
import ResponsiveAppBar from "./AppBar";
import MobilityIndicators from "./MobilityIndicators";
import SumpTrajectory from "./SumpTrajectory";
import SumpManagement from "./SumpManagement";
import DigitalParticipation from "./DigitalParticipation";
import { Navigate } from "react-router-dom";
import References from "./References";
import store from "../store";

function App() {
    const storeRoutes = store.getState().routes;
    return (
        <div className="App">
            <div>
                <ResponsiveAppBar />
                <Routes>
                    {storeRoutes.displayTabMobilityIndicators === true &&
                    <Route
                        path="/mobility-indicators"
                        element={<MobilityIndicators />}
                    >
                        <Route path=":cityName" element={<MobilityIndicators />} />
                    </Route>
                    }
                    {storeRoutes.displayTabSumpTrajectory === true &&
                    <Route
                        path="/sump-trajectory"
                        element={<SumpTrajectory />}
                    >
                        <Route path=":cityName" element={<SumpTrajectory />} />
                    </Route>
                    }
                    {storeRoutes.displayTabSumpManagement === true &&
                    <Route
                        path="/sump-management"
                        element={<SumpManagement />}
                    >
                        <Route path=":cityName" element={<SumpManagement />} />
                    </Route>
                    }
                    {storeRoutes.displayTabDigitalParticipation === true &&
                    <Route
                        path="/digital-participation"
                        element={<DigitalParticipation />}
                    >
                        <Route path=":cityName" element={<DigitalParticipation />} />
                    </Route>
                    }
                    {storeRoutes.displayTabReferences === true &&
                    <Route
                        path="/references"
                        element={<References />}
                    >
                    </Route>
                    }
                    <Route
                        path="/*"
                        element={
                            <Navigate replace to="/home" />
                        }
                    ></Route>
                </Routes>
            </div>
        </div>
    );
}

export default App;
