import DashboardIndicator from "./DashboardIndicator";
import "../../styles/Dashboard.css";
import store from "../../store";
import { useSelector } from "react-redux";
import logo from "../../img/logo-large.png";
import { useTranslation } from "react-i18next";

export default function DashboardMobilityIndicators() {
    const { t } = useTranslation();
    let stateMap = useSelector(
        (_state) => store.getState().map
    );

    // Classes related to the map container
    let classNameDashboard = "dashboard-container";
    classNameDashboard =
    stateMap.display === false
        ? classNameDashboard + " dashboard-container-no-map"
        : classNameDashboard;
    classNameDashboard =
        stateMap.fullScreen === true
            ? classNameDashboard + " dashboard-container-map-full-screen"
            : classNameDashboard;

    // Number of unchecked indicators treeviews
    let arrayCheckedStatusTreeviewsIndicators = [];
    for (let section of Object.values(
        store.getState().dashboardMobilityIndicators
    )) {
        if (section.treeviews.indicators.checked !== 0) {
            arrayCheckedStatusTreeviewsIndicators.push(
                section.treeviews.layers.checked
            );
        }
    }

    // List of themes
    let storeMobilityIndicatorsThemes = useSelector(
        (_state) => store.getState().dashboardMobilityIndicators
    );

    return (
        <div className={classNameDashboard}>
            {/* At least one indicator is selected */}
            {arrayCheckedStatusTreeviewsIndicators.length !== 0 && (
                <div>

                    { [...new Map(Object.entries(storeMobilityIndicatorsThemes)).entries()].map(([key, _]) => {
                            return <DashboardIndicator key={key} id={key} part="mobility-indicators"/>
                        })
                    }

                </div>
            )}
            {/* No indicator selected: display a message insteaf of a blank screen */}
            {arrayCheckedStatusTreeviewsIndicators.length === 0 && (
                <div className="dashboard-container-empty">
                    <div className="dashboard-container-empty-subcontainer">
                        <p className="dashboard-container-empty-message">
                            {t("Select at least one indicator in the side panel to display the mobility indicators")}
                        </p>
                        <img
                            className="dashboard-container-empty-logo"
                            src={logo}
                            alt="logo"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
