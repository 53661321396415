import "../../styles/GanttLegend.css";
import store from "../../store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function GanttMycLegend() {
    const [status] = useState([store.getState().ganttSumpManagement.status]);
    const { t } = useTranslation();

    let htmlLegendStatus = [];
    for (const [name, color] of Object.entries(status[0])) {
        let style = {
            backgroundColor: color,
        };
        htmlLegendStatus.push(
            <div className="gantt-legend-item">
                <div className="gantt-legend-color" style={style}></div>
                <label>{t(`SUMP Management Content.legend.${name}`)}</label>
            </div>
        );
    }

    let htmlLegendProgress = [];
    let styleBeforeProgress = {
        backgroundImage:
            "linear-gradient(45deg, #00f 2.5%, #fff 2.5%, #fff 47.5%, #00f 47.5%, #00f 52.5%, #fff 52.5%, #fff 97.5%, #00f 97.5%)",
        backgroundSize: "10px 10px",
        backgroundPosition: "50px 50px",
    };
    let styleAfterProgress = {
        backgroundColor: "#fff",
        backgroundImage:
            "radial-gradient(circle, #00f 20%, transparent 10%), radial-gradient(circle, #00f 20%, transparent 10%)",
        backgroundSize: "10px 10px",
        backgroundPosition: "50px 50px",
    };

    htmlLegendProgress.push(
        <div className="gantt-legend-item">
            <div
                className="gantt-legend-color"
                style={styleBeforeProgress}
            ></div>
            <label>{t("SUMP Management Content.legend.EtudesAcquisitionOutils")}</label>
        </div>
    );
    htmlLegendProgress.push(
        <div className="gantt-legend-item">
            <div
                className="gantt-legend-color"
                style={styleAfterProgress}
            ></div>
            <label>
            {t("SUMP Management Content.legend.ConstructionInfrastructuresImplementationPhysique")}
            </label>
        </div>
    );

    return (
        <>
            <div className="gantt-legend">
                {htmlLegendStatus.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
            <div className="gantt-legend">
                {htmlLegendProgress.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
        </>
    );
}
