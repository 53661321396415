import { React, Component } from "react";
import "chart.js/auto";
import "../styles/Dashboard.css";
import "../styles/Charts.css";
import parse from "html-react-parser";
import store from "../store";
import { Chart as ReactChart } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart as ChartJS } from 'chart.js';
import { formatNumberChart } from "../utils";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
    TreemapController,
    TreemapElement,
    ChartDataLabels,
    annotationPlugin
  );



const { config } = require("../settings");
const configGeographicalAreas = config.geographicalAreas;

class Charts extends Component {
    constructor(props) {
        super(props);

        this.part = props.part;
        this.configCharts = config.charts;
        this.xAxisMinYear =
            this.part === "mobility-indicators"
                ? this.configCharts.xAxisMinYearMobilityIndicators
                : this.configCharts.xAxisMinYearSumpTrajectory;
        this.xAxisMaxYear =
            this.part === "mobility-indicators"
                ? this.configCharts.xAxisMaxYearMobilityIndicators
                : this.configCharts.xAxisMaxYearSumpTrajectory;
        const chartsData = props.data.charts;
        const keyFigureIndicator = props.data.keyFigure;

        this.state = {
            chartsData,
            data: [],
            keyFigureIndicator,
            currentYear: store.getState().years.currentYear,
            currentCity: store.getState().cities.currentCity,
            currentGeographicalArea:
                store.getState().geographicalAreas.currentGeographicalArea,
        };

        for (let [key, value] of Object.entries(this.state.chartsData)) {
            this.getData(key, value);
        }

        this.getDataKeyFigureIndicator();
    }

    // When the year of the indicators changes, update the charts and key figures
    componentDidUpdate(_prevProps, prevState) {
        const storeCurrentYear = store.getState().years.currentYear;
        const storeCurrentCity = store.getState().cities.currentCity;
        const storeCurrentGeographicalArea =
            store.getState().geographicalAreas.currentGeographicalArea;
        if (
            prevState.currentYear !== storeCurrentYear ||
            prevState.currentCity !== storeCurrentCity ||
            prevState.currentGeographicalArea !== storeCurrentGeographicalArea
        ) {
            this.setState({
                currentYear: storeCurrentYear,
                currentCity: storeCurrentCity,
                currentGeographicalArea: storeCurrentGeographicalArea,
            });
            // Update of the charts
            for (let [key, value] of Object.entries(this.state.chartsData)) {
                this.getData(key, value);
            }
            // Update of the key figures
            this.getDataKeyFigureIndicator();
        }
    }

    // Fetch data from the api
    fetchData = async (
        url,
        multiYearGeographicalAreaIndicator = true,
        singleYearGeographicalAreaType = "",
        keyFigureIndicator = false,
        keyFigureIndicatorType = "",
        keyFigureChart = false,
        keyFigureChartType = ""
    ) => {
        let urlCityOptions = url + this.state.currentCity;

        urlCityOptions += `&chart_multi_year_geographical_area=${multiYearGeographicalAreaIndicator}`;
        // Not a multi-year chart, specify the year or the geographical_area
        if (!multiYearGeographicalAreaIndicator) {
            if (singleYearGeographicalAreaType === "year") {
                urlCityOptions += `&chart_year=${this.state.currentYear}`;
            }
            else {
                urlCityOptions += `&chart_geographical_area=${configGeographicalAreas[this.state.currentGeographicalArea]}`;
            }

        }
        // Specify the year or the geographical area of the key figure indicator depending on type
        if (keyFigureIndicator) {
            if (keyFigureIndicatorType === "year") {
                urlCityOptions += `&indicator_key_figure_type=year&indicator_key_figure_year=${this.state.currentYear}`;
            } else {
                urlCityOptions += `&indicator_key_figure_type=geographical_area&indicator_key_figure_geographical_area=${
                    configGeographicalAreas[this.state.currentGeographicalArea]
                }`;
            }
        }
        // Specify there is a key number associated with the chart
        if (keyFigureChart) {
            urlCityOptions += `&chart_key_figure=true`;
            // Specify the year or the geographical area of the key figure chart depending on type
            if (keyFigureChartType === "year") {
                urlCityOptions += `&chart_key_figure_type=year&chart_key_figure_year=${this.state.currentYear}`;
            } else {
                urlCityOptions += `&chart_key_figure_type=geographical_area&chart_key_figure_geographical_area=${
                    configGeographicalAreas[this.state.currentGeographicalArea]
                }`;
            }
        }

        const response = await fetch(urlCityOptions);
        const data = await response.json();
        return data;
    };

    // API call to get the indicator key figure data and update the state
    getDataKeyFigureIndicator() {
        let keyFigureIndicator = this.state.keyFigureIndicator;
        let keyFigureIndicatorUrl = keyFigureIndicator.url;
        let keyFigureIndicatorTypeUpdate = keyFigureIndicator.typeUpdate;
        // Fetch if a city has been selected
        if (keyFigureIndicatorUrl !== "" && this.state.currentCity !== "") {
            this.fetchData(
                keyFigureIndicatorUrl,
                false,
                "",
                true,
                keyFigureIndicatorTypeUpdate,
                false,
                ""
            )
                .then((resultApi) => {
                    this.setState({
                        keyFigureIndicator: {
                            ...keyFigureIndicator,
                            data: formatNumberChart(resultApi["data_indicator_key_figure"]),
                        },
                    });
                })
                // API unavailable
                .catch((_error) => {
                    this.setState({
                        keyFigureIndicator: {
                            ...keyFigureIndicator,
                            data: "",
                        },
                    });
                });
        }
    }

    // API call to get the chart data and update the state
    getData(key, chart) {
        const datasetsChart = chart.datasets;
        let dataChart = {
            labels: [],
            datasets: [],
            keyFigure: {},
            error: "",
        };
        let data = this.state.data;

        const arrayChartSingleColor = ["line", "bar", "area"];
        // Fetch if a city has been selected
        if (this.state.currentCity.length !== 0) {
            for (let dataset of Object.values(datasetsChart)) {
                this.fetchData(
                    dataset.url,
                    chart.multiYearGeographicalArea,
                    chart.singleYearGeographicalAreaType,
                    false,
                    "",
                    chart.keyFigure.url !== "" ? true : false,
                    chart.keyFigure.typeUpdate
                )
                    .then((resultApi) => {
                        let resultApiData = resultApi.data;
                        let resultApiDataChartKeyFigure =
                            resultApi.data_chart_key_figure;
                        let lengthData = resultApiData.length;
                        let typeChart = chart.typeChart;
                        let labelDataset = dataset.label;
                        let datalabelsPropertiesDataset = dataset.datalabelsProperties;
                        // Different rendering according to the charts (single color for line & bar charts)
                        let backgroundColorChart = "";
                        let borderColorChart = "";
                        if (arrayChartSingleColor.includes(typeChart)) {
                            backgroundColorChart = dataset.backgroundColor;
                            borderColorChart = dataset.borderColor;
                        } else {
                            backgroundColorChart =
                                dataset.backgroundColor.slice(
                                    0,
                                    lengthData
                                );
                            borderColorChart =
                                dataset.borderColor.slice(
                                    0,
                                    lengthData
                                );
                        }

                        // Use labels from the response api except for the reality data
                        // So that the points are well placed on the x-axis
                        if (!dataset.url.includes("trend=reality")) {
                            dataChart.labels = resultApi.labels;
                        }
                        // TODO : fix later
                        else {
                            dataChart.labels = resultApi.labels;
                        }

                        dataChart.datasets.push({
                            type: dataset.type,
                            label: labelDataset,
                            data:
                                resultApiData.length > 0
                                    ? resultApiData
                                    : resultApiDataChartKeyFigure,
                            labels: resultApi.labels,
                            backgroundColor: backgroundColorChart,
                            borderColor: borderColorChart,
                            fill: dataset.fill,
                            spanGaps: dataset.spanGaps,
                            borderWidth: dataset.borderWidth,
                            borderDash: dataset.borderDash,
                            pointStyle: dataset.pointStyle,
                            pointRadius: dataset.pointRadius,
                            yAxisID: dataset.yAxisID,
                            order: dataset.order,
                            tooltipUnit: dataset.tooltipUnit,
                            tooltipToHide: dataset.tooltipToHide,
                            pictoProperties: dataset.pictoProperties,
                            barPercentage: dataset.barPercentage,
                            categoryPercentage: dataset.categoryPercentage,
                            datalabels : {
                                align	: datalabelsPropertiesDataset.align,
                                anchor : datalabelsPropertiesDataset.anchor,
                                display: datalabelsPropertiesDataset.display,
                                color: datalabelsPropertiesDataset.color,
                                font: {
                                    weight: datalabelsPropertiesDataset.font.weight,
                                },
                                value: datalabelsPropertiesDataset.value
                            }
                        });
                        dataChart.keyFigure = {
                            label: chart.keyFigure.label,
                            data: formatNumberChart(
                                resultApiDataChartKeyFigure,
                                chart.keyFigure.separator
                            ),
                            position: chart.keyFigure.position,
                        };
                    })
                    // An error occurred at the time of API call
                    .catch((_error) => {
                        dataChart.error = "Error when calling the API";
                    })
                    // Update of the state
                    .finally(() => {
                        data[key] = dataChart;
                        this.setState({
                            data: data,
                        });
                    });
            }
        }
    }

    render() {
        // Customization of the legend by using icons instead of colored squares
        const renderChartLegendCustom = (chart) => {
            let descriptionLegendCustom = "";

            const datasetsChart = chart.datasets;
            for (let dataset of Object.values(datasetsChart)) {
                descriptionLegendCustom += `<div class="chart-legend-custom-row">
                <p class="chart-legend-custom-icon ${dataset.legendCustomCssClass}"></p>
                <p> ${dataset.label}</p>
                </div> `;
            }

            return parse(descriptionLegendCustom);
        };

        // Generic function to generate a chart
        const renderChart = (chart, dataChart) => {
            // Regenerate the object expected by the data variable of Chart
            let datasetsValid = [];
            dataChart.datasets.forEach((dataset) =>
            {

                    // Data property formatting according to the chart type (treemap expects a specific format)
                    const datasetData = dataset.data;
                    const dataChartLabels = dataChart.labels;
                    const datasetBackgroundColor = dataset.backgroundColor;
                    const datasetDatalabelsProperties = dataset.datalabels;
                    const dataFormatted = [];
                    for (let i = 0; i < datasetData.length; i++) {
                        let obj = {}
                        if (chart.typeChart === "treemap") {
                            obj = {
                                name: dataChartLabels[i],
                                value: datasetData[i],
                                color: datasetBackgroundColor[i]
                            };
                        }
                        else if (chart.typeChart === "line") {
                            obj = {
                                x: dataset.labels[i],
                                y: datasetData[i]
                            }
                        }
                    dataFormatted.push(obj);
                    }

                    datasetsValid.push({
                    type: dataset.type,
                    label: dataset.label,
                    data: (chart.typeChart === "treemap" || chart.typeChart === "line") ? dataFormatted : datasetData,
                    labels: chart.typeChart === "treemap" ? {
                        display: false,
                        formatter: (context) => `${context.raw._data.name} : ${context.raw._data.value}`,
                      } : {},
                    tree: chart.typeChart === "treemap" ? dataFormatted : undefined,
                    key: chart.typeChart === "treemap" ? "value" : undefined,
                    backgroundColor: chart.typeChart === "treemap" ? (context) =>
                        {
                            let rawColor = "";
                            rawColor = context.raw.color;
                            if (!rawColor) {
                                rawColor = context.raw._data.color;
                            }
                        return rawColor }: dataset.backgroundColor,
                    borderColor: chart.typeChart === "treemap" ? (context) =>
                        {
                            let rawColor = "";
                            rawColor = context.raw.color;
                            if (!rawColor) {
                                rawColor = context.raw._data.color;
                            }
                        return rawColor }: dataset.borderColor,
                    borderWidth: dataset.borderWidth,
                    borderDash: dataset.borderDash,
                    fill: dataset.fill,
                    spanGaps: dataset.spanGaps,
                    pointStyle: dataset.pointStyle,
                    pointRadius: dataset.pointRadius,
                    yAxisID: dataset.yAxisID,
                    order: dataset.order,
                    tooltipUnit: dataset.tooltipUnit,
                    tooltipToHide: dataset.tooltipToHide,
                    pictoProperties: dataset.pictoProperties,
                    barPercentage: dataset.barPercentage,
                    categoryPercentage: dataset.categoryPercentage,
                    datalabels : {
                        align	: datasetDatalabelsProperties.align,
                        anchor : datasetDatalabelsProperties.anchor,
                        display: datasetDatalabelsProperties.display,
                        color: datasetDatalabelsProperties.color,
                        font: {
                            weight: datasetDatalabelsProperties.font.weight
                        },
                        formatter: function(value, _context) {
                            const datalabelsValue = datasetDatalabelsProperties.value;
                            if (Math.round(value) === 0) {
                                return ""
                            } else {
                                return datalabelsValue.prefix + value + datalabelsValue.suffix;
                            }
                          }

                    }
                })
        }
        );

            // Translate the labels
            let dataChartLabels = dataChart.labels;
            if (chart.i18nKeyLabels) {
                dataChartLabels = dataChartLabels.map(value =>
                    {
                        let valueTranslated = this.props.t(`${chart.i18nKeyLabels}.${value}`);
                        if (valueTranslated.startsWith(chart.i18nKeyLabels)) {
                            return value
                        } else {
                            return valueTranslated
                        }
                    }
                    );
                }
            let dataChartValid = {
                labels: dataChartLabels,
                datasets: datasetsValid,
            };

            // The order of the datasets is displayed as the order defined in the store
            let chartDatasets = chart.datasets;
            if (chartDatasets.length > 1) {
                let chartDatasetsLabelsSort = [];
                chartDatasets.forEach((dataset) =>
                    chartDatasetsLabelsSort.push(dataset.label)
                );
                let datasetsValidSort = [];
                for (let label of chartDatasetsLabelsSort) {
                    for (let dataset of datasetsValid) {
                        if (label === dataset.label) {
                            datasetsValidSort.push(dataset);
                            break;
                        }
                    }
                }
                dataChartValid.datasets = datasetsValidSort;
            }

            // Key figure associated with the chart //
            let dataChartKeyFigureData = dataChart.keyFigure.data;
            let dataChartKeyFigureLabel = dataChart.keyFigure.label;
            let dataChartKeyFigurePosition = dataChart.keyFigure.position;
            if (
                dataChartKeyFigureLabel !== undefined &&
                dataChartKeyFigureLabel.startsWith("%")
            ) {
                dataChartKeyFigureData =
                    Number(dataChartKeyFigureData).toLocaleString() + "%";
                dataChartKeyFigureLabel = dataChartKeyFigureLabel.substring(1);
            }

            let contentDivDataChartKeyFigure = "";
            if (chart.typeChart !== "picto") {
                contentDivDataChartKeyFigure = (
                    <div className="dashboard-key-figure-container dashboard-key-figure-chart-container">
                        <p className="dashboard-key-figure-data">
                            {dataChartKeyFigureData}
                        </p>
                        <p className="dashboard-key-figure-label">
                            {dataChartKeyFigureLabel}
                        </p>
                    </div>
                );
            }
            // Picto representation
            else {
                const renderPicto = (datasets) => {
                    let descriptionLegendPictoCustom = "";
                    let datasetsPictoSimpleKeyFigure = [];
                    let datasetsPictoMany = []
                    for (let dataset of datasets) {
                        let datasetType = dataset.type;
                        if (datasetType === "picto-simple") {
                            datasetsPictoSimpleKeyFigure.push(dataset);
                        }
                        // picto-many
                        else {
                            datasetsPictoMany.push(dataset);
                        }
                    }

                    // Simple pictogram associated with a key figure
                    if (datasetsPictoSimpleKeyFigure.length > 0) {
                        for (let dataset of datasetsPictoSimpleKeyFigure) {
                            let pictoLabel = this.props.t(dataset.label);
                            let pictoData = dataset.data;
                            let pictoProperties = dataset.pictoProperties;
                            // No data
                            if (pictoData === "") {
                                pictoLabel = `
                                <div class="dashboard-key-figure-picto-simple-legend-row dashboard-key-figure-picto-simple-no-data">
                                <strong>${this.props.t("Data not available")}</strong>
                                </div>
                                `
                            } else {
                                pictoLabel = pictoLabel.replace(
                                    "REPLACEPICTOKEYFIGURE",
                                    `<div class="dashboard-key-figure-value-picto-simple"><strong> ${pictoData} </strong></div>`
                                );
                            }

                            descriptionLegendPictoCustom +=
                            `
                            <div class="dashboard-key-figure-picto-simple-container">
                            <div class="dashboard-picto-simple ${pictoProperties.cssClassIcon}"></div>
                            <div class="dashboard-key-figure-text-picto-simple">${pictoLabel}</div>
                            </div>
                            `;
                        }
                    }

                    // Many pictograms
                    if (datasetsPictoMany.length > 0) {
                        let representationPictoMany = "";
                        let descriptionRepresentationsPictoMany = "";
                        let legendPictoMany = "";
                        for (let dataset of datasetsPictoMany) {
                            let descriptionLegendPictoMany = "";
                            let descriptionRepresentationPictoMany = "";
                            let pictoLabel = this.props.t(dataset.label);
                            let pictoData = dataset.data;
                            // Example : "[Decimal('724866.00'), Decimal('6')]";
                            // Regular express to extract numeric values
                            let regex = /Decimal\('([\d.]+)'\)/g;
                            let arrayPictoData = [];
                            let matchPictoData;
                            while ((matchPictoData = regex.exec(pictoData[0])) !== null) {
                                arrayPictoData.push(parseFloat(matchPictoData[1]));
                            }
                            let pictoProperties = dataset.pictoProperties;
                            // arrayPictoData[0] = value to be used in the legend
                            // arrayPictoData[1] = number of pictograms
                            // arrayPictoData[2] = second value to be used in the legend
                            pictoLabel = pictoLabel.replace(
                                "REPLACEPICTOKEYFIGURE",
                                "<strong>" + formatNumberChart(arrayPictoData[0]) + "</strong>"
                            );
                            pictoLabel = pictoLabel.replace(
                                "REPLACESECONDPICTOKEYFIGURE",
                                "<strong>" + formatNumberChart(arrayPictoData[2]) + "</strong>"
                            );

                            for (var i = 0; i < Number(arrayPictoData[1]); i++) {
                                descriptionRepresentationPictoMany +=
                                `
                                <div class="dashboard-picto-many ${pictoProperties.cssClassIcon}"></div>
                                `;

                              }

                            descriptionLegendPictoMany +=
                            `
                            <div class="dashboard-key-figure-picto-many-legend-row">
                            <div class="dashboard-picto-many dashboard-picto-many-legend ${pictoProperties.cssClassIcon}"></div>
                            <div>${pictoLabel}</div>
                            </div>
                            `
                            descriptionRepresentationsPictoMany += `
                            <div class="dashboard-key-figure-picto-many-representation-legend-row">
                            <div class="dashboard-key-figure-picto-many-representation-row"> ${descriptionRepresentationPictoMany}</div>
                            ${descriptionLegendPictoMany}
                            </div>
                            `;
                        }
                        representationPictoMany += `
                        <div class="dashboard-key-figure-picto-many-representation-legend-container">
                        ${descriptionRepresentationsPictoMany}
                        </div>`
                        let containerPictoMany = `
                        <div class="dashboard-key-figure-picto-many-container">
                        ${representationPictoMany}
                        ${legendPictoMany}
                        </div>`
                        descriptionLegendPictoCustom += containerPictoMany
                    }

                    return parse(descriptionLegendPictoCustom);
                };

                contentDivDataChartKeyFigure = renderPicto(
                    dataChartValid.datasets
                );
            }

            // Retrieve a translation
            const getTranslation = (datasetLabel) => {
                return this.props.t(datasetLabel);
            };

            return (

                <div>
                    {/* Key figure associated with the chart if the position is equal to top */}
                    {dataChartKeyFigureLabel !== "" &&
                    dataChartKeyFigurePosition === "top"
                        ? contentDivDataChartKeyFigure
                        : ""}
                    {/* Custom Legend */}
                    {chart.legendCustom === "true" &&
                        dataChart.error === "" && (
                            <div className="chart-legend-custom-container ">
                                {renderChartLegendCustom(chart)}
                            </div>
                        )}
                    {/* Display the chart if there was no api error and if there is data and if the chart is not picto */}
                    {dataChart.labels.length !== 0 &&
                    dataChartValid.datasets["0"].data.length && chart.typeChart !== "picto" ? (
                        <div className="">
                            <ReactChart
                                type={
                                    chart.typeChart === "area"
                                        ? "line"
                                        : chart.typeChart
                                }
                                data={dataChartValid}
                                height={chart.heightChart}
                                width={chart.widthChart}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio:
                                        chart.maintainAspectRatio,
                                    interaction: {
                                        mode: "index",
                                    },
                                    indexAxis: chart.indexAxis,
                                    locale: "fr-FR",
                                    plugins: {
                                        legend: {
                                            display:
                                                chart.legendDisplay ===
                                                    "true" &&
                                                chart.legendCustom !== "true",
                                            position: "top",
                                            labels: {
                                                boxHeight:
                                                    chart.legendLabelsBoxHeight,
                                                boxWidth: 30,
                                                /* generateLabels */
                                                generateLabels: function(chart){
                                                var data = chart.data;
                                                var legends = Array.isArray(data.datasets) ? data.datasets.map(function(dataset, i) {
                                                    const datasetLabel = getTranslation(dataset.label);
                                                return {
                                                    text: datasetLabel.replace('HIDETOOLTIPIFZERO', ''),
                                                    fillStyle: (!Array.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
                                                    hidden: !chart.isDatasetVisible(i),
                                                    lineCap: dataset.borderCapStyle,
                                                    lineDash: dataset.borderDash,
                                                    lineDashOffset: dataset.borderDashOffset,
                                                    lineJoin: dataset.borderJoinStyle,
                                                    lineWidth: dataset.borderWidth,
                                                    strokeStyle: dataset.borderColor,
                                                    pointStyle: dataset.pointStyle,
                                                    // // Below is extra data used for toggling the datasets
                                                    // datasetIndex: i
                                                };
                                                }, this) : [];
                                                return legends;
                                            },
                                                filter: function(item, _chart) {
                                                    return !item.text.includes('HIDELEGEND')
                                                }
                                            },
                                            onClick: (e) => e.stopPropagation(),

                                        },
                                        title: {
                                            display:
                                                chart.titleDisplay === "true",
                                            text: chart.titleChart,
                                            font: {
                                                size: "14",
                                            },
                                        },
                                        tooltip: {
                                            callbacks: {
                                                title: function (tooltipItem) {
                                                    let title = "";
                                                    if (chart.typeChart !== "treemap") {
                                                        title = tooltipItem[0].label;
                                                    } else {
                                                        title = tooltipItem[0].raw._data.name
                                                    }
                                                    return title.replace(
                                                        ",",
                                                        ""
                                                    );
                                                },

                                                // Add a unit at the end of the tooltip label
                                                label: function (tooltipItem) {
                                                    let tooltipItemDataset =
                                                        tooltipItem.dataset;
                                                    let tooltipLabel =
                                                    getTranslation(tooltipItemDataset.label);
                                                    let tooltipToHide = tooltipItemDataset.tooltipToHide;
                                                    let tooltipUnit = getTranslation(tooltipItemDataset.tooltipUnit);

                                                    if (tooltipItemDataset.tooltipToHide && tooltipToHide.includes(tooltipItem.label)) {
                                                        return ""
                                                    }

                                                    if (tooltipLabel !== "") {
                                                        tooltipLabel =
                                                            tooltipLabel +
                                                            " : ";
                                                    }
                                                    let tooltipFormattedValue = "";
                                                    if (chart.typeChart !== "treemap") {
                                                        tooltipFormattedValue = tooltipItem.formattedValue;
                                                    } else {
                                                        tooltipFormattedValue = tooltipItem.raw._data.value
                                                    }

                                                    if (tooltipLabel.includes("HIDETOOLTIPIFZERO")) {
                                                        if (tooltipFormattedValue === "0") {
                                                            return ""
                                                        } else {
                                                            return (
                                                        tooltipLabel.replace("HIDETOOLTIPIFZERO", "").replace("HIDELEGEND", "") +
                                                        tooltipFormattedValue +
                                                        tooltipUnit
                                                    );
                                                        }
                                                    } else {
                                                        return (
                                                        tooltipLabel +
                                                        tooltipFormattedValue +
                                                        tooltipUnit
                                                    );
                                                    }

                                                },
                                            },
                                        },
                                        datalabels: {
                                            display: false,
                                        },
                                        annotation: {
                                            annotations: {
                                                annotation1: chart.annotationsProperties.annotation1 !== undefined ? {
                                                    type: chart.annotationsProperties.annotation1.type,
                                                    xMin: chart.annotationsProperties.annotation1.xMin,
                                                    xMax: chart.annotationsProperties.annotation1.xMax,
                                                    yMin: chart.annotationsProperties.annotation1.yMin,
                                                    yMax: chart.annotationsProperties.annotation1.yMax,
                                                    borderColor: chart.annotationsProperties.annotation1.borderColor,
                                                    borderDash: chart.annotationsProperties.annotation1.borderDash,
                                                    borderWidth: chart.annotationsProperties.annotation1.borderWidth
                                                } : undefined,
                                                annotation2: chart.annotationsProperties.annotation2 !== undefined
                                                 ? {
                                                    type: chart.annotationsProperties.annotation2.type,
                                                    xMin: chart.annotationsProperties.annotation2.xMin,
                                                    xMax: chart.annotationsProperties.annotation2.xMax,
                                                    yMin: chart.annotationsProperties.annotation2.yMin,
                                                    yMax: chart.annotationsProperties.annotation2.yMax,
                                                    borderColor: chart.annotationsProperties.annotation2.borderColor,
                                                    borderDash: chart.annotationsProperties.annotation2.borderDash,
                                                    borderWidth: chart.annotationsProperties.annotation2.borderWidth
                                                } : undefined
                                        }
                                    }
                                    },
                                    scales: {
                                        x: {
                                            type: chart.scales.x.type,
                                            display: chart.scales.x.display,
                                            stacked: chart.scales.x.stacked,
                                            title: {
                                                display:
                                                    chart.scales.x.titleDisplay,
                                                    text: Array.isArray(chart.scales.x.titleText) ? chart.scales.x.titleText.map(key => this.props.t(key)) : this.props.t(chart.scales.x.titleText)
                                            },
                                            grid: {
                                                display:
                                                    chart.scales.x.gridDisplay,
                                            },
                                            beginAtZero:
                                                chart.scales.x.beginAtZero,
                                            min: chart.scales.x.min,
                                            max: chart.scales.x.max,
                                            ticks: {
                                                        maxRotation: chart.scales.x.ticks.maxRotation,
                                                        minRotation: chart.scales.x.ticks.minRotation,
                                                        display: chart.scales.x.ticks.display,
                                                        callback: function(value, _index, _ticks) {
                                                            const chartTicksValue = chart.scales.x.ticks.value;
                                                            return chartTicksValue.prefix + this.getLabelForValue(value) + chartTicksValue.suffix;
                                                        }
                                            },
                                        },
                                        y: {
                                            type: chart.scales.y.type,
                                            display: chart.scales.y.display,
                                            stacked: chart.scales.y.stacked,
                                            position: "left",
                                            title: {
                                                display:
                                                    chart.scales.y.titleDisplay,
                                                text: Array.isArray(chart.scales.y.titleText) ? chart.scales.y.titleText.map(key => this.props.t(key)) : this.props.t(chart.scales.y.titleText)
                                            },
                                            grid: {
                                                display:
                                                    chart.scales.y.gridDisplay,
                                            },
                                            beginAtZero:
                                                chart.scales.y.beginAtZero,
                                            min: chart.scales.y.min,
                                            max: chart.scales.y.max,
                                            ticks: {
                                                maxRotation: chart.scales.y.ticks.maxRotation,
                                                minRotation: chart.scales.y.ticks.minRotation,
                                                display: chart.scales.y.ticks.display,
                                                callback: function(value, _index, _ticks) {
                                                    const chartTicksValue = chart.scales.y.ticks.value;
                                                    return chartTicksValue.prefix + this.getLabelForValue(value) + chartTicksValue.suffix;
                                                }
                                            }
                                        },
                                        yRight: {
                                            type: "linear",
                                            display:
                                                chart.scales.yRight.display,
                                            stacked:
                                                chart.scales.yRight.stacked,
                                            position: "right",
                                            title: {
                                                display:
                                                    chart.scales.yRight
                                                        .titleDisplay,
                                                        text: Array.isArray(chart.scales.yRight.titleText) ? chart.scales.yRight.titleText.map(key => this.props.t(key)) : this.props.t(chart.scales.yRight.titleText),
                                                color: chart.scales.yRight
                                                    .titleColor,
                                            },
                                            grid: {
                                                display:
                                                    chart.scales.yRight
                                                        .gridDisplay,
                                                color: "#ff0000",
                                            },
                                            beginAtZero:
                                            chart.scales.yRight.beginAtZero,
                                            min: chart.scales.yRight.min,
                                            max: chart.scales.yRight.max,
                                            ticks: {
                                                maxRotation: chart.scales.yRight.ticks.maxRotation,
                                                minRotation: chart.scales.yRight.ticks.minRotation,
                                                display: chart.scales.yRight.ticks.display,
                                                callback: function(value, _index, _ticks) {
                                                    const chartTicksValue = chart.scales.yRight.ticks.value;
                                                    return chartTicksValue.prefix + this.getLabelForValue(value) + chartTicksValue.suffix;
                                                }
                                            }
                                        },
                                    },
                                    clip: false,
                                }}
                            />
                        </div>
                    ) : /* API Error or no data and chart type is not picto */
                    chart.typeChart !== "picto" ? (
                        <div className="api-error-no-data-container">
                            <p className="api-error-no-data-description">
                                {dataChart.error !== ""
                                    ? this.props.t(dataChart.error)
                                    : "No data to display for the selected year"}
                            </p>
                            <p className="api-error-no-data-icon"></p>
                        </div>
                    ) : (
                        /* No API Error and Picto chart */
                        <div>
                            <div>{contentDivDataChartKeyFigure}</div>
                        </div>
                    )}
                    {/* Key figure associated with the chart if the position is equal to bottom */}
                    {dataChartKeyFigureLabel !== "" &&
                    dataChartKeyFigurePosition === "bottom"
                        ? contentDivDataChartKeyFigure
                        : ""}
                </div>
            );
        };

        const listCharts = [];
        for (let [key, value] of Object.entries(this.state.chartsData)) {
            if (this.state.data[key]) {
                listCharts.push(
                    <div key={key}>
                        {renderChart(value, this.state.data[key])}
                    </div>
                );
            }
        }

        // Generic function to generate the key figure associated with the indicator
        const renderKeyFigureIndicator = () => {
            let keyFigureIndicator = this.state.keyFigureIndicator;
            let keyFigureIndicatorData = keyFigureIndicator.data;
            let KeyFigureIndicatorLabel = this.props.t(keyFigureIndicator.label);

            if (
                keyFigureIndicatorData &&
                keyFigureIndicatorData !== "None" &&
                keyFigureIndicatorData !== "NaN"
            ) {
                // No space between the key figure value and the percentage character
                if (KeyFigureIndicatorLabel.startsWith("%")) {
                    keyFigureIndicatorData =
                        Number(keyFigureIndicatorData).toLocaleString() + "%";
                    KeyFigureIndicatorLabel =
                        KeyFigureIndicatorLabel.substring(1);
                }
                return (
                    <div className="dashboard-key-figure-container">
                        <p className="dashboard-key-figure-data">
                            {keyFigureIndicatorData}
                        </p>
                        <p className="dashboard-key-figure-label">
                            {KeyFigureIndicatorLabel}
                        </p>
                    </div>
                );
            }
        };

        return (
            <div>
                {renderKeyFigureIndicator()}
                <div className="dashboard-charts-container">{listCharts}</div>
            </div>
        );
    }
}

export default withTranslation()(Charts);
