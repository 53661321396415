import { createSlice } from "@reduxjs/toolkit";

const { config } = require("../settings");
const configGeographicalAreas = config.geographicalAreas;

export const geographicalAreas = createSlice({
    name: "geographicalAreas",
    initialState: {
        listGeographicalAreas: Object.keys(configGeographicalAreas),
        currentGeographicalArea: "Préfecture Rabat",
    },
    reducers: {
        updateCurrentGeographicalArea: (state, props) => {
            const geographicalArea = props.payload;
            state.currentGeographicalArea = geographicalArea;
        },
    },
});

export const { updateCurrentGeographicalArea } = geographicalAreas.actions;

export default geographicalAreas.reducer;
