import { api_url } from "../../../utils";

const ReseauVoirie = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=reseau_voirie&schema_city=",
    zindex: 2,
    type: "categorized",
    popup: {
        default: {
            content: {
                fr: {
                    type: "Hiérarchie : ",
                },
                en: {
                    type: "Hierarchy: ",
                    valuesTranslation: {
                        "Autoroute": "Highway",
                        "Principal": "Primary",
                        "Secondaire": "Secondary",
                        "Tertiaire": "Tertiary"
                    }
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.ReseauVoirie",
    style: {
        regular: false,
        fillColor: "rgba(102, 88, 213)",
        strokeColor: "rgb(102, 88, 213)",
        strokeWidth: 0,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.ReseauVoirie.title",
            subtitle: "",
            attribute: "type",
            symbol: "line",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(15, 7, 5)",
                    strokeColor: "rgb(15, 7, 5)",
                    strokeWidth: 4,
                    value: "Autoroute",
                    label: "Mobility Indicators Content.layersLegend.ReseauVoirie.classes.1",
                },
                {
                    fillColor: "rgb(142, 134, 131)",
                    strokeColor: "rgb(142, 134, 131)",
                    strokeWidth: 4,
                    value: "Principal",
                    label: "Mobility Indicators Content.layersLegend.ReseauVoirie.classes.2",
                },
                {
                    fillColor: "rgb(142, 134, 131)",
                    strokeColor: "rgb(142, 134, 131)",
                    strokeWidth: 2,
                    value: "Secondaire",
                    label: "Mobility Indicators Content.layersLegend.ReseauVoirie.classes.3",
                },
                {
                    fillColor: "rgb(142, 134, 131)",
                    strokeColor: "rgb(142, 134, 131)",
                    strokeWidth: 1,
                    value: "Tertiaire",
                    label: "Mobility Indicators Content.layersLegend.ReseauVoirie.classes.4",
                },
            ],
        },
    },
};

export default ReseauVoirie;
