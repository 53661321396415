import * as React from "react";
import TreeView from "@mui/lab/TreeView";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch } from "react-redux";
import {
    reverseCheckedStatusLayer,
    updateCheckedStatusTreeview,
    updateExpandStatusTreeviewLayers,
} from "../../stores/MapLayersMobilityIndicators";
import "../../styles/TreeViewCheckbox.css";
import { maxWidth } from "@mui/system";
import store from "../../store";
import { useTranslation } from "react-i18next";
import { MinusSquare, PlusSquare, CloseSquare, StyledTreeItem, countLayersCheckedDisplayMap } from "../../utils";

export default function TreeViewCheckboxMapLayersMobilityIndicators(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const typeTreeView = "layers";
    const checkedTreeView = props.data.treeviews[typeTreeView].checked;
    const titleTreeView = t(props.data.treeviews.title);
    const idSection = props.data.idSection;

    const storeMapLayersMobilityIndicators =
        store.getState().mapLayersMobilityIndicators;
    const layers = storeMapLayersMobilityIndicators[idSection].layers;
    const currentCity = store.getState().cities.currentCity;

    // Update the status of the checkbox of the treeview layers according to the status of the checkboxes of the layers
    const updateCheckedStatusTreeviewLayer = (focusSection) => {
        let idCheckedStatus = "";
        let arrayCheckedStatusLayer = [];
        for (let value of Object.values(
            store.getState().mapLayersMobilityIndicators[focusSection].layers
        )) {
            // Check that the layer is not excluded for this city
            if (!value.excludeCities.includes(currentCity)) {
                arrayCheckedStatusLayer.push(value.checked);
            }
        }
        let checkerStatusLayer = arrayCheckedStatusLayer.every(
            (statusChecked) => statusChecked === true
        );
        if (checkerStatusLayer === true) {
            idCheckedStatus = 2;
        } else {
            checkerStatusLayer = arrayCheckedStatusLayer.every(
                (statusChecked) => statusChecked === false
            );

            if (checkerStatusLayer === true) {
                idCheckedStatus = 0;
            } else {
                idCheckedStatus = 1;
            }
        }
        dispatch(
            updateCheckedStatusTreeview({
                idSection: focusSection,
                idCheckedStatus: idCheckedStatus,
                typeTreeView: "layers",
            })
        );
    };

    // When the user clicks on the checkbox of the treeview, do not trigger the expand/collapse
    // When the users clicks on the treeview, expand/collapse it
    const handleToggle = (event) => {
        if (event.target.type === "checkbox") {
            const currentCheckedTreeView =
                storeMapLayersMobilityIndicators[idSection].treeviews[
                    typeTreeView
                ].checked;
            let idCheckedStatus = "";
            // Layer treeview
            let typeKeys = storeMapLayersMobilityIndicators[idSection].layers;

            // Reverse the status of all layer checkboxes if the treeview checkbox is checked/unchecked
            if ([0, 2].includes(currentCheckedTreeView)) {
                for (let key of Object.keys(typeKeys)) {
                    dispatch(
                        reverseCheckedStatusLayer({
                            idSection: idSection,
                            idLayer: key,
                        })
                    );
                }
                idCheckedStatus = currentCheckedTreeView === 2 ? 0 : 2;
            }
            // Reverse the status of all unchecked layer checkboxes if the treeview checkbox is indeterminate
            else {
                for (let [key, value] of Object.entries(typeKeys)) {
                    if (value.checked === false) {
                        dispatch(
                            reverseCheckedStatusLayer({
                                idSection: idSection,
                                idLayer: key,
                            })
                        );
                    }
                }
                idCheckedStatus = 2;
            }

            // Update the status of the layer treeview
            dispatch(
                updateCheckedStatusTreeview({
                    idSection: idSection,
                    idCheckedStatus: idCheckedStatus,
                    typeTreeView: typeTreeView,
                })
            );

            // Adjusting the map display
            countLayersCheckedDisplayMap(dispatch);
        }
        // Expand/Collapse the treeview by updating the expand value in the store
        else {
            const currentExpandTreeView =
                storeMapLayersMobilityIndicators[idSection].treeviews[
                    typeTreeView
                ].expand;
            const idExpandStatus =
                currentExpandTreeView === true ? false : true;
            dispatch(
                updateExpandStatusTreeviewLayers({
                    idSection: idSection,
                    idExpandStatus: idExpandStatus,
                    typeTreeView: typeTreeView,
                })
            );
        }

        // Update the status of the checkbox of the treeview layers according to the status of the checkboxes of the layers
        updateCheckedStatusTreeviewLayer(idSection);
    };

    // Update check status in the store when the user clicks on the checkbox of a layer
    const handleClick = (event) => {
        const idLayer = event.target.id;

        // Update the checked status of the layer
        dispatch(
            reverseCheckedStatusLayer({
                idSection: idSection,
                idLayer: idLayer,
            })
        );

        // Retrieve the checked status of each layer and update the status of the treeview checkbox
        let arrayCheckedStatusLayer = [];
        let typeKeys =
            store.getState().mapLayersMobilityIndicators[idSection].layers;

        for (let value of Object.values(typeKeys)) {
            // Check that the layer is not excluded for this city
            if (
                !(
                    typeTreeView === "layers" &&
                    value.excludeCities.includes(currentCity)
                )
            ) {
                arrayCheckedStatusLayer.push(value.checked);
            }
        }
        let idCheckedStatus = "";
        let checkerStatusLayer = arrayCheckedStatusLayer.every(
            (statusChecked) => statusChecked === true
        );
        if (checkerStatusLayer === true) {
            idCheckedStatus = 2;
        } else {
            let checkerStatusLayer = arrayCheckedStatusLayer.every(
                (statusChecked) => statusChecked === false
            );
            if (checkerStatusLayer === true) {
                idCheckedStatus = 0;
            } else {
                idCheckedStatus = 1;
            }
        }
        dispatch(
            updateCheckedStatusTreeview({
                idSection: idSection,
                idCheckedStatus: idCheckedStatus,
                typeTreeView: typeTreeView,
            })
        );

        // Adjusting the map display
        countLayersCheckedDisplayMap(dispatch);
    };

    return (
        <div className="treeview-container">
            <TreeView
                aria-label="customized"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                sx={{ overflowX: "hidden" }}
                expanded={
                    store.getState().mapLayersMobilityIndicators[idSection]
                        .treeviews[typeTreeView].expand === false
                        ? []
                        : ["0"]
                }
                onNodeToggle={handleToggle}
            >
                <StyledTreeItem
                    nodeId="0"
                    label={
                        <FormControlLabel
                            label={t(titleTreeView)}
                            control={
                                <Checkbox
                                    checked={checkedTreeView === 2}
                                    indeterminate={checkedTreeView === 1}
                                />
                            }
                        />
                    }
                >
                    {Object.keys(layers)
                        .filter(
                            (layer) =>
                                layers[layer].excludeCities.includes(
                                    currentCity
                                ) === false
                        )
                        .map((keyName, i) => (
                            <FormControlLabel
                                key={i}
                                label={t(layers[keyName].titleSideBar)}
                                control={
                                    <Checkbox
                                        id={keyName}
                                        checked={layers[keyName].checked}
                                        onClick={handleClick}
                                        className="indicator-check"
                                        sx={{ width: maxWidth }}
                                    />
                                }
                            />
                        ))}
                </StyledTreeItem>
            </TreeView>
        </div>
    );
}
