import { createSlice } from "@reduxjs/toolkit";
const { api_url } = require("../settings");

export const references = createSlice({
    name: "references",
    initialState: {
        url: api_url + "references/bibliography/",
        fr: {
            title: `<h1 class="references-title">Références - Observatoire de la Mobilité</h1>`,
            content_production: `
            <h2 class="references-section-title">Contenu et réalisation</h2>
            <p>
            La réalisation de l'Observatoire de la Mobilité pour le Plan de Mobilité Urbaine Durable (PMUD) 2040 de la conurbation
            Rabat-Salé-Skhirate-Témara suit la démarche MobiliseYourCity et s'appuie sur la plateforme numérique développée initialement
            dans le cadre des Observatoires en Inde. Il s'agit d'un outil partenarial amené à être mutualisé avec d’autres démarches
            similaires de plans de mobilité au Maroc et à l'international.
            Le choix d'indicateurs et leur représentation a été élaboré par RRM à partir de la plateforme déjà créée et des
            recommandations du PMUD.
            </p>`,
            technical_development: `<h2 class="references-section-title">Développement technique de la plateforme par Oslandia</h2>
            <p>
            Système de gestion : ReactJS / Python Flask / OpenLayers / ChartJS / Postgres / Postgis
            Oslandia. Geographical Information Systems. 4 Place Louis Armand, 75012 Paris, France |
            <a href="https://oslandia.com/">https://oslandia.com/</a>
            </p>`,
            bibliography: `<h2 class="references-section-title">Bibliographie PMUD</h2>`,
            more_informations: `
            <h2 class="references-section-title">Pour plus d'informations</h2>
            <p>
            MobiliseYourCity | <a href="https://www.mobiliseyourcity.net/fr">https://www.mobiliseyourcity.net/fr</a>
            </p>`
        },
        en: {
            title: `<h1 class="references-title">References – Urban Mobility Observatory</h1>`,
            content_production: `<h2 class="references-section-title">Content and production</h2>
            <p>
            The creation of the Urban Mobility Observatory for the 2040 Sustainable Urban Mobility Plan (SUMP) for the Rabat-Sale-Skhirate-Temara conurbation follows the MobiliseYourCity approach and it is based on the digital platform initially developed within the framework of the Indian Observatories. This is a partnership platform intended to be shared with other similar mobility plan approaches both in Morocco and internationally. The choice of indicators and their representation come from RRM based on the platform already created and the SUMP recommendations.
            </p>`,
            technical_development: `<h2 class="references-section-title">Technical development of the platform by Oslandia</h2>
            <p>
            Management system: ReactJS / Python Flask / OpenLayers / ChartJS / Postgres / Postgis
            Oslandia. Geographical Information Systems. 4 Place Louis Armand, 75012 Paris, France |
            <a href="https://oslandia.com/">https://oslandia.com/</a>
            </p>`,
            bibliography: `<h2 class="references-section-title">SUMP Publications</h2>`,
            more_informations: `<h2 class="references-section-title">For more information</h2>
            <p>
            MobiliseYourCity | <a href="https://www.mobiliseyourcity.net/">https://www.mobiliseyourcity.net/</a>
            </p>`
        },
    }
});

export default references.reducer;
