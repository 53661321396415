import { api_url } from "../../../utils";

const MotorisationMenages = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=motorisation_menages&schema_city=",
    zindex: 1,
    type: "graduated",
    popup: {
        default: {
            content: {
                fr: {
                    l_comar: "Nom de la commune ou de l'arrondissement : ",
                    veh_1000h: "Nombre de voitures / 1000 habitants : ",
                    mot_1000h: "Nombre de motos / 1000 habitants : ",
                },
                en: {
                    l_comar: "Name of the Municipality or District: ",
                    veh_1000h: "Number of Cars per 1000 Inhabitants: ",
                    mot_1000h: "Number of Motorcycles per 1000 Inhabitants: ",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.MotorisationMenages",
    style: {
        regular: false,
        fillColor: "rgba(0, 0, 0)",
        strokeColor: "rgb(0, 0, 0)",
        strokeWidth: 0,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.MotorisationMenages.title",
            subtitle: "",
            attribute: "veh_1000h",
            symbol: "rectangle",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(247, 251, 255)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    min: 59,
                    max: 99,
                    label: "Mobility Indicators Content.layersLegend.MotorisationMenages.classes.1",
                },
                {
                    fillColor: "rgb(200, 220, 240)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    min: 100,
                    max: 199,
                    label: "Mobility Indicators Content.layersLegend.MotorisationMenages.classes.2",
                },
                {
                    fillColor: "rgb(115, 178, 216)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    min: 200,
                    max: 299,
                    label: "Mobility Indicators Content.layersLegend.MotorisationMenages.classes.3",
                },
                {
                    fillColor: "rgb(41, 121, 185)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    min: 300,
                    max: 399,
                    label: "Mobility Indicators Content.layersLegend.MotorisationMenages.classes.4",
                },
                {
                    fillColor: "rgb(8, 48, 107)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    min: 400,
                    max: 499,
                    label: "Mobility Indicators Content.layersLegend.MotorisationMenages.classes.5",
                },
            ],
        },
    },
};

export default MotorisationMenages;
