import { api_url } from "../../../utils";

const StationsGrandsTaxis = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=stations_grands_taxis&schema_city=",
    zindex: 3,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_station: "Nom Station GT :",
                    direction: "Direction GT :",
                },
                en: {
                    l_station: "Name of Large Taxi Station: ",
                    direction: "Direction of Large Taxis: ",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.StationsGrandsTaxis",
    style: {
        regular: false,
        fillColor: "rgb(255, 192, 0)",
        strokeColor: "rgb(35, 35, 35)",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 4,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.StationsGrandsTaxis.title",
            subtitle: "",
            attribute: "",
            symbol: "point",
            legendCustomCssClass:
                "map-legend-symbol-8px map-legend-symbol-border-1px-gray-35-35-35",
            class: [
                {
                    fillColor: "rgb(255, 192, 0)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 0,
                    label: "Mobility Indicators Content.layersLegend.StationsGrandsTaxis.classes.1",
                },
            ],
        },
    },
};

export default StationsGrandsTaxis;
