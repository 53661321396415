import { api_url } from "../../../utils";

const Parkings = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=parkings&schema_city=",
    zindex: 3,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_parking: "Nom parking :",
                    gestion: "Gestionnaire :",
                    nb_places: "Capacité : ",
                },
                en: {
                    l_parking: "Parking Name: ",
                    gestion: "Operator: ",
                    nb_places: "Capacity: ",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.Parkings",
    style: {
        regular: true,
        fillColor: "rgba(72, 123, 182, 1)",
        strokeColor: "rgba(248, 248, 249, 1)",
        strokeWidth: 2,
        strokeLineDash: [],
        points: 4,
        radius: 6,
        angle: 25,
        rotation: 30,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.Parkings.title",
            subtitle: "",
            attribute: "",
            symbol: "diamond",
            legendCustomCssClass:
                "map-legend-symbol-8px map-legend-symbol-border-2px-white",
            class: [
                {
                    fillColor: "rgba(72, 123, 182, 1)",
                    strokeColor: "rgba(248, 248, 249, 1)",
                    strokeWidth: 0,
                    label: "Mobility Indicators Content.layersLegend.Parkings.classes.1",
                },
            ],
        },
    },
};

export default Parkings;
