import { api_url } from "../../../utils";

const LignesTram = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=lignes_tram&schema_city=",
    zindex: 2,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    num_ligne: "Numéro de la ligne de tram : ",
                    freq: "Fréquence moyenne de la ligne : ",
                },
                en: {
                    num_ligne: "Tram Line Number: ",
                    freq: "Average frequency of the line: ",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.LignesTram",
    style: {
        regular: false,
        fillColor: "rgba(102, 88, 213)",
        strokeColor: "rgb(102, 88, 213)",
        strokeWidth: 2,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.LignesTram.title",
            subtitle: "",
            attribute: "",
            symbol: "line",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(102, 88, 213)",
                    strokeColor: "rgb(102, 88, 213)",
                    strokeWidth: 3,
                    label: "Mobility Indicators Content.layersLegend.LignesTram.classes.1",
                },
            ],
        },
    },
};

export default LignesTram;
