import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partMobilityIndicators,
    popupInformation,
    defaultBarPercentage,
    defaultCategoryPercentage,
    defaultDatalabelsAlign,
    defaultTicksCustomValue,
} from "../../../utils";

const NbDeplacementsQuotidiens = {
    checked: false,
    titleSideBar: "Mobility Indicators Content.indicators.NbDeplacementsQuotidiens",
    titleIndicator: "Mobility Indicators Content.indicators.NbDeplacementsQuotidiens",
    popupInformation: {
        status: "false",
        text: popupInformation[partMobilityIndicators]["nbDeplacementsQuotidiens"],
    },
    keyFigure: {
        typeUpdate: "year", // year or geographical_area
        label: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.keyFigure.trips",
        url: api_url +
        "charts/data/?part=mobility-indicators&type_label=rabat_years&indicator=nb_deplacements_quotidiens&trend=projection&chart=nb_deplacements_quotidiens_rabat&chart_data=False&indicator_key_figure=True&schema_city=",
        separator: "thousand",
        position: "",
    },
    charts: [
        {
            typeChart: "bar",
            typeLabels: "geographical_areas", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "year", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "false",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: false,
            indexAxis: "y", // x or y
            heightChart: "200",
            widthChart: "600",
            scales: {
                x: {
                    type: "linear",
                    display: true,
                    stacked: true,
                    titleDisplay: true,
                    titleText: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.axis.titleX",
                    gridDisplay: false,
                    beginAtZero: true,
                    min: 0,
                    max: 7000000,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "category",
                    display: true,
                    stacked: true,
                    titleDisplay: true,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    ticks: {
                        display: false,
                        value: defaultTicksCustomValue
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.legend.Préfecture Rabat - 26% des déplacements dans la conurbation",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=nb_deplacements_quotidiens&trend=projection&chart=nb_deplacements_quotidiens_rabat&schema_city=",
                    backgroundColor: configChartsColorPalettes["0"].backgroundColor[0],
                    borderColor: configChartsColorPalettes["0"].borderColor[0],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.tooltipUnit. déplacements / jour",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: defaultCategoryPercentage,
                    datalabelsProperties: {
                        display: true,
                        anchor: "start",
                        align: defaultDatalabelsAlign,
                        color: "#FFFFFF",
                        font: {
                            weight: "bold"
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.legend.Préfecture Salé - 49% des déplacements dans la conurbation",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=nb_deplacements_quotidiens&trend=projection&chart=nb_deplacements_quotidiens_sale&schema_city=",
                    backgroundColor: configChartsColorPalettes["0"].backgroundColor[1],
                    borderColor: configChartsColorPalettes["0"].borderColor[1],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.tooltipUnit. déplacements / jour",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: defaultCategoryPercentage,
                    datalabelsProperties: {
                        display: true,
                        anchor: "start",
                        align: defaultDatalabelsAlign,
                        color: "#FFFFFF",
                        font: {
                            weight: "bold"
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.legend.Préfecture Skhirat-Témara - 22% des déplacements dans la conurbation",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=nb_deplacements_quotidiens&trend=projection&chart=nb_deplacements_quotidiens_skhirat&schema_city=",
                    backgroundColor: configChartsColorPalettes["0"].backgroundColor[2],
                    borderColor: configChartsColorPalettes["0"].borderColor[2],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "Mobility Indicators Content.charts.NbDeplacementsQuotidiens.tooltipUnit. déplacements / jour",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: defaultCategoryPercentage,
                    datalabelsProperties: {
                        display: true,
                        anchor: "start",
                        align: defaultDatalabelsAlign,
                        color: "#FFFFFF",
                        font: {
                            weight: "bold"
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
            ],
        },
    ],
};

export default NbDeplacementsQuotidiens;
