// api : default to 127.0.0.1:9001
var api_port = process.env.REACT_APP_API_FLASK_SERVER_TEST_PORT || "9001";
var api_host = process.env.REACT_APP_API_FLASK_SERVER_HOST || "localhost";
if (api_host === "localhost") {
    var api_url = "http://" + api_host + ":" + api_port + "/api/";
} else {
    var api_url = "https://" + api_host + "/api/";
}
console.log("api_url : " + api_url);

// pg_tileserv : default to 127.0.0.1:7800
var pg_tileserv_port = process.env.REACT_APP_PG_TILESERV_PORT || "7800";
var pg_tileserv_host =
    process.env.REACT_APP_PG_TILESERV_SERVER_HOST || "localhost";
if (pg_tileserv_host === "localhost") {
    var pg_tileserv_url =
        "http://" + pg_tileserv_host + ":" + pg_tileserv_port + "/";
} else {
    var pg_tileserv_url = "https://" + pg_tileserv_host + "/pg_tileserv/";
}
console.log("pg_tileserv_url : " + pg_tileserv_url);

module.exports = {
    api_url,
    pg_tileserv_url,
};
