import GaresFerroviaires from "./layers/GaresFerroviaires";
import VoiesFerrees from "./layers/VoiesFerrees";
import GaresRoutieres from "./layers/GaresRoutieres";
import LignesTram from "./layers/LignesTram";
import StationsTram from "./layers/StationsTram";
import LignesBus from "./layers/LignesBus";
import ArretsBus from "./layers/ArretsBus";
import ItinerairesGrandsTaxis from "./layers/ItinerairesGrandsTaxis";
import StationsGrandsTaxis from "./layers/StationsGrandsTaxis";
import Parkings from "./layers/Parkings";
import ParcsRelais from "./layers/ParcsRelais";
import ReseauVoirie from "./layers/ReseauVoirie";
// import VitesseCommercialeTransportPublic from "./indicators/VitesseCommercialeTransportPublic";
import NbPetitsGrandsTaxis from "./indicators/NbPetitsGrandsTaxis";
import NbParkingsOuvrage from "./indicators/NbParkingsOuvrage";
import PartPopulationDesserteTc from "./indicators/PartPopulationDesserteTc";
import ParcBusElectrique from "./indicators/ParcBusElectrique";
import KmTcsp from "./indicators/KmTcsp";
// import KmVoiesCyclables from "./indicators/KmVoiesCyclables";
// import NbBornesRechargePublique from "./indicators/NbBornesRechargePublique";
import NbPlacesParkingsOuvrage from "./indicators/NbPlacesParkingsOuvrage";
import NbParcsRelais from "./indicators/NbParcsRelais";
import NbPlacesParcsRelais from "./indicators/NbPlacesParcsRelais";

const MobilityOffer = {
    idSection: "mobilityOffer",
    questionSection: "",
    treeviews: {
        title: "Mobility Indicators Content.sections.MobilityOffer",
        indicators: {
            checked: 0,
            expand: false,
        },
        layers: {
            checked: 2,
            expand: true,
        },
    },
    layers: {
        reseauVoirie: ReseauVoirie,
        garesFerroviaires: GaresFerroviaires,
        voiesFerrees: VoiesFerrees,
        garesRoutieres: GaresRoutieres,
        lignesTram: LignesTram,
        stationsTram: StationsTram,
        lignesBus: LignesBus,
        arretsBus: ArretsBus,
        itinerairesGrandsTaxis: ItinerairesGrandsTaxis,
        stationsGrandsTaxis: StationsGrandsTaxis,
        parkings: Parkings,
        parcsRelais: ParcsRelais,
    },
    indicators: {
        // vitesseCommercialeTransportPublic: VitesseCommercialeTransportPublic,
        parcBusElectrique: ParcBusElectrique,
        kmTcsp: KmTcsp,
        nbPetitsGrandsTaxis: NbPetitsGrandsTaxis,
        // kmVoiesCyclables: KmVoiesCyclables,
        // nbBornesRechargePublique: NbBornesRechargePublique,
        nbParkingsOuvrage: NbParkingsOuvrage,
        nbPlacesParkingsOuvrage: NbPlacesParkingsOuvrage,
        nbParcsRelais: NbParcsRelais,
        nbPlacesParcsRelais: NbPlacesParcsRelais,
        partPopulationDesserteTc: PartPopulationDesserteTc,
    },
};

export default MobilityOffer;
