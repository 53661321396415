import { createSlice } from "@reduxjs/toolkit";
import PopulationUrbanization from "./part-mobility-indicators/sections/PopulationUrbanization";
import MobilityDemand from "./part-mobility-indicators/sections/MobilityDemand";
import MobilityOffer from "./part-mobility-indicators/sections/MobilityOffer";
import Externality from "./part-mobility-indicators/sections/Externality";
import Economy from "./part-mobility-indicators/sections/Economy";

export const dashboardMobilityIndicators = createSlice({
    name: "dashboardMobilityIndicators",
    initialState: {
        populationUrbanization: PopulationUrbanization,
        mobilityDemand: MobilityDemand,
        mobilityOffer: MobilityOffer,
        externality: Externality,
        economy: Economy,
    },
    reducers: {
        // Reverse the checked status of the indicator/layer checkbox
        reverseCheckedStatusIndicatorLayer: (state, props) => {
            const idSection = props.payload.idSection;
            const idIndicatorLayer = props.payload.idIndicatorLayer;
            const typeTreeView = props.payload.typeTreeView;
            if (typeTreeView === "indicators") {
                state[idSection].indicators[idIndicatorLayer].checked =
                    !state[idSection].indicators[idIndicatorLayer].checked;
            } else {
                state[idSection].layers[idIndicatorLayer].checked =
                    !state[idSection].layers[idIndicatorLayer].checked;
            }
        },
        // Update the checked status of the layer checkbox
        updateCheckedStatusLayer: (state, props) => {
            const idSection = props.payload.idSection;
            const idLayer = props.payload.idLayer;
            const idCheckedStatus = props.payload.idCheckedStatus;
            state[idSection].layers[idLayer].checked = idCheckedStatus;
        },
        // Update the checked status of the treeview checkbox: unchecked, indeterminate, checked
        updateCheckedStatusTreeview: (state, props) => {
            const idSection = props.payload.idSection;
            const idCheckedStatus = props.payload.idCheckedStatus;
            const typeTreeView = props.payload.typeTreeView;
            state[idSection].treeviews[typeTreeView].checked = idCheckedStatus;
        },
        // Update the expand status of the treeview checkbox
        updateExpandStatusTreeviewIndicators: (state, props) => {
            const idSection = props.payload.idSection;
            const idExpandStatus = props.payload.idExpandStatus;
            const typeTreeView = props.payload.typeTreeView;
            state[idSection].treeviews[typeTreeView].expand = idExpandStatus;
        },
    },
});

export const {
    reverseCheckedStatusIndicatorLayer,
    updateCheckedStatusLayer,
    updateCheckedStatusTreeview,
    updateExpandStatusTreeviewIndicators,
} = dashboardMobilityIndicators.actions;

export default dashboardMobilityIndicators.reducer;
