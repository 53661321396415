import { createSlice } from "@reduxjs/toolkit";
import ChallengeOne from "./part-sump-trajectory/sections/ChallengeOne";
import ChallengeTwo from "./part-sump-trajectory/sections/ChallengeTwo";
import ChallengeThree from "./part-sump-trajectory/sections/ChallengeThree";
import ChallengeFour from "./part-sump-trajectory/sections/ChallengeFour";

export const dashboardSumpTrajectory = createSlice({
    name: "dashboardSumpTrajectory",
    initialState: {
        challengeOne: ChallengeOne,
        challengeTwo: ChallengeTwo,
        challengeThree: ChallengeThree,
        challengeFour: ChallengeFour,
    },
});

export default dashboardSumpTrajectory.reducer;
