import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import "../styles/AppBar.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import preval from "preval.macro";
import Select from "@mui/material/Select";
import store from "../store";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../img/logo.png";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/LanguageSelector";

const ResponsiveAppBar = () => {
    const storeRoutes = store.getState().routes;
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const { t } = useTranslation();

    const pages = [
        { label: t("Mobility Indicators"), id: "mobility-indicators", display: storeRoutes.displayTabMobilityIndicators },
        { label: t("SUMP Trajectory"), id: "sump-trajectory", display: storeRoutes.displayTabSumpTrajectory },
        { label: t("SUMP Management"), id: "sump-management", display: storeRoutes.displayTabSumpManagement },
        { label: t("Digital Participation"), id: "digital-participation", display: storeRoutes.displayTabDigitalParticipation },
        { label: t("References"), id: "references", display: storeRoutes.displayTabReferences },
    ];

    let listCities = Object.keys(store.getState().cities.listCities);
    let currentCityCapitalize = useSelector((_state) =>
        store
            .getState()
            .cities.currentCity.replace(/\w/, (firstLetter) =>
                firstLetter.toUpperCase()
            )
    );
    let listCitiesCapitalize = listCities.map((cityName) =>
        cityName.replace(/\w/, (cityName) => cityName.toUpperCase())
    );
    const [currentCity, setCurrentCity] = useState(currentCityCapitalize);

    const location = useLocation();
    const navigate = useNavigate();

    // Update the value of the Select when the url changes
    useEffect(() => {
        setCurrentCity(currentCityCapitalize);
    }, [currentCityCapitalize]);

    // Update of the current city in the store and in the Select + update of the city in the url
    const handleCityChange = (event) => {
        let cityName = event.target.value;
        let cityNameLowerCase = cityName.toLowerCase();
        setCurrentCity(cityName);
        if (location.pathname.includes("mobility-indicators")) {
            navigate("/sump/mobility-indicators/" + cityNameLowerCase);
        } else if (location.pathname.includes("sump-trajectory")) {
            navigate("/sump/sump-trajectory/" + cityNameLowerCase);
        } else if (location.pathname.includes("sump-management")) {
            navigate("/sump/sump-management/" + cityNameLowerCase);
        } else if (location.pathname.includes("digital-participation")) {
            navigate("/sump/digital-participation/" + cityNameLowerCase);
        } else if (location.pathname.includes("references")) {
            navigate("/sump/references");
        }
    };

    const displayCitiesSelector = listCitiesCapitalize.length > 1 ? "" : "none";

    return (
        <AppBar
            sx={{
                position: "static",
                height: "68px",
            }}
        >
            <Container maxWidth="">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            minWidth: "190px",
                        }}
                        className="back-to-home"
                    >
                        <img src={logo} alt="logo" />
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                page.display === true ? (
                                <MenuItem
                                    key={page.id}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        <Link
                                            style={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                            to={`/${page.label}`}
                                        >
                                            {page.label}
                                        </Link>
                                    </Typography>
                                </MenuItem>) : null
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        {<img src={logo} alt="logo" />}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                        className="menu-indicators"
                    >
                        {pages.map((page) => (
                            page.display === true ? (
                            <Button
                                key={page.id}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: "white", display: "block" }}
                                // Soucis de id / label, il faut l'id et non le label
                                className={
                                    location.pathname.includes(page.id)
                                        ? "current-menu-tab"
                                        : ""
                                }
                            >
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "#5a3601",
                                    }}
                                    to={`/sump/${page.id}`}
                                >
                                    {page.label}
                                </Link>
                            </Button>) : null
                        ))}
                    </Box>
                    <Box
                        id="box-select-city"
                        style={{ display: displayCitiesSelector }}
                    >
                        <Select
                            id="appbar-select-city"
                            defaultValue={currentCity}
                            value={currentCity}
                            onChange={handleCityChange}
                        >
                            {listCitiesCapitalize.map((cityName, i) => {
                                return (
                                    <MenuItem value={cityName} key={i}>
                                        {cityName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>

                    <LanguageSelector></LanguageSelector>

                    <div id="build-date">
                        Build Date:{" "}
                        {preval`module.exports = new Date().toLocaleString();`}
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default ResponsiveAppBar;
