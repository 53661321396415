import { createSlice } from "@reduxjs/toolkit";

const { api_url } = require("../settings");

export const map = createSlice({
    name: "map",
    initialState: {
        display: true,
        fullScreen: false,
        legendDisplay: true,
        layerCityBoundary: {
            enable: true,
            checked: true,
            initialDisplay: true,
            source: "api",
            url: api_url + "maps/geojson/?table=boundary&schema_city=",
            zindex: 0,
            type: "single",
            popup: {
                default: {
                    content: {
                        fr: {
                        },
                        en: {
                        }
                    },
                    separators: {
                        decimal: [],
                        thousand: [],
                    },
                },
            },
            titleSideBar: "Zoning",
            style: {
                regular: false,
                fillColor: "rgba(0, 0, 0, 0)",
                strokeColor: "#393E46",
                strokeWidth: 2,
                strokeLineDash: [3, 8],
                points: 0,
                radius: 0,
                angle: 0,
                rotation: 0,
            },
            legend: {
                default: {
                    title: "Zoning",
                    subtitle: "",
                    attribute: "",
                    symbol: "rectangle",
                    legendCustomCssClass: "",
                    class: [
                        {
                            fillColor: "rgba(0, 0, 0, 0)",
                            strokeColor: "#393E46",
                            strokeWidth: 2,
                            label: "Boundaries",
                        },
                    ],
                },
            },
        },
    },
    reducers: {
        reverseMapDisplay: (state) => {
            state.display = !state.display;
        },
        reverseMapFullScreen: (state) => {
            state.fullScreen = !state.fullScreen;
        },
        resetMapFullScreen: (state) => {
            state.fullScreen = false;
        },
        reverseLegendDisplay: (state) => {
            state.legendDisplay = !state.legendDisplay;
        },
    },
});

export const {
    reverseMapDisplay,
    reverseMapFullScreen,
    resetMapFullScreen,
    reverseLegendDisplay,
} = map.actions;

export default map.reducer;
