// REST API & pg_tileserv
var { api_url, pg_tileserv_url } = require("./settings-server");

// Languages
const defaultLanguage = "fr";

const config = {
    defaultLanguage: defaultLanguage,
    home: {
        title: `homeTitle`,
        speech: `homeSpeech`,
        help: `homeHelp`,
    },
    cities: {
        rabat: {
            center: [-6.84, 33.8],
            zoom: 9.9,
            extent: [-830000, 3910000, -690000, 4090000],
        },
    },
    displayTabs: {
        displayTabMobilityIndicators: true,
        displayTabSumpTrajectory: true,
        displayTabSumpManagement: true,
        displayTabDigitalParticipation: true,
        displayTabReferences: true},
    years: ["2011", "2015", "2022"],
    geographicalAreas: {
        "Préfecture Rabat": "préfecture rabat",
        "Préfecture Salé": "préfecture salé",
        "Préfecture Skhirat-Témara": "préfecture skhirat_témara",
        "Conurbation": "conurbation",
    },
    // https://materialui.co/flatuicolors/
    // https://materialui.co/colors
    charts: {
        xAxisMinYearMobilityIndicators: 2011,
        xAxisMaxYearMobilityIndicators: 2022,
        xAxisMinYearSumpTrajectory: 2011,
        xAxisMaxYearSumpTrajectory: 2050,
        maxRadiusMapPie: 30,
        colorPalettes: {
            // Geographical areas : Préfecture Rabat, Préfecture Salé, Préfecture Skhirat-Témara, Conurbation et Sidi Taïbi
            0: {
                backgroundColor: ["rgb(0, 86, 27)", "rgb(167, 0, 30)", "rgb(118, 205, 205)", "rgb(210, 180, 140)"],
                borderColor: ["rgb(0, 86, 27)", "rgb(167, 0, 30)", "rgb(118, 205, 205)", "rgb(210, 180, 140)"],
                borderWidth: 1,
            },
            // Opacity (PartActifsHabitants indicator)
            "0_opacity_part_actifs_habitants": {
                backgroundColor: ["rgba(0, 86, 27, 0.4)", "rgba(167, 0, 30, 0.4)", "rgba(118, 205, 205, 0.4)", "rgba(210, 180, 140, 0.4)"],
                borderColor: ["rgba(0, 86, 27, 0.4)", "rgba(167, 0, 30, 0.4)", "rgba(118, 205, 205, 0.4)", "rgba(210, 180, 140, 0.4)"],
                borderWidth: 1,
            },
            // Geographical areas : Préfecture Salé, Préfecture Rabat, Préfecture Skhirat-Témara
            "0_sale_rabat_skhirat": {
                backgroundColor: ["rgb(167, 0, 30)", "rgb(0, 86, 27)", "rgb(118, 205, 205)"],
                borderColor: ["rgb(167, 0, 30)", "rgb(0, 86, 27)", "rgb(118, 205, 205)"],
                borderWidth: 1,
            },
            // Colors transmitted notably for the modal split chart
            // Other were added for cartographic needs
            1: {
                backgroundColor: [
                    "rgba(255, 200, 0, 0.4)",
                    "rgba(0, 176, 80, 0.4)",
                    "rgba(250, 50, 150, 0.4)",
                    "rgba(63, 184, 216, 0.4)",
                    "rgba(152, 46, 132, 0.4)",
                    "rgba(146, 208, 80, 0.4)",
                    "rgba(189, 0, 38, 0.4)",
                    "rgba(127, 141, 244, 0.4)",
                    "rgba(254, 168, 47, 0.4)",
                ],
                borderColor: [
                    "rgb(255, 200, 0)",
                    "rgb(0, 176, 80)",
                    "rgb(250, 50, 150)",
                    "rgb(63, 184, 216)",
                    "rgb(152, 46, 132)",
                    "rgb(146, 208, 80)",
                    "rgb(189, 0, 38)",
                    "rgb(127, 141, 244)",
                    "rgb(254, 168, 47)",
                ],
                borderWidth: 1,
            },
            // Transport Public : vitesse commerciale et fréquentation
            2: {
                backgroundColor: [
                    "rgb(0, 32, 96)",
                    "rgb(0, 176, 240)",
                ],
                borderColor: [
                    "rgb(0, 32, 96)",
                    "rgb(0, 176, 240)",
                ],
                borderWidth: 1,
            },
            // Motorisation
            3: {
                backgroundColor: [
                    "rgb(216, 109, 205)",
                    "rgb(242, 206, 239)",
                ],
                borderColor: [
                    "rgb(216, 109, 205)",
                    "rgb(242, 206, 239)",
                ],
                borderWidth: 1,
            },
            // Motorisation
            4: {
                backgroundColor: [
                    "rgb(167, 0, 30)",
                    "rgb(255, 189, 189)",
                ],
                borderColor: [
                    "rgb(167, 0, 30)",
                    "rgb(255, 189, 189)",
                ],
                borderWidth: 1,
            },
            // Répartition modale des déplacements quotidiens
            5: {
                backgroundColor: [
                    "rgb(0, 176, 80)",
                    "rgb(146, 208, 80)",
                    "rgb(242, 206, 239)",
                    "rgb(216, 109, 205)",
                    "rgb(255, 255, 0)",
                    "rgb(255, 192, 0)",
                    "rgb(33, 92, 152)",
                    "rgb(0, 32, 96)",
                    "rgb(0, 176, 240)",
                    "rgb(202, 237, 251)",
                    "rgb(148, 220, 248)",
                    "rgb(247, 199, 172)",
                ],
                borderColor: [
                    "rgb(0, 176, 80)",
                    "rgb(146, 208, 80)",
                    "rgb(242, 206, 239)",
                    "rgb(216, 109, 205)",
                    "rgb(255, 255, 0)",
                    "rgb(255, 192, 0)",
                    "rgb(33, 92, 152)",
                    "rgb(0, 32, 96)",
                    "rgb(0, 176, 240)",
                    "rgb(202, 237, 251)",
                    "rgb(148, 220, 248)",
                    "rgb(247, 199, 172)",
                ],
                borderWidth: 1,
            },
            // Emissions CO2
            6: {
                backgroundColor: [
                    "rgb(33, 92, 152)",
                    "rgb(0, 32, 96)",
                    "rgb(0, 176, 240)",
                    "rgb(255, 192, 0)",
                    "rgb(255, 255, 0)",
                    "rgb(216, 109, 205)",
                    "rgb(242, 206, 239)",
                ],
                borderColor: [
                    "rgb(33, 92, 152)",
                    "rgb(0, 32, 96)",
                    "rgb(0, 176, 240)",
                    "rgb(255, 192, 0)",
                    "rgb(255, 255, 0)",
                    "rgb(216, 109, 205)",
                    "rgb(242, 206, 239)",
                ],
                borderWidth: 1,
            },
            // Budget transports
            7: {
                backgroundColor: [
                    "rgb(247, 199, 172)",
                ],
                borderColor: [
                    "rgb(247, 199, 172)",
                ],
                borderWidth: 1,
            },
            // Taux immobilite
            8: {
                backgroundColor: ["rgb(255, 255, 255)", "rgb(210, 180, 140)"],
                borderColor: ["rgb(210, 180, 140)", "rgb(210, 180, 140)"],
                borderWidth: 1,
            },
            // Taux Mobilite Quotidien (femme/homme)
            9: {
                backgroundColor: ["rgb(255, 255, 255)", "rgb(189, 195, 199)"],
                borderColor: ["rgb(189, 195, 199)", "rgb(189, 195, 199)"],
                borderWidth: 1,
            },
            // Undefined : missing attribute in popup
            undefined: {
                backgroundColor: ["rgba(149, 165, 166, 0.8)"],
                borderColor: ["rgb(149, 165, 166)"],
                borderWidth: 1,
            },
            // Transparent
            "transparent": {
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                borderColor: ["rgba(0, 0, 0, 0)"],
                borderWidth: 0,
            },
            // White
            "white": {
                backgroundColor: ["rgba(255, 255, 255, 1)"],
                borderColor: ["rgba(255, 255, 255, 1)"],
                borderWidth: 0,
            },
            // Trajectory : VolumeDeplacementsJournaliersMode indicator
            "trajectory_volume_deplacements_journaliers_mode": {
                backgroundColor: ["rgb(0, 176, 80)","rgb(146, 208, 80)","rgb(216, 109, 205)","rgb(242, 206, 239)",
                "rgb(255, 192, 0)","rgb(255, 255, 0)","rgb(33, 92, 152)","rgb(0, 32, 96)","rgb(0, 176, 240)",
                "rgb(202, 237, 251)","rgb(148, 220, 248)"],
                borderColor: ["rgb(0, 176, 80)","rgb(146, 208, 80)","rgb(216, 109, 205)","rgb(242, 206, 239)",
                "rgb(255, 192, 0)","rgb(255, 255, 0)","rgb(33, 92, 152)","rgb(0, 32, 96)","rgb(0, 176, 240)",
                "rgb(202, 237, 251)","rgb(148, 220, 248)"],
                borderWidth: 0,
            },
            // Trajectory : VolumeDeplacementsJournaliersMode indicator (objective -> with transparency)
            "trajectory_volume_deplacements_journaliers_mode_objective_transparency": {
                backgroundColor: ["rgba(0, 176, 80, 0.4)","rgba(146, 208, 80, 0.4)","rgba(216, 109, 205, 0.4)","rgba(242, 206, 239, 0.4)",
                "rgba(255, 192, 0, 0.4)","rgba(255, 255, 0, 0.4)","rgba(33, 92, 152, 0.4)","rgba(0, 32, 96, 0.4)",
                "rgba(0, 176, 240, 0.4)","rgba(202, 237, 251, 0.4)","rgba(148, 220, 248, 0.4)"],
                borderColor: ["rgba(0, 176, 80, 0.4)","rgba(146, 208, 80, 0.4)","rgba(216, 109, 205, 0.4)","rgba(242, 206, 239, 0.4)",
                "rgba(255, 192, 0, 0.4)","rgba(255, 255, 0, 0.4)","rgba(33, 92, 152, 0.4)","rgba(0, 32, 96, 0.4)",
                "rgba(0, 176, 240, 0.4)","rgba(202, 237, 251, 0.4)","rgba(148, 220, 248, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionPartModaleDeplacementsQuotidiens indicator
            "trajectory_evolution_part_modale_deplacements_quotidiens": {
                backgroundColor: ["rgb(0, 176, 80)","rgb(146, 208, 80)","rgb(216, 109, 205)","rgb(255, 192, 0)","rgb(0, 176, 240)"],
                borderColor: ["rgb(0, 176, 80)","rgb(146, 208, 80)","rgb(216, 109, 205)","rgb(255, 192, 0)","rgb(0, 176, 240)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionPartModaleDeplacementsQuotidiens indicator (objective -> with transparency)
            "trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency": {
                backgroundColor: ["rgba(0, 176, 80, 0.4)","rgba(146, 208, 80, 0.4)","rgba(216, 109, 205, 0.4)","rgba(255, 192, 0, 0.4)","rgba(0, 176, 240, 0.4)"],
                borderColor: ["rgba(0, 176, 80, 0.4)","rgba(146, 208, 80, 0.4)","rgba(216, 109, 205, 0.4)","rgba(255, 192, 0, 0.4)","rgba(0, 176, 240, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionDemographieDeplacements indicator (Population)
            "trajectory_evolution_demographie_deplacements_population": {
                backgroundColor: ["rgb(233, 113, 50)"],
                borderColor: ["rgb(233, 113, 50)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionDemographieDeplacements indicator (Population) (objective -> with transparency)
            "trajectory_evolution_demographie_deplacements_population_objective_transparency": {
                backgroundColor: ["rgba(233, 113, 50, 0.4)"],
                borderColor: ["rgba(233, 113, 50, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionDemographieDeplacements (Déplacements) indicator
            "trajectory_evolution_demographie_deplacements_deplacements": {
                backgroundColor: ["rgb(82, 182, 227)"],
                borderColor: ["rgb(82, 182, 227)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionDemographieDeplacements (Déplacements) indicator (objective -> with transparency)
            "trajectory_evolution_demographie_deplacements_deplacements_objective_transparency": {
                backgroundColor: ["rgba(82, 182, 227, 0.4)"],
                borderColor: ["rgba(82, 182, 227, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionTauxMobilite indicator
            "trajectory_evolution_taux_mobilite": {
                backgroundColor: ["rgb(247, 199, 172)"],
                borderColor: ["rgb(247, 199, 172)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionTauxMobilite indicator (objective -> with transparency)
            "trajectory_evolution_taux_mobilite_objective_transparency": {
                backgroundColor: ["rgba(247, 199, 172, 0.4)"],
                borderColor: ["rgba(247, 199, 172, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionTauxMotorisation indicator
            "trajectory_evolution_taux_motorisation": {
                backgroundColor: ["rgb(216, 109, 205)","rgb(242, 206, 239)"],
                borderColor: ["rgb(216, 109, 205)","rgb(242, 206, 239)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionTauxMotorisation indicator (objective -> with transparency)
            "trajectory_evolution_taux_motorisation_objective_transparency": {
                backgroundColor: ["rgba(216, 109, 205, 0.4)","rgba(242, 206, 239, 0.4)"],
                borderColor: ["rgba(216, 109, 205, 0.4)","rgba(242, 206, 239, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionDemographie indicator
            "trajectory_evolution_demographie": {
                backgroundColor: ["rgb(0, 86, 27)","rgb(167, 0, 30)","rgb(118, 205, 205)","rgb(120, 33, 112)"],
                borderColor: ["rgb(0, 86, 27)","rgb(167, 0, 30)","rgb(118, 205, 205)","rgb(120, 33, 112)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionDemographie indicator (objective -> with transparency)
            "trajectory_evolution_demographie_objective_transparency": {
                backgroundColor: ["rgba(0, 86, 27, 0.4)","rgba(167, 0, 30, 0.4)","rgba(118, 205, 205, 0.4)","rgba(120, 33, 112, 0.4)"],
                borderColor: ["rgba(0, 86, 27, 0.4)","rgba(167, 0, 30, 0.4)","rgba(118, 205, 205, 0.4)","rgba(120, 33, 112, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionOffreDemandeTc indicator
            "trajectory_offre_demande_tc": {
                backgroundColor: ["rgb(0, 176, 240)","rgb(0, 32, 96)","rgb(97, 203, 243)","rgb(33, 92, 152)",
                "rgb(255, 192, 0)"],
                borderColor: ["rgb(0, 176, 240)","rgb(0, 32, 96)","rgb(97, 203, 243)","rgb(33, 92, 152)",
                "rgb(255, 192, 0)"],
                borderWidth: 0,
            },
            // Trajectory : EvolutionOffreDemandeTc indicator (objective -> with transparency)
            "trajectory_offre_demande_tc_objective_transparency": {
                backgroundColor: ["rgba(0, 176, 240, 0.4)","rgba(0, 32, 96, 0.4)","rgba(97, 203, 243, 0.4)","rgba(33, 92, 152, 0.4)",
                "rgba(255, 192, 0, 0.4)"],
                borderColor: ["rgba(0, 176, 240, 0.4)","rgba(0, 32, 96, 0.4)","rgba(97, 203, 243, 0.4)","rgba(33, 92, 152, 0.4)",
                "rgba(255, 192, 0, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : PartBudgetMoyenMobilite indicator
            "trajectory_part_budget_moyen_mobilite": {
                backgroundColor: ["rgb(184, 233, 148)", "rgb(251, 227, 214)", "rgb(255, 216, 139)"],
                borderColor: ["rgb(184, 233, 148)", "rgb(251, 227, 214)", "rgb(255, 216, 139)"],
                borderWidth: 0,
            },
            // Trajectory : EmissionsCo2 indicator
            "trajectory_emissions_co2": {
                backgroundColor: ["rgb(0, 32, 96)","rgb(33, 92, 152)","rgb(0, 176, 240)","rgb(255, 192, 0)",
                "rgb(255, 255, 0)","rgb(216, 109, 205)","rgb(242, 206, 239)"],
                borderColor: ["rgb(0, 32, 96)","rgb(33, 92, 152)","rgb(0, 176, 240)","rgb(255, 192, 0)",
                "rgb(255, 255, 0)","rgb(216, 109, 205)","rgb(242, 206, 239)"],
                borderWidth: 0,
            },
            // Trajectory : EmissionsCo2 indicator (objective -> with transparency)
            "trajectory_emissions_co2_objective_transparency": {
                backgroundColor: ["rgba(0, 32, 96, 0.4)","rgba(33, 92, 152, 0.4)","rgba(0, 176, 240, 0.4)",
                "rgba(255, 192, 0, 0.4)","rgba(255, 255, 0, 0.4)","rgba(216, 109, 205, 0.4)","rgba(242, 206, 239, 0.4)"],
                borderColor: ["rgba(0, 32, 96, 0.4)","rgba(33, 92, 152, 0.4)","rgba(0, 176, 240, 0.4)",
                "rgba(255, 192, 0, 0.4)","rgba(255, 255, 0, 0.4)","rgba(216, 109, 205, 0.4)","rgba(242, 206, 239, 0.4)"],
                borderWidth: 0,
            },
            // Trajectory : target value 2030
            "trajectory_target_value_2030": {
                backgroundColor: ["rgb(184, 233, 148)"],
                borderColor: ["rgb(184, 233, 148)"],
                borderWidth: 0,
            },
            // Trajectory : target value 2040
            "trajectory_target_value_2040": {
                backgroundColor: ["rgb(7, 153, 146)"],
                borderColor: ["rgb(7, 153, 146)"],
                borderWidth: 0,
            },
        },
    },
    gantt: {
        status: {
            Planifié: "#C2E3F4",
            "En cours de démarrage": "#ffec33",
            "En cours": "#EFB730",
            Suspendu: "#f39c12",
            Abandonné: "#F2F4EF",
            Achevé: "#75b99d",
        },
        yearEnd: 2041,
        widthYear: 58,
    },
};

module.exports = {
    api_url,
    pg_tileserv_url,
    config,
};
