import CommunesArrondissements from "./layers/CommunesArrondissements";
import Prefectures from "./layers/Prefectures";

const Perimeters = {
    idSection: "perimeters",
    treeviews: {
        title: "Mobility Indicators Content.sections.Perimeters",
        layers: {
            checked: 1,
            expand: true,
        },
    },
    layers: {
        communes_arrondissements: CommunesArrondissements,
        prefectures: Prefectures,
    },
};

export default Perimeters;
