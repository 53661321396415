import { api_url } from "../../../utils";

const DeplacementsCentroidesFluxInternes = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url:
        api_url +
        "maps/geojson/?table=deplacements_centroides_flux_internes&schema_city=",
    zindex: 3,
    type: "graduated",
    popup: {
        default: {
            content: {
                fr: {
                    int_total: "",
                },
                en: {
                    int_total: ""
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.DeplacementsCentroidesFluxInternes",
    style: {
        regular: false,
        fillColor: "red",
        strokeColor: "red",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 1,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.DeplacementsCentroidesFluxInternes.title",
            subtitle: "",
            attribute: "int_total",
            symbol: "point",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    radius: 4,
                    min: 1,
                    max: 199999,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsCentroidesFluxInternes.classes.1",
                    customCssClass: "map-legend-symbol-10px map-legend-symbol-point-border-1px-35-35-35",
                },
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    radius: 8,
                    min: 200000,
                    max: 399999,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsCentroidesFluxInternes.classes.2",
                    customCssClass: "map-legend-symbol-17px map-legend-symbol-point-border-1px-35-35-35",
                },
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    radius: 12,
                    min: 400000,
                    max: 599999,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsCentroidesFluxInternes.classes.3",
                    customCssClass: "map-legend-symbol-22px map-legend-symbol-point-border-1px-35-35-35",
                },
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    radius: 16,
                    min: 600000,
                    max: 850000,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsCentroidesFluxInternes.classes.4",
                    customCssClass: "map-legend-symbol-28px map-legend-symbol-point-border-1px-35-35-35",
                },
            ],
        },
    },
};

export default DeplacementsCentroidesFluxInternes;
