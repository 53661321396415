import { api_url } from "../../../utils";

const CommunesArrondissements = {
    enable: true,
    checked: true,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url:
        api_url +
        "maps/geojson/?table=perimeter_communes_arrondissements&schema_city=",
    zindex: 1,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_comar: "Nom de la commune ou de l'arrondissement : ",
                    pop22: "Nombre d'habitants 2022 : ",
                    tx_pop: "Taux de croissance annualisé 2014 - 2022 (%) : ",
                    nb_pers_men: "Nombre de personnes par ménage :",
                    empl22: "Nombre d'emplois 2022 : ",
                    p_act22: "Part d'actifs parmi la population 2022 : ",
                    vocation: "Vocation principale de la zone : ",
                    dens22: "Densité (habitants / km²) : ",
                },
                en: {
                    l_comar: "Name of the Municipality or District: ",
                    pop22: "Number of Inhabitants in 2022: ",
                    tx_pop: "Annualized Growth Rate 2014 - 2022 (%): ",
                    nb_pers_men: "Number of People per Household: ",
                    empl22: "Number of Jobs in 2022: ",
                    p_act22: "Percentage of Active Population in 2022: ",
                    vocation: "Primary Purpose of the Area: ",
                    dens22: "Density (Inhabitants/km²): ",
                    valuesTranslation: {
                        "Zone résidentielle (plus d'actifs que d'emplois)": "Residential Area (More Active Residents Than Jobs)",
                        "Zone mixte (autant d'emplois que d'actifs)": "Mixed-Use Area (Equal Number of Jobs and Active Residents)",
                        "Bassin d'emplois (plus d'emplois que d'actifs)": "Employment Basin (More Jobs Than Active Residents)"
                    }
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.CommunesArrondissements",
    style: {
        regular: false,
        fillColor: "rgba(0, 0, 0, 0.001)",
        strokeColor: "rgb(233, 35, 35)",
        strokeWidth: 1.5,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.CommunesArrondissements.title",
            subtitle: "",
            attribute: "",
            symbol: "rectangle",
            legendCustomCssClass: "map-legend-symbol-border-2px-red-233-35-35",
            class: [
                {
                    fillColor: "rgba(0, 0, 0, 0)",
                    strokeColor: "rgb(233, 35, 35)",
                    strokeWidth: 1,
                    label: "Mobility Indicators Content.layersLegend.CommunesArrondissements.classes.1",
                },
            ],
        },
    },
};

export default CommunesArrondissements;
