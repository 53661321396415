import { api_url } from "../../../utils";

const StationsTram = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=stations_tram&schema_city=",
    zindex: 3,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_station: "Nom station tram :",
                },
                en: {
                    l_station: "Name of Tram Station: "
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.StationsTram",
    style: {
        regular: false,
        fillColor: "rgb(72, 123, 182)",
        strokeColor: "rgb(50, 87, 128)",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 4,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.StationsTram.title",
            subtitle: "",
            attribute: "",
            symbol: "point",
            legendCustomCssClass:
                "map-legend-symbol-8px map-legend-symbol-border-1px-blue-50-87-128",
            class: [
                {
                    fillColor: "rgb(72, 123, 182)",
                    strokeColor: "rgb(50, 87, 128)",
                    strokeWidth: 0,
                    label: "Mobility Indicators Content.layersLegend.StationsTram.classes.1",
                },
            ],
        },
    },
};

export default StationsTram;
