import { Component } from "react";
import InfoIcon from "@mui/icons-material/Info";
import "../styles/PopupInformation.css";
import { withTranslation} from "react-i18next";

class PopupInformation extends Component {
    constructor(props) {
        super(props);

        this.indicator = props.indicator;
        this.text = props.text;
        let displayStatus = false;
        if (this.indicator) {
            this.popupInformation = this.indicator.popupInformation;
            this.popupInformationText = this.popupInformation.text;
            displayStatus = this.popupInformation.status;
        }
        this.state = {
            display: displayStatus,
            currentLanguage: this.props.i18n.resolvedLanguage
        };
    }

    // Reverse the display status of the information popup of the indicator when hovering on the icon
    onPopupInformationHover() {
        this.setState({
            display: "true",
        });
    }

    // Reverse the display status of the information popup of the indicator when hovering off the icon
    onPopupInformationLeave() {
        this.setState({
            display: "false",
        });
    }

    // Listen to language changes
    componentDidMount() {
        const { i18n } = this.props;
        this.unsubscribeLanguageChange = i18n.on('languageChanged', this.handleLanguageChange);
    }

    // Update the language of the information popup of the indicator when the language is changed
    handleLanguageChange = () => {
        const { i18n } = this.props;
        this.setState({ currentLanguage: i18n.resolvedLanguage });
    };

    render() {
        // Information popup about the indicator
        const renderPopupInformationText = () => {
            if (this.state.display === "true") {
                if (this.text) {
                    let html = this.props.t(this.text);
                    if(typeof this.text === 'object') {
                        html = this.text[this.state.currentLanguage].speech
                    }

                    return (
                        <div className="popup-information-container">
                            <div className="popup-information-single-text">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: html,
                                    }}
                                />
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="popup-information-container">
                            <div className="">
                                <p className="popup-information-title">
                                    { this.props.t(this.indicator.titleIndicator) }
                                </p>
                            </div>
                            <div className="popup-information-section-container">
                                <p className="popup-information-text"
                                dangerouslySetInnerHTML={{
                                     __html: this.popupInformationText[this.state.currentLanguage].speech,
                                        }}
                                >
                                </p>
                            </div>
                            {this.popupInformationText[this.state.currentLanguage].source.length > 0 && (
                                <div className="popup-information-section-container">
                                    <p className="popup-information-section">
                                        { this.props.t("Data source") }
                                    </p>
                                    <p className="popup-information-text"
                                    dangerouslySetInnerHTML={{
                                     __html: this.popupInformationText[this.state.currentLanguage].source,
                                        }}>
                                    </p>
                                </div>
                            )}
                        </div>
                    );
                }
            }
        };

        // Generic function to generate the information popup
        const renderPopupInformation = () => {
            return (
                <div>
                    <div
                        onMouseEnter={(e) => this.onPopupInformationHover(e)}
                        onMouseLeave={(e) => this.onPopupInformationLeave(e)}
                    >
                        <InfoIcon
                            sx={{ color: "#357aa4", fontSize: 27 }}
                        ></InfoIcon>
                        <div>{renderPopupInformationText()}</div>
                    </div>
                </div>
            );
        };

        const renderPopup = renderPopupInformation();

        return <div className="popup-information-icon">{renderPopup}</div>;
    }
}

export default withTranslation()(PopupInformation);
