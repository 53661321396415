import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partSumpTrajectory,
    popupInformation,
    defaultDatalabelsAlign,
    defaultDatalabelsAnchor,
    defaultDatalabelsFontWeight,
    defaultTicksCustomValue
} from "../../../utils";

const VolumeDeplacementsJournaliersMode = {
    checked: false,
    titleSideBar: "Volume de déplacements journaliers par mode - Scénario PMUD",
    titleIndicator: "SUMP Trajectory Content.charts.VolumeDeplacements.title",
    popupInformation: {
        status: "false",
        text: popupInformation[partSumpTrajectory]["volumeDeplacementsJournaliersMode"],
    },
    keyFigure: {
        typeUpdate: "geographical_area", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "line",
            typeLabels: "years", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "year", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "true",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "300",
            widthChart: "500",
            scales: {
                x: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    min: 2006,
                    max: 2040,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: true,
                    titleDisplay: true,
                    titleText: ["SUMP Trajectory Content.charts.VolumeDeplacements.axis.titleY"],
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 14000000,
                    ticks: {
                        display: true,
                        value: {
                            prefix: "",
                            suffix: ""
                        }
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROMarche à pied",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_marche&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["0"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROMarche à pied",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_marche_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["0"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROBicyclette",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_bicyclette&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["1"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["1"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROBicyclette",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_bicyclette_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["1"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["1"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROVoiture",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_voiture&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["2"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["2"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROVoiture",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_voiture_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["2"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["2"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZERODeux-roues motorisé",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_deux_roues&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["3"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["3"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZERODeux-roues motorisé",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_deux_roues_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["3"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["3"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROGrand taxi",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_grand_taxi&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["4"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["4"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROGrand taxi",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_grand_taxi_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["4"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["4"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROPetit taxi",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_petit_taxi&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["5"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["5"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROPetit taxi",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_petit_taxi_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["5"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["5"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROONCF (dans la conurbation)",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_oncf&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["6"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["6"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROONCF (dans la conurbation)",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_oncf_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["6"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["6"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROTramway",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_tramway&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["7"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["7"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROTramway",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_tramway_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["7"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["7"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROBus (Alsa)",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_bus&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["8"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["8"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROBUS (Alsa)",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_bus_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["8"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["8"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROTransport scolaire",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_transport_scolaire&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["9"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["9"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROTransport scolaire",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_transport_scolaire_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["9"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["9"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDETOOLTIPIFZEROTransport de personnel",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_transport_personnel&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].backgroundColor["10"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode"].borderColor["10"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.VolumeDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROTransport de personnel",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=volume_deplacements_journaliers_mode&trend=reality&chart=volume_deplacements_journaliers_mode_transport_personnel_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].backgroundColor["10"],
                    borderColor: configChartsColorPalettes["trajectory_volume_deplacements_journaliers_mode_objective_transparency"].borderColor["10"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 1,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: true,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
            ],
        },
    ],
};

export default VolumeDeplacementsJournaliersMode;
