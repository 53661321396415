import MotorisationMenages from "./layers/MotorisationMenages";
import DeplacementsCentroidesFluxInternes from "./layers/DeplacementsCentroidesFluxInternes";
import DeplacementsLignesDesir from "./layers/DeplacementsLignesDesir";
import PartsModales from "./layers/PartsModales";
import FrequentationTransportPublic from "./indicators/FrequentationTransportPublic";
import Motorisation from "./indicators/Motorisation";
import TauxMobiliteQuotidien from "./indicators/TauxMobiliteQuotidien";
import RepartitionModaleDeplacementsQuotidiens from "./indicators/RepartitionModaleDeplacementsQuotidiens";
import NbDeplacementsQuotidiens from "./indicators/NbDeplacementsQuotidiens";
import TraficRoutier from "./layers/TraficRoutier";
import TauxImmobilite from "./indicators/TauxImmobilite";
// import DistanceMoyenneDeplacements from "./indicators/DistanceMoyenneDeplacements";

const MobilityDemand = {
    idSection: "mobilityDemand",
    questionSection: "",
    treeviews: {
        title: "Mobility Indicators Content.sections.MobilityDemand",
        indicators: {
            checked: 0,
            expand: false,
        },
        layers: {
            checked: 2,
            expand: true,
        },
    },
    layers: {
        motorisationMenages: MotorisationMenages,
        deplacementsCentroidesFluxInternes: DeplacementsCentroidesFluxInternes,
        deplacementsLignesDesir: DeplacementsLignesDesir,
        partsModales: PartsModales,
        traficRoutier: TraficRoutier,
    },
    indicators: {
        // distanceMoyenneDeplacements: DistanceMoyenneDeplacements,
        repartitionModaleDeplacementsQuotidiens: RepartitionModaleDeplacementsQuotidiens,
        nbDeplacementsQuotidiens: NbDeplacementsQuotidiens,
        tauxMobiliteQuotidien: TauxMobiliteQuotidien,
        motorisation: Motorisation,
        frequentationTransportPublic: FrequentationTransportPublic,
        tauxImmobilite: TauxImmobilite,
    },
};

export default MobilityDemand;
