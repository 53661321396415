import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partMobilityIndicators,
    popupInformation,
    defaultDatalabelsProperties,
    defaultTicksCustomValue
} from "../../../utils";

const TauxMobiliteQuotidien = {
    checked: false,
    titleSideBar: "Mobility Indicators Content.indicators.TauxMobiliteQuotidien",
    titleIndicator: "Mobility Indicators Content.indicators.TauxMobiliteQuotidien",
    popupInformation: {
        status: "false",
        text: popupInformation[partMobilityIndicators]["tauxMobiliteQuotidien"],
    },
    keyFigure: {
        typeUpdate: "geographical_area", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "bar",
            typeLabels: "geographical_areas", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "geographical_area", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "false",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "250",
            widthChart: "600",
            i18nKeyLabels: "Mobility Indicators Content.charts.TauxMobiliteQuotidien.axis.labelsX",
            scales: {
                x: {
                    type: "category",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue,
                        minRotation: 0,
                        maxRotation: 15,
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: true,
                    titleText: "",
                    gridDisplay: true,
                    beginAtZero: true,
                    max: null,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "bar",
                    label: "",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=taux_mobilite_quotidien&trend=projection&chart=taux_mobilite_quotidien&schema_city=",
                    backgroundColor: [...configChartsColorPalettes["0"].backgroundColor, ...["rgb(0, 0, 0)"], ...configChartsColorPalettes["9"].backgroundColor,],
                    borderColor: [ ...configChartsColorPalettes["0"].borderColor, ...["rgb(0, 0, 0)"], ...configChartsColorPalettes["9"].borderColor],
                    borderWidth: 3,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 1,
                    categoryPercentage: 0.5,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
            ],
        },
    ],
};

export default TauxMobiliteQuotidien;
