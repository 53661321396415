import GeoJSON from "ol/format/GeoJSON";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Overlay from "ol/Overlay";
import Map from "ol/Map";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import Chart from "ol-ext/style/Chart"
import * as olExtent from 'ol/extent';
import { fromLonLat } from "ol/proj";
import { Vector as VectorSource, XYZ } from "ol/source";
import { Fill, Stroke, Style, RegularShape, Circle } from "ol/style";
import View from "ol/View";
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import React, { Component } from "react";
import "../styles/Map.css";
import MapLegend from "./MapLegend";
import store from "../store";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { connect } from "react-redux";
import { reverseMapFullScreen, reverseLegendDisplay } from "../stores/Map";
import { formatNumberPopup } from "../utils";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTile from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import { defaults as defaultControls } from "ol/control.js";
import Icon from "@mdi/react";
import { mdiFormatListBulletedType } from "@mdi/js";
import { withTranslation } from "react-i18next";
import { compose } from 'redux';

const { config } = require("../settings");
const configChartsColorPalettesUndefined =
    config.charts.colorPalettes["undefined"];
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class MapWrapper extends Component {
    constructor(props) {
        super(props);

        this.configMap = config.map;
        this.legendDisplay = store.getState().map.legendDisplay;
        let centerZoomCity = {};
        if(store.getState().cities.listCities[store.getState().cities.currentCity]) {
            centerZoomCity = store.getState().cities.listCities[store.getState().cities.currentCity];
        }
        else {
           centerZoomCity = {
                zoom: [0, 0],
                center: 11,
                extent: [-180, -90, 180, 90],
            }
        }
        this.state = {
            display: store.getState().map.display,
            fullScreen: store.getState().map.fullScreen,
            currentCity: store.getState().cities.currentCity,
            centerZoomCity: centerZoomCity,
            data: {
                ...store.getState().dashboardMobilityIndicators,
                ...store.getState().mapLayersMobilityIndicators,
            },
            alert: {
                open: false,
                layers: [],
            },
            currentLanguage: store.getState().general.currentLanguage
        };

        this.closeAlert = this.closeAlert.bind(this);
        store.subscribe(() => {
            this.setState({
                display: store.getState().map.display,
                fullScreen: store.getState().map.fullScreen,
                legendDisplay: store.getState().map.legendDisplay,
                currentCity: store.getState().cities.currentCity,
                centerZoomCity:
                    store.getState().cities.listCities[
                        store.getState().cities.currentCity
                    ],
                currentLanguage: store.getState().general.currentLanguage
            });
        });
    }

    // Open the alert with the list of the layers that cannot be displayed
    openAlert(layerName) {
        this.setState((prevState) => ({
            alert: {
                ...prevState.alert,
                open: true,
                layers: [...prevState.alert.layers, layerName],
            },
        }));
    }
    // Close the alert with the list of the layers that cannot be displayed
    closeAlert() {
        this.setState((prevState) => ({
            alert: {
                ...prevState.alert,
                open: false,
            },
        }));
    }

    // Load layer
    async loadLayer(layer, layerId) {
        let currentCity = this.state.currentCity;
        const layerSource = layer.source;
        let layerPopup = layer.popup;
        let layerPopupKeys = Object.keys(layerPopup);

        // Check if there is a specific configuration for this city
        if (layerPopupKeys.includes(currentCity) === true) {
            layerPopup = layerPopup[currentCity];
        } else {
            layerPopup = layerPopup["default"];
        }

        const layerPopupContent = layerPopup.content;
        const layerPopupSeparators = layerPopup.separators;

        let response = "";
        let urlFieldsProperties = "";
        let urlSource = "";
        if (layerSource === "api") {
            urlFieldsProperties =
                "&fields_properties=" + Object.keys(layerPopupContent[this.state.currentLanguage]).filter(key => key !== 'valuesTranslation').join("&fields_properties=");
            urlSource = `${layer.url}${currentCity}${urlFieldsProperties}`;
            response = await fetch(urlSource)
                .then((response) => response.json())
                // API not available
                .catch((_error) => {});
        }
        // pg_tileserv
        else {
            urlFieldsProperties =
                "?properties=" + Object.keys(layerPopupContent[this.state.currentLanguage]).join(",");
            urlSource = `${layer.url.replace(
                "REPLACECITYNAME",
                currentCity
            )}/{z}/{x}/{y}.pbf${urlFieldsProperties}`;
        }

        // API available but error in the url
        if (
            layerSource === "api" &&
            (response === undefined || !response.data)
        ) {
            this.openAlert(layer.titleSideBar);
        }
        // Add the layer to the map
        else {
            let sourceLayer = "";
            if (layerSource === "api") {
                const features = {
                    type: "FeatureCollection",
                    crs: {
                        type: "name",
                        properties: {
                            name: "EPSG:3857",
                        },
                    },
                    features: response.data,
                };
                sourceLayer = new VectorSource({
                    features: new GeoJSON().readFeatures(features),
                });
            }
            // pg_tileserv
            else {
                sourceLayer = new VectorTile({
                    format: new MVT(),
                    url: urlSource,
                });
            }

            let layerLegend = layer.legend;
            let layerLegendKeys = Object.keys(layerLegend);
            // Check if there is a specific configuration for this city
            if (layerLegendKeys.includes(currentCity) === true) {
                layerLegend = layerLegend[currentCity];
            } else {
                layerLegend = layerLegend["default"];
            }

            // If type is PIE, then we have to calculate the center of the feature and and POINT features
            let maxTotal = 0;
            if (layer.type === "pie") {
                let layerFeatures = sourceLayer.getFeatures();
                layerFeatures.forEach(function (f) {
                    // Get feature centroid
                    let featExtent = f.getGeometry().getExtent();
                    let coords = olExtent.getCenter(featExtent);
                    const clonedProperties = JSON.parse(JSON.stringify(f.getProperties()));
                    let featureCentroid = new Feature();
                    featureCentroid.setProperties(clonedProperties, true);
                    featureCentroid.setGeometry(new Point(coords));
                    sourceLayer.addFeature(featureCentroid);
                    maxTotal = f.get(layer.pieProps.total) > maxTotal ? f.get(layer.pieProps.total) : maxTotal;
                });
            }

            const styleLayer = (feature) => {

                let fillColor = "";
                let strokeColor = "";
                let strokeWidth = "";
                let strokeLineDash = "";
                let radius = "";
                const attributeClassification = layerLegend.attribute;
                const layerStyle = layer.style;
                const layerType = layer.type;
                const layerSymbol = layerLegend.symbol;
                if (feature) {
                    // Definition of the color and stroke width (the other properties are defined in the store, style key, FOR THE MOMENT)
                    if (
                        layerType === "graduated" ||
                        layerType === "categorized"
                    ) {
                        const featureValue = feature.get(
                            attributeClassification
                        );
                        // Missing attribute for graduated type
                        if (!featureValue) {
                            fillColor =
                                configChartsColorPalettesUndefined
                                    .backgroundColor[0];
                            strokeColor =
                                configChartsColorPalettesUndefined
                                    .borderColor[0];
                            strokeWidth =
                                configChartsColorPalettesUndefined
                                    .borderWidth[0];
                        } else {
                            if (layerType === "graduated") {
                                for (const row of layerLegend.class) {
                                    if (
                                        featureValue >= row.min &&
                                        featureValue <= row.max
                                    ) {
                                        fillColor = row.fillColor;
                                        strokeColor = row.strokeColor;
                                        strokeWidth = row.strokeWidth;
                                        strokeLineDash = row.strokeLineDash;
                                        if (layerSymbol === "point" || layerSymbol === "diamond") {
                                            radius = row.radius;
                                        }
                                        break;
                                    }
                                }
                            }
                            // Categorized
                            else {
                                for (const row of layerLegend.class) {
                                    if (featureValue === row.value) {
                                        fillColor = row.fillColor;
                                        strokeColor = row.strokeColor;
                                        strokeWidth = row.strokeWidth;
                                        strokeLineDash = row.strokeLineDash;
                                        if (layerSymbol === "point" || layerSymbol === "diamond") {
                                            radius = row.radius;
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        if(layerType === "pie") {
                            // Pie
                            // Proportionnal aera for the pie
                            let total = feature.get(layer.pieProps.total);
                            radius = config.charts.maxRadiusMapPie;
                            let maxArea = Math.PI * config.charts.maxRadiusMapPie * config.charts.maxRadiusMapPie;
                            let percent = (Math.abs(total)) / (maxTotal);
                            let currentArea = percent * maxArea;
                            let scaledRadius = Math.sqrt(currentArea / Math.PI);

                            var data = [];
                            layer.pieProps.data.forEach((item) => {
                                data.push(feature.get(item)*100.0/total)
                            })

                            // Create pie chart style
                            let style = [ new Style({
                                image: new Chart({
                                    type: "pie",
                                    radius: scaledRadius,
                                    data: data,
                                    rotateWithView: true,
                                    stroke: new Stroke({
                                        color: "#fff",
                                        width: 1
                                    }),
                                    colors: layer.pieProps.colors
                                }),
                                zIndex: 99999
                            })];
                            return style;
                        }
                        else {
                            fillColor = layerStyle.fillColor;
                            strokeColor = layerStyle.strokeColor;
                            strokeWidth = layerStyle.strokeWidth;
                            strokeLineDash = layerStyle.strokeLineDash;
                            radius = layerStyle.radius;
                        }
                    }
                } else {
                    fillColor = layerStyle.fillColor;
                    strokeColor = layerStyle.strokeColor;
                    strokeWidth = layerStyle.strokeWidth;
                    strokeLineDash = layerStyle.strokeLineDash;
                    radius = layerStyle.radius;
                }

                const styleStroke = new Stroke({
                    color: strokeColor,
                    width: strokeWidth,
                    lineDash: strokeLineDash,
                });
                const styleFill = new Fill({ color: fillColor });

                // point geometry
                if (layerSymbol === "point" || layerSymbol === "diamond") {
                    if (layerStyle.regular === true) {
                        return new Style({
                            image: new RegularShape({
                                fill: styleFill,
                                stroke: styleStroke,
                                points: layerStyle.points,
                                radius: radius,
                                angle: layerStyle.angle,
                                rotation: layerStyle.rotation,
                            }),
                        });
                    } else {
                        return [
                            new Style({
                                image: new Circle({
                                    fill: styleFill,
                                    stroke: styleStroke,
                                    radius: radius,
                                }),
                            }),
                        ];
                    }
                }
                // linestring geometry
                else if (layerSymbol === "line") {
                    return [
                        new Style({
                            stroke: styleStroke,
                        }),
                    ];
                }
                // layerSymbol equal to rectangle (polygon geometry)
                else {
                    //
                    if (styleFill.color_ === "rgba(0, 0, 0, 0)") {
                        return [
                            new Style({
                                stroke: styleStroke,
                            }),
                        ];
                    } else {
                        return [
                            new Style({
                                stroke: styleStroke,
                                fill: styleFill,
                            }),
                        ];
                    }
                }
            };

            let vectorLayer = "";
            if (layerSource === "api") {
                vectorLayer = new VectorLayer({
                    source: sourceLayer,
                    style: styleLayer,
                });
            }
            // pg_tileserv
            else {
                var vectorTileStyle = styleLayer();
                vectorLayer = new VectorTileLayer({
                    source: sourceLayer,
                    style: vectorTileStyle,
                });
            }

            vectorLayer.set("id", layerId);
            vectorLayer.set("popupContent", layerPopupContent);
            vectorLayer.set("popupSeparators", layerPopupSeparators);
            vectorLayer.setZIndex(layer.zindex);
            if (layer.checked === false) {
                vectorLayer.setVisible(false);
            }
            this.map.addLayer(vectorLayer);
        }
    }

    // Loading of the layers associated with the selected city
    loadLayers() {
        let currentCity = this.state.currentCity;
        if (currentCity !== "") {
            for (let [keySection, section] of Object.entries(this.state.data)) {
                for (let [keyLayer, layer] of Object.entries(section.layers)) {
                    const layerId = keySection + keyLayer;
                    // Check that the layer is enabled
                    // Check that the layer is not excluded for this city
                    if (
                        layer.enable &&
                        !layer.excludeCities.includes(currentCity)
                    ) {
                        this.loadLayer(layer, layerId);
                    }
                }
            }

            this.loadLayer(
                store.getState().map.layerCityBoundary,
                "cityBoundary"
            );
        }
    }

    // Zoom and center the map on the current city
    updateMapView() {
        this.map.setView(
            new View({
                center: fromLonLat(this.state.centerZoomCity.center),
                zoom: this.state.centerZoomCity.zoom,
                constrainResolution: true,
                extent: this.state.centerZoomCity.extent,
            })
        );
    }

    componentDidMount() {
        this.map = new Map({
            target: "map-container",
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: "https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
                        attributions:
                            '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors © <a href="https://carto.com/about-carto/">CARTO</a>',
                    }),
                }),
            ],
            controls: defaultControls({
                attributionOptions: { collapsible: true },
            }),
            view: new View({
                center: fromLonLat(this.state.centerZoomCity.center),
                zoom: this.state.centerZoomCity.zoom,
                constrainResolution: true,
                extent: this.state.centerZoomCity.extent,
            }),
        });

        // Avoids a blank map when there is a city in the store
        // (e.g. when you switch from the sump trajectory part to mobility indicators)
        if (this.state.currentCity !== "") {
            this.updateMapView();
        }

        // Loading of the layers
        this.loadLayers();

        // Popup overlay
        const container = document.getElementById("popup");
        const content = document.getElementById("popup-content");
        this.popupOverlay = new Overlay({
            element: container,
            autoPan: false,
            // autoPan: {
            //     animation: {
            //         duration: 250,
            //     },
            // },
        });
        this.map.addOverlay(this.popupOverlay);

        this.map.on("pointermove", (event) => {
            let val = undefined;
            let listPopup = [];
            let contentPopup = [];
            this.map.forEachFeatureAtPixel(
                event.pixel,
                (feature, _layer) => {
                    // Retrieval and creation of the popup content
                    let layerPopupContent = _layer.get("popupContent")[this.state.currentLanguage];
                    let layerPopupSeparators = _layer.get("popupSeparators");
                    let layerZIndex = _layer.get("zIndex");
                    let contentPopupPixel = [];
                    for (let [field, label] of Object.entries(
                        layerPopupContent
                    )) {
                        val = feature.get(field);
                        if (val && label !== "") {
                            val = formatNumberPopup(
                                val,
                                field,
                                layerPopupSeparators
                            );
                            if ("valuesTranslation" in layerPopupContent
                            && Object.keys(layerPopupContent["valuesTranslation"]).includes(val)
                        ) {
                                val = layerPopupContent["valuesTranslation"][val];
                            }
                            contentPopupPixel.push(`<div class="popup-row">
                    <div class="popup-label">${label}</div>
                    <div class="popup-value">${val}</div>
                </div>`);
                        }
                    }

                    if (contentPopupPixel.length > 0) {
                        contentPopup.push(contentPopupPixel);
                        listPopup.push({
                            zIndex: layerZIndex,
                            popup: contentPopupPixel,
                        });
                    }
                },
                {
                    hitTolerance: 5,
                }
            );

            // Display the popup of the layer with the highest zIndex
            let sortListPopup = listPopup.sort((a, b) => b.zIndex - a.zIndex);
            if (contentPopup.length > 0) {
                content.innerHTML = sortListPopup[0].popup.join("");
                this.popupOverlay.setPosition(event.coordinate);
            } else {
                this.popupOverlay.setPosition(undefined);
            }
        });
    }

    // Update the visibility of the layers
    componentDidUpdate(_prevProps, prevState) {
        if (this.state.display === true) {
            this.map.updateSize();
        }

        if (prevState.currentCity !== store.getState().cities.currentCity) {
            // Loading of the layers of the new selected city
            this.loadLayers();

            // Zoom and center on the new city
            this.updateMapView();
        }

        for (let [keySection, section] of Object.entries(this.state.data)) {
            for (let [keyLayer, _layer] of Object.entries(section.layers)) {
                let layerChecked = "";
                try {
                    layerChecked =
                        store.getState().dashboardMobilityIndicators[keySection]
                            .layers[keyLayer].checked;
                } catch (error) {
                    // section does not exist in the dashboardMobilityIndicators store
                    if (error instanceof TypeError) {
                        layerChecked =
                            store.getState().mapLayersMobilityIndicators[
                                keySection
                            ].layers[keyLayer].checked;
                    }
                }

                if (
                    prevState.data[keySection].layers[keyLayer].checked !==
                    layerChecked
                ) {
                    const layerId = keySection + keyLayer;
                    this.map.getLayers().forEach(function (layer) {
                        if (layer.get("id") === layerId) {
                            if (layerChecked) {
                                layer.setVisible(true);
                            } else {
                                layer.setVisible(false);
                            }
                        }
                    });

                    this.setState({
                        data: {
                            ...store.getState().dashboardMobilityIndicators,
                            ...store.getState().mapLayersMobilityIndicators,
                        },
                    });
                }
            }
        }
    }

    render() {
        // Classes related to the map container
        let classNameMap =
            this.state.display === true
                ? "map-container"
                : "map-container-hidden";
        classNameMap =
            this.state.fullScreen === true
                ? classNameMap + " map-container-full-screen"
                : classNameMap;

        // Classes related to the map question contaienr
        let classNameMapQuestion = "map-question-container";
        classNameMapQuestion =
            this.state.fullScreen === true
                ? classNameMapQuestion + " map-question-container-full-screen"
                : classNameMapQuestion;

        // If one theme is display, display the associated question
        let arrayCheckedStatusTreeviewsIndicators = [];
        for (let section of Object.values(
            store.getState().dashboardMobilityIndicators
        )) {
            if (section.treeviews.indicators.checked !== 0) {
                arrayCheckedStatusTreeviewsIndicators.push(
                    section.questionSection
                );
            }
        }
        const textQuestionSection =
            arrayCheckedStatusTreeviewsIndicators.length === 1
                ? arrayCheckedStatusTreeviewsIndicators[0].split("?")
                : [];
        // Replacement of strings in questions
        for (var i = 0; i < textQuestionSection.length; i++) {
            textQuestionSection[i] = textQuestionSection[i].replace(
                "REPLACECITYNAME",
                this.state.currentCity.charAt(0).toUpperCase() +
                    this.state.currentCity.slice(1)
            );
        }
        const visibilitytextQuestionSection =
            this.state.display === true ? "visible" : "hidden";

        // Full screen mode
        const iconFullScreen =
            this.state.fullScreen === false ? (
                <IconButton
                    title="Full screen"
                    onClick={this.props.handleReverseFullScreenMode}
                >
                    <FullscreenIcon
                        className="map-icon-screen-legend-display "
                        fontSize="medium"
                    ></FullscreenIcon>
                </IconButton>
            ) : (
                <IconButton
                    title="Exit full screen mode"
                    onClick={this.props.handleReverseFullScreenMode}
                >
                    <FullscreenExitIcon
                        className="map-icon-screen-legend-display "
                        fontSize="medium"
                    ></FullscreenExitIcon>
                </IconButton>
            );

        // Legend display mode
        const iconLegendDisplay =
            this.state.legendDisplay === true ? (
                <IconButton
                    title="Hide legend"
                    onClick={this.props.handleReverseLegendDisplayMode}
                >
                    <Icon
                        className="map-icon-screen-legend-display "
                        path={mdiFormatListBulletedType}
                        size={0.9}
                    />
                </IconButton>
            ) : (
                <IconButton
                    title="Show legend"
                    onClick={this.props.handleReverseLegendDisplayMode}
                >
                    <Icon
                        className="map-icon-screen-legend-display "
                        path={mdiFormatListBulletedType}
                        size={0.9}
                    />
                </IconButton>
            );

        return (
            <div
                id="map-container"
                className={classNameMap}
                style={{ visibility: visibilitytextQuestionSection }}
            >
                {/* Display full screen icon */}
                <div className="container-map-icon-screen map-icon">
                    {iconFullScreen}
                </div>
                {/* Display legend display icon */}
                <div className="container-map-icon-legend-display map-icon">
                    {iconLegendDisplay}
                </div>
                {/* Display an error with the list of the layers that cannot be displayed (API error)*/}
                <div>
                    <Stack spacing={2} sx={{ width: "100%" }}>
                        <Snackbar
                            open={this.state.alert.open}
                            autoHideDuration={5000}
                            onClose={this.closeAlert}
                        >
                            <Alert
                                onClose={this.closeAlert}
                                severity="error"
                                sx={{ width: "100%" }}
                            >
          {this.props.t("Unable to Load Layers") +
          this.state.alert.layers.map(layer => this.props.t(layer)).join(", ")}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </div>
                {/* Display the question(s) associated with a section */}
                <div className={classNameMapQuestion}>
                    {arrayCheckedStatusTreeviewsIndicators.length === 1
                        ? textQuestionSection.map((question, i) => (
                              <p key={i} className="map-question">
                                  {question.length > 0 ? question + "?" : ""}
                              </p>
                          ))
                        : ""}
                </div>
                {/* Display the legend(s) */}
                <MapLegend />

                <div id="popup" className="ol-popup">
                    <div id="popup-content"></div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // Reverse the full screen mode
        handleReverseFullScreenMode: () => {
            dispatch(reverseMapFullScreen());
        },
        // Reverse the legend display mode
        handleReverseLegendDisplayMode: () => {
            dispatch(reverseLegendDisplay());
        },
    };
};

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation()
  )(MapWrapper);
