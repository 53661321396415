import RevenuMenages from "./layers/RevenuMenages";
import BudgetMenageMobilite from "./indicators/BudgetMenageMobilite";
// import TempsMoyenTransport from "./indicators/TempsMoyenTransport";

const Economy = {
    idSection: "economy",
    questionSection: "",
    treeviews: {
        title: "Mobility Indicators Content.sections.Economy",
        indicators: {
            checked: 0,
            expand: false,
        },
        layers: {
            checked: 2,
            expand: true,
        },
    },
    layers: {
        revenuMenages: RevenuMenages,
    },
    indicators: {
        budgetMenageMobilite: BudgetMenageMobilite,
        // tempsMoyenTransport: TempsMoyenTransport
    },
};

export default Economy;
