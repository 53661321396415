import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import store from "../store";
import "../styles/References.css";
import Button from "@mui/material/Button";
import download from 'downloadjs';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


export default function References() {
  const { t, i18n } = useTranslation();
  const [bibliographyData, setBibliographyData] = useState([]);
  const [urlApi] = useState([store.getState().references.url]);
  const [displayAlert, setDisplayAlert] = useState(
    false
);

    // Retrieve data to generate the bibliography
    useEffect(() => {
      // Update data on bibliography
      let getData = async () => {
          if (bibliographyData.length === 0) {
            let response = await fetch(urlApi);

            // Valid api call
            if (response.status === 200) {
                const data = await response.json();

                const dataFiles = data.files;
                setBibliographyData(dataFiles);
            }
          }
      };
      getData();
  }, [bibliographyData,
      urlApi,
  ]);

    // Error during the download of the bibliography
    useEffect(() => {
      // Alert is displayed for 7.5 seconds
      setTimeout(() => {
          setDisplayAlert(false);
      }, 7500);
    }, [displayAlert]);

  // Download file
    const downloadFile = async (file) => {
      try {
        const searchQuery = encodeURIComponent(file);
        let response = await fetch(urlApi + `/${searchQuery}`);

        // Valid api call
        if (response.status === 200) {
          setDisplayAlert(false);
          const data = await response;
          download(data.url);
        } else {
          setDisplayAlert(true);
        }

    } catch (erreur) {
      setDisplayAlert(true);
    }
  };


  let referencesData = store.getState().references[i18n.resolvedLanguage];

  return (
    <div className="intro-text">
        <Box
            display = 'flex'
            justifyContent='center'
            p={1}
            m= {1}
            bgcolor="white"
        >
          <Box
            display="flex"
            width={'75%'}
            p={1}
          >
            <div className="home-speech" >
              {/* Error during the download of the file bibliography */}
              {displayAlert &&
              <Alert severity="error">
                  <AlertTitle>{t("Download File Error")}</AlertTitle>
              </Alert>}


              <div dangerouslySetInnerHTML={{ __html: referencesData.title }}></div>
              <div dangerouslySetInnerHTML={{ __html: referencesData.content_production }}></div>
              <div dangerouslySetInnerHTML={{ __html: referencesData.technical_development }}></div>
              {bibliographyData.length > 0 &&
              <div>
                <div dangerouslySetInnerHTML={{ __html: referencesData.bibliography }}></div>
                {bibliographyData.map((file, index) => (
                  <li className="bibliography-list" key={index}>
                    {'- '}{file}{' : '}
                    <Button
                          key={index}
                          onClick={() => downloadFile(file)}
                          sx={{ display: "block" }}
                      >
                      {t("Download File")}
                    </Button>

                  </li>
                ))}
              </div>
              }
              <div dangerouslySetInnerHTML={{ __html: referencesData.more_informations }}></div>
            </div>
          </Box>
        </Box>
    </div>

  );
}
