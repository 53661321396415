import { Component } from "react";
import "../../styles/Dashboard.css";
import store from "../../store";
import Charts from "../Charts";
import PopupInformation from "../PopupInformation";
import { withTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class DashboardIndicator extends Component {
    constructor(props) {
        super(props);

        this.id = props.id;
        this.part = props.part;

        this.state = {
            data: store.getState().dashboardSumpTrajectory[this.id]
                .indicators,
        };
        this.titleSection =
        store.getState().dashboardSumpTrajectory[
            this.id
        ].titleSection;

        this.listIndicators = [];
        for (let key of Object.keys(this.state.data)) {
            this.listIndicators.push(key);
        }
    }

    render() {
        let statusIndicator = [];
        for (let ind of this.listIndicators) {
            statusIndicator[ind] = this.state.data[ind];
        }

        // Generic function to generate charts associated to a indicator
        const renderIndicatorCharts = (ind, _width = 200, _height = 180) => {
            return (
                <div className="dashboard-chart-container">
                    <div className="dashboard-chart-title-container dashboard-chart-title-mobility-indicators">
                        <p className="dashboard-chart-title">
                            {this.props.t(ind.titleIndicator)}
                        </p>
                        <div>
                            <PopupInformation indicator={ind} />
                        </div>
                    </div>
                    <Charts data={ind} part={this.part} />
                    {/* Empty div to separe the last chart of a section from the title of the next section */}
                    <div style={{ height: "30px" }}></div>
                </div>
            );
        };

        const listCharts = [];
        for (let key of Object.keys(this.state.data)) {
            listCharts.push(
                <div key={key}>
                    {renderIndicatorCharts(statusIndicator[key])}
                </div>
            );
        }

        return (
            <div className="dashboard-content">
                <Accordion
                    defaultExpanded
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className="dashboard-sump-trajectory-challenge-title"
                    >
                        {this.props.t(this.titleSection)}
                    </AccordionSummary>
                    <AccordionDetails
                        className="dashboard-charts-container"
                    >
                        {listCharts}
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

export default withTranslation()(DashboardIndicator);
