import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partMobilityIndicators,
    popupInformation,
    defaultBarPercentage,
    defaultCategoryPercentage,
    defaultDatalabelsProperties,
    defaultTicksCustomValue
} from "../../../utils";

const NbParcsRelais = {
    checked: false,
    titleSideBar: "Mobility Indicators Content.indicators.NbParcsRelais",
    titleIndicator: "Mobility Indicators Content.indicators.NbParcsRelais",
    popupInformation: {
        status: "false",
        text: popupInformation[partMobilityIndicators]["nbParcsRelais"],
    },
    keyFigure: {
        typeUpdate: "year", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "picto",
            typeLabels: "geographical_areas", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "geographical_area", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "false",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "250",
            widthChart: "600",
            scales: {
                x: {
                    type: "category",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: true,
                    titleText: "",
                    gridDisplay: true,
                    beginAtZero: true,
                    max: null,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "year", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "picto-simple",
                    label: "Mobility Indicators Content.charts.NbParcsRelais.legend.REPLACEPICTOKEYFIGURE parcs",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=nb_parcs_relais&trend=projection&chart=nb_parcs_relais&schema_city=",
                    backgroundColor: configChartsColorPalettes["0"].backgroundColor,
                    borderColor: configChartsColorPalettes["0"].borderColor,
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: defaultCategoryPercentage,
                    datalabelsProperties: defaultDatalabelsProperties,
                    pictoProperties: {
                        cssClassIcon: "dashboard-picto-p_r-svg dashboard-picto-simple-svg-128px",
                    },
                },
            ],
        },
    ],
};

export default NbParcsRelais;
