import EvolutionPartModaleDeplacementsQuotidiens from "./indicators/EvolutionPartModaleDeplacementsQuotidiens";
import EvolutionDemographieDeplacements from "./indicators/EvolutionDemographieDeplacements";
import EvolutionTauxMobilite from "./indicators/EvolutionTauxMobilite";
import EvolutionTauxMotorisation from "./indicators/EvolutionTauxMotorisation";
import VolumeDeplacementsJournaliersMode from "./indicators/VolumeDeplacementsJournaliersMode";

const ChallengeOne = {
    idSection: "challengeOne",
    titleSection: "SUMP Trajectory Content.challenges.challenge1",
    indicators: {
        volumeDeplacementsJournaliersMode: VolumeDeplacementsJournaliersMode,
        evolutionPartModaleDeplacementsQuotidiens: EvolutionPartModaleDeplacementsQuotidiens,
        evolutionDemographieDeplacements: EvolutionDemographieDeplacements,
        evolutionTauxMobilite: EvolutionTauxMobilite,
        evolutionTauxMotorisation: EvolutionTauxMotorisation
    },
};

export default ChallengeOne;
