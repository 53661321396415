export const { api_url, pg_tileserv_url, config } = require("../settings");
export const { popupInformation } = require("../settings-popup-information");
export const partMobilityIndicators = "MobilityIndicators";
export const partSumpTrajectory = "SumpTrajectory";
export const configChartsColorPalettes = config.charts.colorPalettes;
// ticks properties
export const defaultTicksCustomValue = {
    prefix: "",
    suffix: "",
}
export const yRight = {
    type: "linear",
    display: false,
    stacked: false,
    titleDisplay: false,
    titleText: "",
    titleColor: configChartsColorPalettes["0"].borderColor["0"],
    gridDisplay: false,
    beginAtZero: true,
    min: null,
    max: null,
    ticks: {
        display: true,
        value: defaultTicksCustomValue
    }
};
export const legendLabelsBoxHeightLine = 3; // Line, Area
export const legendLabelsBoxHeightOther = 12; // Bar, Pie
export const defaultBarPercentage = 1;
export const defaultCategoryPercentage = 0.5;

// datalabels properties
export const defaultDatalabelsDisplay = false;
export const defaultDatalabelsAnchor = "end";
export const defaultDatalabelsAlign = "end";
export const defaultDatalabelsFontWeight = "normal";
export const defaultDatalabelsValuePrefix = "";
export const defaultDatalabelsValueSuffix = "";
export const defaultDatalabelsProperties = {
    display: defaultDatalabelsDisplay,
    anchor: defaultDatalabelsAnchor,
    align: defaultDatalabelsAlign,
    font: {
        weight: defaultDatalabelsFontWeight
    },
    value: {
        prefix: "",
        suffix: "",
    }
}

// Missing attribute on graduated / categorized type
export const undefinedDefaultBackgroundColor =
    configChartsColorPalettes["undefined"].backgroundColor;
export const undefinedDefaultStrokeColor =
    configChartsColorPalettes["undefined"].borderColor;
export const undefinedDefaultStrokeWidth =
    configChartsColorPalettes["undefined"].borderWidth;
