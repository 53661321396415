import EmissionsCo2 from "./indicators/EmissionsCo2";
import EmissionsGes from "./indicators/EmissionsGes";
import NbAccidents from "./indicators/NbAccidents";

const Externality = {
    idSection: "externality",
    questionSection: "",
    treeviews: {
        title: "Mobility Indicators Content.sections.Externality",
        indicators: {
            checked: 0,
            expand: false,
        },
        layers: {
            checked: 2,
            expand: true,
        },
    },
    layers: {},
    indicators: {
        emissionsGes: EmissionsGes,
        emissionsCo2: EmissionsCo2,
        nbAccidents: NbAccidents,
    },
};

export default Externality;
