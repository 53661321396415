import { createSlice } from "@reduxjs/toolkit";
const { config } = require("../settings");
const configDisplayTabs = config.displayTabs;

export const routes = createSlice({
    name: "routes",
    initialState: configDisplayTabs,
});

export default routes.reducer;
