import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import TreeItem from "@mui/lab/TreeItem";
import { treeItemClasses } from "@mui/lab/TreeItem";
import { alpha, styled } from "@mui/material/styles";
import { useSpring, animated } from "@react-spring/web";
import Collapse from "@mui/material/Collapse";
import store from "./store";
import { reverseMapDisplay, resetMapFullScreen } from "./stores/Map";

// Formatting of numbers in the charts
export const formatNumberChart = (data, separator) => {
    const storeGeneralLanguage = store.getState().general.currentLanguage;
    const locale = `${storeGeneralLanguage}-${storeGeneralLanguage.toUpperCase()}`
    if (separator === "") {
        return data;
    } else if (separator === "decimal") {
        return data.replace(",", ".");
    }
    // Thousand separator
    else {
        return Intl.NumberFormat(locale, {
            maximumSignificantDigits: 20,
        }).format(parseFloat(data));
    }
};

// Formatting of numbers in the map popup
export const formatNumberPopup = (data, field, separators) => {
    const storeGeneralLanguage = store.getState().general.currentLanguage;
    const locale = `${storeGeneralLanguage}-${storeGeneralLanguage.toUpperCase()}`
    data = data.toString();
    if (separators.decimal.includes(field)) {
        return data.replace(",", ".");
    } else if (separators.thousand.includes(field)) {
        return Intl.NumberFormat(locale, {
            maximumSignificantDigits: 20,
        }).format(data);
    }
    return data;
};

// Treeview utility functions
export function MinusSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

export function PlusSquare(props) {
    return (
        <SvgIcon
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

export function CloseSquare(props) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}


function TransitionComponent(props) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const CustomTreeItem = React.forwardRef((props, ref) => (
    <TreeItem
        {...props}
        TransitionComponent={TransitionComponent}
        ref={ref}
    />
));

export const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

// Counting checked layers and adjusting the map display
export const countLayersCheckedDisplayMap = (dispatch) => {
    const storeMapDisplay = store.getState().map.display;
    let arrayCheckedStatusTreeviewsLayers = [];
    // dashboarsMobilityIndicators store
    // checks treeview layers only for checked indicator sections
    for (let section of Object.values(
        store.getState().dashboardMobilityIndicators
    )) {
        // At least one indicator of the theme is checked
        if (section.treeviews.indicators.checked !== 0) {
            arrayCheckedStatusTreeviewsLayers.push(
                section.treeviews.layers.checked
            );
        }
    }
    // mapLayersMobilityIndicators store
    for (let section of Object.values(
        store.getState().mapLayersMobilityIndicators
    )) {
        arrayCheckedStatusTreeviewsLayers.push(
            section.treeviews.layers.checked
        );
    }

    // Display of the map according to its status
    // No layer is checked
    if (
        arrayCheckedStatusTreeviewsLayers.every(
            (statusChecked) => statusChecked === 0
        ) === true
    ) {
        if (storeMapDisplay === true) {
            dispatch(reverseMapDisplay());
            dispatch(resetMapFullScreen());
        }
    }
    // At least one layer is checked
    else {
        if (storeMapDisplay === false) {
            dispatch(reverseMapDisplay());
        }
    }
};
