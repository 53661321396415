import "../../styles/Gantt.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import store from "../../store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export default function GanttMyc({ tasks, startYear, endYear }) {
    let htmlTasks = [];
    const [widthYear] = useState(
        store.getState().ganttSumpManagement.widthYear
    );
    let widthYearHeader = widthYear - 1; // 1 for the border
    const { t } = useTranslation();

    // Years
    let htmlYears = [];
    for (let year = startYear; year < endYear; year++) {
        htmlYears.push(
            <div
                className="gantt-header-cell-year"
                style={{ width: widthYearHeader, minWidth: widthYearHeader }}
                key={year}
            >
                {year}
            </div>
        );
    }
    htmlTasks.push(
        <div className="gantt-task-year gantt-header-year">{htmlYears}</div>
    );

    // Tasks
    tasks.forEach(function (task) {
        let htmlTask = "";
        if (task.isAction === "True") {
            htmlTask = (
                <div
                    className="gantt-action"
                    style={{ background: task.styles.backgroundColor }}
                >
                    {t(`SUMP Management Content.tasks.${task.name}`)}
                </div>
            );
        } else {
            let dateStart = new Date(task.start);
            let taskDateStartYear = dateStart.getFullYear();
            let dateEnd = new Date(task.end);
            let taskDateEndYear = dateEnd.getFullYear();
            let nbYears = taskDateEndYear - taskDateStartYear;
            let htmlYears = [];
            let styleContainer = {
                marginLeft: (taskDateStartYear - startYear) * widthYear,
                width: (nbYears + 1) * widthYear,
                minWidth: (nbYears + 1) * widthYear,
            };
            let styleContainerProgressReal = {
                width: (((nbYears + 1) * task.progressReal) / 100) * widthYear,
                minWidth:
                    (((nbYears + 1) * task.progressReal) / 100) * widthYear,
                background: task.styles.progressRealColor,
            };
            let styleBeforeProgress = {
                backgroundImage:
                    "linear-gradient(45deg, " +
                    task.styles.progressColor +
                    " 5%, #fff 5%, #fff 45%, " +
                    task.styles.progressColor +
                    " 45%, " +
                    task.styles.progressColor +
                    " 55%, #fff 55%, #fff 95%, " +
                    task.styles.progressColor +
                    " 95%)",
                width: (((nbYears + 1) * task.progress) / 100) * widthYear,
                minWidth: (((nbYears + 1) * task.progress) / 100) * widthYear,
            };
            let styleAfterProgress = {
                backgroundImage:
                    "radial-gradient(circle, " +
                    task.styles.progressColor +
                    " 20%, transparent 10%), radial-gradient(circle, " +
                    task.styles.progressColor +
                    " 20%, transparent 10%)",
                width:
                    (nbYears + 1 - ((nbYears + 1) * task.progress) / 100) *
                    widthYear,
                minWidth:
                    (nbYears + 1 - ((nbYears + 1) * task.progress) / 100) *
                    widthYear,
            };
            let toolTip = t(`SUMP Management Content.tasks.${task.name}`) + " ( " + task.progressReal + "% )";
            htmlYears.push(
                <LightTooltip title={toolTip} key={task.id} arrow>
                    <div
                        className="gantt-task-container"
                        style={styleContainer}
                    >
                        <div
                            className="gantt-task-before-progress"
                            style={styleBeforeProgress}
                        ></div>
                        <div
                            className="gantt-task-after-progress"
                            style={styleAfterProgress}
                        ></div>
                        <div
                            className="gantt-task-progress-real"
                            style={styleContainerProgressReal}
                        ></div>
                    </div>
                </LightTooltip>
            );
            htmlTask = (
                <div className="gantt-task">
                    <span className="gantt-task-title">{t(`SUMP Management Content.tasks.${task.name}`)}</span>{" "}
                    {htmlYears}
                </div>
            );
        }
        htmlTasks.push(htmlTask);
    });

    return (
        <>
            {htmlTasks.length > 0
                ? htmlTasks.map((task, index) => <div key={index}>{task}</div>)
                : ""}
        </>
    );
}
