import { Component } from "react";
import "../../styles/Dashboard.css";
import store from "../../store";
import Charts from "../Charts";
import PopupInformation from "../PopupInformation";
import { withTranslation} from "react-i18next";

class DashboardIndicator extends Component {
    constructor(props) {
        super(props);

        this.id = props.id;
        this.part = props.part;

        this.state = {
            data: store.getState().dashboardMobilityIndicators[this.id]
                .indicators,
            titleDashBoard: store.getState().dashboardMobilityIndicators[
                this.id
            ].treeviews.title
        };

        this.listIndicators = [];
        for (let key of Object.keys(this.state.data)) {
            this.listIndicators.push(key);
        }

        store.subscribe(() => {
            this.setState({
                data: store.getState().dashboardMobilityIndicators[this.id]
                    .indicators,
                titleDashBoard: store.getState().dashboardMobilityIndicators[
                        this.id
                    ].treeviews.title
            });
        });
    }

    render() {
        let statusIndicator = [];
        let statusIndicatorsChecked = [];
        for (let ind of this.listIndicators) {
            statusIndicator[ind] = this.state.data[ind];
            statusIndicatorsChecked[ind] = statusIndicator[ind].checked;
        }

        const displayBlocks = (arr) => {
            let display = false;
            for (var key in arr) {
                if (arr[key]) {
                    return display === true;
                }
            }
        };

        // Generic function to generate charts associated to a indicator
        const renderIndicatorCharts = (ind, _width = 200, _height = 180) => {
            if (ind.checked) {
                return (
                    <div className="dashboard-chart-container">
                        <div className="dashboard-chart-title-container dashboard-chart-title-mobility-indicators">
                            <p className="dashboard-chart-title">
                                {this.props.t(ind.titleIndicator)}
                            </p>
                            <div>
                                <PopupInformation indicator={ind} />
                            </div>
                        </div>
                        <Charts data={ind} part={this.part} />
                        {/* Empty div to separe the last chart of a section from the title of the next section */}
                        <div style={{ height: "30px" }}></div>
                    </div>
                );
            }
        };

        const listCharts = [];
        for (let key of Object.keys(this.state.data)) {
            listCharts.push(
                <div key={key}>
                    {renderIndicatorCharts(statusIndicator[key])}
                </div>
            );
        }

        let displayBlock = displayBlocks(statusIndicatorsChecked);

        return (
            <div className="dashboard-content">
                {displayBlock === false && (
                    <p className="dashboard-section-title">
                        {this.props.t(this.state.titleDashBoard)}
                    </p>
                )}
                {displayBlock === false && (
                    <div className="dashboard-charts-container">
                        {listCharts}
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(DashboardIndicator);
