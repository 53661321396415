import { createSlice } from "@reduxjs/toolkit";
import Perimeters from "./part-mobility-indicators/sections/Perimeters";

export const mapLayersMobilityIndicators = createSlice({
    name: "mapLayersMobilityIndicators",
    initialState: {
        perimeters: Perimeters,
    },
    reducers: {
        // Reverse the checked status of the layer checkbox
        reverseCheckedStatusLayer: (state, props) => {
            const idSection = props.payload.idSection;
            const idLayer = props.payload.idLayer;
            state[idSection].layers[idLayer].checked =
                !state[idSection].layers[idLayer].checked;
        },
        // Update the checked status of the treeview checkbox: unchecked, indeterminate, checked
        updateCheckedStatusTreeview: (state, props) => {
            const idSection = props.payload.idSection;
            const idCheckedStatus = props.payload.idCheckedStatus;
            const typeTreeView = props.payload.typeTreeView;
            state[idSection].treeviews[typeTreeView].checked = idCheckedStatus;
        },
        // Update the expand status of the treeview checkbox
        updateExpandStatusTreeviewLayers: (state, props) => {
            const idSection = props.payload.idSection;
            const idExpandStatus = props.payload.idExpandStatus;
            const typeTreeView = props.payload.typeTreeView;
            state[idSection].treeviews[typeTreeView].expand = idExpandStatus;
        },
    },
});

export const {
    reverseCheckedStatusLayer,
    updateCheckedStatusTreeview,
    updateExpandStatusTreeviewLayers,
} = mapLayersMobilityIndicators.actions;

export default mapLayersMobilityIndicators.reducer;
