import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MapIcon from "@mui/icons-material/Map";
import BarChartIcon from "@mui/icons-material/BarChart";
import "../../styles/SideBar.css";
import TreeViewCheckboxMobilityIndicators from "./TreeViewCheckboxMobilityIndicators";
import TreeViewCheckboxMapLayersMobilityIndicators from "./TreeViewCheckboxMapLayersMobilityIndicators";
import store from "../../store";
import { useDispatch } from "react-redux";
import { reverseMapDisplay } from "../../stores/Map";
import { updateCurrentYear } from "../../stores/Years";
import { updateCurrentGeographicalArea } from "../../stores/GeographicalAreas";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const drawerWidth = 280;

export default function SideBarMobilityIndicators() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleClickMap = () => {
        dispatch(reverseMapDisplay());
    };

    let storeMobilityIndicators = useSelector(
        (_state) => store.getState().dashboardMobilityIndicators
    );
    let storeMapLayersMobilityIndicators = useSelector(
        (_state) => store.getState().mapLayersMobilityIndicators
    );
    let storeMapDisplay = useSelector((_state) => store.getState().map.display);

    // Hide view the map if no layer is checked
    let arrayCheckedStatusTreeviewsLayers = [];
    for (let section of Object.values(
        store.getState().dashboardMobilityIndicators
    )) {
        // At least one indicator of the theme is checked
        if (section.treeviews.indicators.checked !== 0) {
            arrayCheckedStatusTreeviewsLayers.push(
                section.treeviews.layers.checked
            );
        }
    }
    // mapLayersMobilityIndicators store
    for (let section of Object.values(
        store.getState().mapLayersMobilityIndicators
    )) {
        arrayCheckedStatusTreeviewsLayers.push(
            section.treeviews.layers.checked
        );
    }

    const listYears = store.getState().years.listYears;
    const [currentYear, setCurrentYear] = useState(
        store.getState().years.currentYear
    );
    const listGeographicalAreas =
        store.getState().geographicalAreas.listGeographicalAreas;
    const [currentGeographicalArea, setCurrentGeographicalArea] = useState(
        store.getState().geographicalAreas.currentGeographicalArea
    );

    // Update of the current year in the store and in the Select
    const handleYearChange = (event) => {
        let year = event.target.value;
        setCurrentYear(year);
        dispatch(updateCurrentYear(year));
    };

    // Update of the current geographical area in the store and in the Select
    const handleGeographicalAreaChange = (event) => {
        let geographicalArea = event.target.value;
        setCurrentGeographicalArea(geographicalArea);
        dispatch(updateCurrentGeographicalArea(geographicalArea));
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Drawer
                className="SideBar"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        height: "calc(100% - 68px)",
                        top: "68px",
                        overflowX: "hidden",
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <p className="sidebar-title-section">
                    <BarChartIcon></BarChartIcon>
                    {t("Themes")}
                </p>
                {/* Indicators Section: One treeview for each theme */}
                {[
                    ...new Map(
                        Object.entries(storeMobilityIndicators)
                    ).entries(),
                ].map(([key, value]) => {
                    return (
                        <TreeViewCheckboxMobilityIndicators
                            key={key}
                            data={value}
                            type="indicators"
                        />
                    );
                })}

                <Box id="box-select-year-area-indicator">
                    <p id="label-select-year-area-indicator">{t("Years")}</p>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            id="sidebar-select-year-area-indicator"
                            defaultValue={currentYear}
                            value={currentYear}
                            onChange={handleYearChange}
                        >
                            {listYears.map((year, i) => {
                                return (
                                    <MenuItem value={year} key={i}>
                                        {year}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box id="box-select-year-area-indicator">
                    <p id="label-select-year-area-indicator">
                        {t("GeographicalAreas")}
                    </p>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            id="sidebar-select-year-area-indicator"
                            defaultValue={currentGeographicalArea}
                            value={currentGeographicalArea}
                            onChange={handleGeographicalAreaChange}
                        >
                            {listGeographicalAreas.map(
                                (geographicalArea, i) => {
                                    return (
                                        <MenuItem
                                            value={geographicalArea}
                                            key={i}
                                        >
                                            {geographicalArea}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Divider className="sidebar-divider" />
                <p className="sidebar-title-section">
                    <MapIcon></MapIcon>
                    {t("mapLayers")}
                </p>
                {/*  Hide view the map if no layer is checked */}
                {arrayCheckedStatusTreeviewsLayers.every(
                    (statusChecked) => statusChecked === 0
                ) === false ? (
                    <div>
                        <FormControlLabel
                            className="sidebar-checkbox"
                            checked={storeMapDisplay === true ? true : false}
                            control={<Checkbox />}
                            label={t("View the map")}
                            onClick={handleClickMap}
                        />
                        <div className="sidebar-city-boundary-container">
                            <p className="sidebar-city-boundary-symbol"></p>
                            <p>{t("SUMP Perimeter")}</p>
                        </div>
                        <Divider
                            className="sidebar-divider"
                            sx={{ marginBottom: 1 }}
                        />
                    </div>
                ) : (
                    ""
                )}

                {/* Map Section: Treeview for layers not linked to an indicator */}
                {/* One treeview for each theme */}
                {[
                    ...new Map(
                        Object.entries(storeMapLayersMobilityIndicators)
                    ).entries(),
                ].map(([key, _]) => {
                    return (
                        <TreeViewCheckboxMapLayersMobilityIndicators
                            key={key}
                            data={storeMapLayersMobilityIndicators[key]}
                        />
                    );
                })}

                {/* Map Section: One treeview for each theme if at least 1 indicator is checked*/}
                {[
                    ...new Map(
                        Object.entries(storeMobilityIndicators)
                    ).entries(),
                ].map(([key, _]) => {
                    if (
                        storeMobilityIndicators[key].treeviews.indicators
                            .checked !== 0
                    ) {
                        return (
                            <TreeViewCheckboxMobilityIndicators
                                key={key}
                                data={storeMobilityIndicators[key]}
                                type="layers"
                            />
                        );
                    } else {
                        return "";
                    }
                })}
            </Drawer>
        </Box>
    );
}
