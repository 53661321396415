import { api_url } from "../../../utils";

const DeplacementsLignesDesir = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=deplacements_lignes_desir&schema_city=",
    zindex: 2,
    type: "graduated",
    popup: {
        default: {
            content: {
                fr: {
                    flux: "",
                },
                en: {
                    flux: ""
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.DeplacementsLignesDesir",
    style: {
        regular: false,
        fillColor: "rgb(213, 180, 60)",
        strokeColor: "rgb(213, 180, 60)",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.DeplacementsLignesDesir.title",
            subtitle: "",
            attribute: "flux",
            symbol: "line",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(213, 180, 60)",
                    strokeWidth: 1,
                    min: 0,
                    max: 17999,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsLignesDesir.classes.1",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(213, 180, 60)",
                    strokeWidth: 5,
                    min: 18000,
                    max: 35999,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsLignesDesir.classes.2",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(213, 180, 60)",
                    strokeWidth: 10,
                    min: 36000,
                    max: 51999,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsLignesDesir.classes.3",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(213, 180, 60)",
                    strokeColor: "rgb(213, 180, 60)",
                    strokeWidth: 14,
                    min: 52000,
                    max: 80000,
                    label: "Mobility Indicators Content.layersLegend.DeplacementsLignesDesir.classes.4",
                    customCssClass: "",
                },
            ],
        },
    },
};

export default DeplacementsLignesDesir;
