import { configureStore } from "@reduxjs/toolkit";
import DashboardMobilityIndicators from "./stores/DashboardMobilityIndicators";
import DashboardSumpTrajectory from "./stores/DashboardSumpTrajectory";
import GanttSumpManagement from "./stores/GanttSumpManagement";
import Map from "./stores/Map";
import Cities from "./stores/Cities";
import Years from "./stores/Years";
import DigitalParticipation from "./stores/DigitalParticipation";
import MapLayersMobilityIndicators from "./stores/MapLayersMobilityIndicators";
import GeographicalAreas from "./stores/GeographicalAreas";
import General from "./stores/General";
import Routes from "./stores/Routes";
import References from "./stores/References";

export default configureStore({
    reducer: {
        dashboardMobilityIndicators: DashboardMobilityIndicators,
        dashboardSumpTrajectory: DashboardSumpTrajectory,
        ganttSumpManagement: GanttSumpManagement,
        map: Map,
        cities: Cities,
        years: Years,
        digitalParticipation: DigitalParticipation,
        mapLayersMobilityIndicators: MapLayersMobilityIndicators,
        geographicalAreas: GeographicalAreas,
        general: General,
        routes: Routes,
        references: References
    },
});
