import EvolutionDemographie from "./indicators/EvolutionDemographie";
import EvolutionOffreDemandeTc from "./indicators/EvolutionOffreDemandeTc";
import AccesTc from "./indicators/AccesTc";

const ChallengeTwo = {
    idSection: "challengeTwo",
    titleSection: "SUMP Trajectory Content.challenges.challenge2",
    indicators: {
        evolutionDemographie: EvolutionDemographie,
        evolutionOffreDemandeTc: EvolutionOffreDemandeTc,
        accesTc: AccesTc
    },
};

export default ChallengeTwo;
