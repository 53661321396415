import { createSlice } from "@reduxjs/toolkit";

const { api_url, config } = require("../settings");
const configChartsColorPalettes = config.charts.colorPalettes;
const configGanttStatusColors = config.gantt.status;
const configGanttYearEnd = config.gantt.yearEnd;
const configGanttWidthYear = config.gantt.widthYear;
const { popupInformation } = require("../settings-popup-information");
const popupInformationSumpManagementSpeech =
    popupInformation.SumpManagement.Gantt;
const defaultComponents = {
    "An integrated public transport network": {
        progressColor: configChartsColorPalettes["1"].borderColor[3],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[3],
    },
    "Moderation of individual transportation modes": {
        progressColor: configChartsColorPalettes["1"].borderColor[2],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[2],
    },
    "Modernization of paratransit sector": {
        progressColor: configChartsColorPalettes["1"].borderColor[0],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[0],
    },
    "A walkable and bicycle-friendly city for all": {
        progressColor: configChartsColorPalettes["1"].borderColor[5],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[5],
    },
    "Revision of the freight strategy": {
        progressColor: configChartsColorPalettes["1"].borderColor[4],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[4],
    },
    "Communication, sensitization, team building": {
        progressColor: configChartsColorPalettes["1"].borderColor[8],
        progressSelectedColor: configChartsColorPalettes["1"].borderColor[8],
    },
};

let citiesObj = {};
Object.keys(config.cities).forEach(
    (city) =>
        (citiesObj[city] = {
            components: defaultComponents,
        })
);

export const ganttSumpManagement = createSlice({
    name: "ganttSumpManagement",
    initialState: {
        url: api_url + "charts/data/gantt/?schema_city=",
        speech: popupInformationSumpManagementSpeech,
        cities: citiesObj,
        status: configGanttStatusColors,
        yearEnd: configGanttYearEnd,
        widthYear: configGanttWidthYear,
    },
});

export default ganttSumpManagement.reducer;
