import { api_url } from "../../../utils";

const TacheUrbaine = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=tache_urbaine&schema_city=",
    zindex: 2,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                },
                en: {
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.TacheUrbaine",
    style: {
        regular: false,
        fillColor: "rgb(180, 172, 162)",
        strokeColor: "rgb(35, 35, 35)",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.TacheUrbaine.title",
            subtitle: "",
            attribute: "",
            symbol: "rectangle",
            legendCustomCssClass: "map-legend-symbol-border-1px-gray-35-35-35",
            class: [
                {
                    fillColor: "rgb(180, 172, 162)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    label: "Mobility Indicators Content.layersLegend.TacheUrbaine.classes.1",
                },
            ],
        },
    },
};

export default TacheUrbaine;
