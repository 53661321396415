import AccidentsCirculation from "./indicators/AccidentsCirculation";
import EmissionCo2Mode from "./indicators/EmissionCo2Mode";

const ChallengeFour = {
    idSection: "challengeFour",
    titleSection: "SUMP Trajectory Content.challenges.challenge4",
    indicators: {
        accidentsCirculation: AccidentsCirculation,
        emissionCo2Mode: EmissionCo2Mode
    },
};

export default ChallengeFour;
