import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import Home from "./components/Home";
import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Routes>
                <Route path="/home" exact element={<Home />} />
                <Route path="/sump/*" element={<App />} />
                <Route path="*" element={<Navigate replace to="/home" />} />
            </Routes>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
