import { Component } from "react";
import "../styles/MapLegend.css";
import parse from "html-react-parser";
import store from "../store";
import { withTranslation } from "react-i18next";

class mapLegend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            legendDisplay: store.getState().map.legendDisplay,
            data: {
                ...store.getState().dashboardMobilityIndicators,
                ...store.getState().mapLayersMobilityIndicators,
            },
        };

        store.subscribe(() => {
            this.setState({
                legendDisplay: store.getState().map.legendDisplay,
                data: {
                    ...store.getState().dashboardMobilityIndicators,
                    ...store.getState().mapLayersMobilityIndicators,
                },
            });
        });
    }

    render() {
        const listLegends = [];
        const currentCity = store.getState().cities.currentCity;
        for (let section of Object.values(this.state.data)) {
            if (
                "indicators" in section.treeviews === false ||
                section.treeviews.indicators.checked !== 0
            ) {
                Object.values(section.layers).forEach((layer, index) => {
                    // Check that the layer is checked
                    // Check that the layer is not excluded for this city
                    if (
                        layer.checked === true &&
                        !layer.excludeCities.includes(currentCity)
                    ) {
                        let layerLegend = layer.legend;
                        let layerLegendKeys = Object.keys(layerLegend);
                        // Check if there is a specific configuration for this city
                        if (layerLegendKeys.includes(currentCity) === true) {
                            layerLegend = layerLegend[currentCity];
                        } else {
                            layerLegend = layerLegend["default"];
                        }

                        if (layerLegend !== "undefined") {
                            let descriptionLegend = "";
                            const layerLegendSymbol = layerLegend.symbol;
                            const layerLegendCustomCssClass =
                                layerLegend.legendCustomCssClass;
                            // map-legend-symbol-border-dark-1px
                            for (const row of layerLegend.class) {
                                let layerLegendStyleSymbol = "";
                                // Variation of the size if the stroke width property is different from 0
                                if (layerLegendSymbol !== "point" && row.strokeWidth) {
                                    layerLegendStyleSymbol = `background-color: ${row.fillColor}; height: ${row.strokeWidth}px`;
                                }
                                // Point layer have the stroke width property equal to 0
                                else {
                                    layerLegendStyleSymbol = `background-color: ${row.fillColor}`;
                                }
                                const rowLegendCustomCssClass =
                                    row.customCssClass;
                                descriptionLegend += `<div class="map-legend-row"><div class="map-legend-symbol-container">
                              <p class="map-legend-symbol map-legend-symbol-${layerLegendSymbol} ${layerLegendCustomCssClass} ${rowLegendCustomCssClass}" style="${layerLegendStyleSymbol}"></p></div>
                              <p> ${this.props.t(row.label)}</p>
                              </div> `;
                            }
                            listLegends.push(
                                <div
                                    key={section.idSection + index}
                                    className="map-legend-container"
                                >
                                    {/* Hide the title if no value */}
                                    {layerLegend.title !== "" ? (
                                        <div className="map-legend-row map-legend-row-title">
                                            <p className="map-legend-title">
                                                {this.props.t(
                                                    layerLegend.title
                                                )}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {/* Hide the subtitle if no value */}
                                    {layerLegend.subtitle !== "" ? (
                                        <div className="map-legend-row">
                                            <p className="map-legend-subtitle">
                                                {this.props.t(
                                                    layerLegend.subtitle
                                                )}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {parse(descriptionLegend)}
                                </div>
                            );
                        }
                    }
                });
            }
        }

        const visibilityMapLegend =
            store.getState().map.display === false ||
            this.state.legendDisplay === false
                ? "hidden"
                : "visible";
        return (
            <div
                className="map-legends-container"
                style={{ visibility: visibilityMapLegend }}
            >
                {listLegends}
            </div>
        );
    }
}

export default withTranslation()(mapLegend);
