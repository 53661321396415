import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partSumpTrajectory,
    popupInformation,
    defaultBarPercentage,
    defaultDatalabelsProperties,
    defaultTicksCustomValue
} from "../../../utils";

const EvolutionPartModaleDeplacementsQuotidiens = {
    checked: false,
    titleSideBar: "Evolution de la part modale des déplacements quotidiens",
    titleIndicator: "SUMP Trajectory Content.charts.EvolutionPartModale.title",
    popupInformation: {
        status: "false",
        text: popupInformation[partSumpTrajectory]["evolutionPartModaleDeplacementsQuotidiens"],
    },
    keyFigure: {
        typeUpdate: "year", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "area",
            typeLabels: "geographical_areas", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "geographical_area", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "true",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "350",
            widthChart: "550",
            i18nKeyLabels: "SUMP Trajectory Content.charts.EvolutionPartModale.axis.labelsX",
            scales: {
                x: {
                    type: "category",
                    display: true,
                    stacked: true,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: true,
                    titleDisplay: true,
                    titleText: "",
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 100,
                    ticks: {
                        display: true,
                        value: {
                            prefix: "",
                            suffix: "%"
                        }
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "year", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDETOOLTIPIFZEROMarche à pied",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_marche&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].backgroundColor[0],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].borderColor[0],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDETOOLTIPIFZEROBicyclette",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_bicyclette&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].backgroundColor[1],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].borderColor[1],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDETOOLTIPIFZEROTransport individuel et petits taxis",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_transport_individuel&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].backgroundColor[2],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].borderColor[2],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDETOOLTIPIFZEROGrands taxis",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_grands_taxis&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].backgroundColor[3],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].borderColor[3],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDETOOLTIPIFZEROTransports collectifs hors personnel et scolaire",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_transport_collectif&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].backgroundColor[4],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens"].borderColor[4],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDELEGENDHIDETOOLTIPIFZEROMarche à pied",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_objective_marche&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].backgroundColor[0],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].borderColor[0],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDELEGENDHIDETOOLTIPIFZEROBicyclette",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_objective_bicyclette&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].backgroundColor[1],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].borderColor[1],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDELEGENDHIDETOOLTIPIFZEROTransport individuel et petits taxis",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_objective_transport_individuel&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].backgroundColor[2],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].borderColor[2],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDELEGENDHIDETOOLTIPIFZEROGrands taxis",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_objective_grands_taxis&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].backgroundColor[3],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].borderColor[3],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.EvolutionPartModale.legend.HIDELEGENDHIDETOOLTIPIFZEROTransports collectifs hors personnel et scolaire",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_geographical_areas&indicator=evolution_part_modale_deplacements_quotidiens&trend=reality&chart=evolution_part_modale_deplacements_quotidiens_objective_transport_collectif&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].backgroundColor[4],
                    borderColor: configChartsColorPalettes["trajectory_evolution_part_modale_deplacements_quotidiens_objective_transparency"].borderColor[4],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
            ],
        },
    ],
};

export default EvolutionPartModaleDeplacementsQuotidiens;
