import { pg_tileserv_url } from "../../../utils";

const DensitePopulation = {
    enable: true,
    checked: false,
    initialDisplay: false,
    excludeCities: [],
    source: "pg_tileserv", // api or pg_tileserv
    url: pg_tileserv_url + "REPLACECITYNAME.densite_population",
    // source: "api",
    // url: api_url + "maps/geojson/?table=densite_population&schema_city=",
    zindex: 3,
    type: "single", // QGIS symbology name
    // field_sqltable: name_label_popup
    popup: {
        default: {
            content: {
                fr: {},
                en: {}
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.DensitePopulation",
    style: {
        regular: false,
        fillColor: "rgb(60, 85, 208)",
        strokeColor: "rgb(60, 85, 208)",
        strokeWidth: 0,
        strokeLineDash: [],
        points: 0,
        radius: 1,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.DensitePopulation.title",
            subtitle: "",
            attribute: "",
            symbol: "point",
            legendCustomCssClass: "map-legend-symbol-5px",
            class: [
                {
                    fillColor: "rgb(60, 85, 208)",
                    strokeColor: "rgb(60, 85, 208)",
                    strokeWidth: 0,
                    label: "Mobility Indicators Content.layersLegend.DensitePopulation.classes.1",
                },
            ],
        },
    },
};

export default DensitePopulation;
