import { api_url } from "../../../utils";

const ArretsBus = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=arrets_bus&schema_city=",
    zindex: 3,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_arret: "Nom arrêt bus : ",
                },
                en: {
                    l_arret: "Name of Bus Stop: "
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.ArretsBus",
    style: {
        regular: false,
        fillColor: "rgb(133, 182, 111)",
        strokeColor: "rgb(35, 35, 35)",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 1,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.ArretsBus.title",
            subtitle: "",
            attribute: "",
            symbol: "point",
            legendCustomCssClass:
                "map-legend-symbol-3px map-legend-symbol-border-1px-gray-35-35-35",
            class: [
                {
                    fillColor: "rgb(133, 182, 111)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 0,
                    label: "Mobility Indicators Content.layersLegend.ArretsBus.classes.1",
                },
            ],
        },
    },
};

export default ArretsBus;
