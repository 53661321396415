import "../../styles/Dashboard.css";
import store from "../../store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DashboardIndicator from "./DashboardIndicator";

export default function DashboardSumpTrajectory() {
    const { t } = useTranslation();


    // List of themes
    let storeSumpTrajectoryThemes = useSelector(
        (_state) => store.getState().dashboardSumpTrajectory
    );

    return (
        <div className="dashboard-container-sump-trajectory">
            <div className="dashboard-container-sump-trajectory-main-title">
                <p className="dashboard-sump-trajectory-main-title">{t("SUMP Trajectory Content.mainTitle")}</p>
            </div>
            <div>
                { [...new Map(Object.entries(storeSumpTrajectoryThemes)).entries()].map(([key, _]) => {
                        return <DashboardIndicator key={key} id={key} part="sump-trajectory"/>
                    })
                }
            </div>
        </div>
    );
}
