import { useNavigate } from "react-router-dom";
import "../styles/Home.css";
import img_speech from "../img/bg_speech.png";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import store from "../store";
import LanguageSelector from "../components/LanguageSelector"

const { config } = require("../settings");

export default function Home() {
    const storeRoutes = store.getState().routes;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const changeRoute = (event) => {
        let tabName = event.target.name;
        const currentCity = Object.keys(store.getState().cities.listCities)[0];
        navigate("/sump/" + tabName + "/" + currentCity);
    };

    const importAllLogos = (r) => {
        return r.keys().map(r);
    };
    const listLogos = importAllLogos(
        require.context("../img/", false, /\/logo0.*_.*\.(png|jpe?g|svg)$/)
    );
    const tabs = [
        { label: "sump-management", display: storeRoutes.displayTabSumpManagement },
        { label: "sump-trajectory", display: storeRoutes.displayTabSumpTrajectory },
        { label: "mobility-indicators", display: storeRoutes.displayTabMobilityIndicators },
        { label: "digital-participation", display: storeRoutes.displayTabDigitalParticipation },

    ];

    return (
        <div className="home-container">
            <LanguageSelector></LanguageSelector>

            <div className="home-content home-content-width">
                <div className="home-header">
                    <div className="logo">
                        <Grid container spacing={2}>
                            {listLogos.map((image, index) => (
                                <Grid item xs={4} key={index}>
                                    <img
                                        key={index}
                                        src={image}
                                        alt="logo"
                                    ></img>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <h1
                        className="home-title"
                        dangerouslySetInnerHTML={{
                            __html: t(config.home.title),
                        }}
                    ></h1>
                    <div className="home-tabs-container">
                        {tabs.map((tab, i) => (
                            tab.display === true ? (
                            <div key={i}>
                                <button
                                    className="home-tabs-button"
                                    style={{
                                        backgroundImage: `url(${require(`../img/tab${i}_${i18n.resolvedLanguage}.png`)})`,
                                    }}
                                    onClick={changeRoute}
                                    name={tab.label}
                                ></button>
                            </div>) : null
                        ))}
                    </div>
                    <div
                        className="home-help"
                        dangerouslySetInnerHTML={{
                            __html: t(config.home.help),
                        }}
                    ></div>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <img
                            className="img-speech"
                            src={img_speech}
                            alt="img_speech"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div
                            className="home-speech"
                            dangerouslySetInnerHTML={{
                                __html: t(config.home.speech),
                            }}
                        ></div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
