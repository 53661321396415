import TauxMobiliteGenre from "./indicators/TauxMobiliteGenre";
import PartBudgetMoyenMobilite from "./indicators/PartBudgetMoyenMobilite";

const ChallengeThree = {
    idSection: "challengeThree",
    titleSection: "SUMP Trajectory Content.challenges.challenge3",
    indicators: {
        tauxMobiliteGenre: TauxMobiliteGenre,
        partBudgetMoyenMobilite: PartBudgetMoyenMobilite
    },
};

export default ChallengeThree;
