import { api_url } from "../../../utils";

const TraficRoutier = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=trafic_routier&schema_city=",
    zindex: 2,
    type: "graduated",
    popup: {
        default: {
            content: {
                fr: {
                    uvp_2: "Flux quotidien de circulation en UVP/j en double-sens : ",
                },
                en: {
                    uvp_2: "Daily Traffic Flow in PCU/Day in Two Directions: "
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.TraficRoutier",
    style: {
        regular: false,
        fillColor: "rgba(102, 88, 213)",
        strokeColor: "rgb(102, 88, 213)",
        strokeWidth: 0,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.TraficRoutier.title",
            subtitle: "",
            attribute: "uvp_2",
            symbol: "line",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(0, 0, 0)",
                    strokeColor: "rgb(0, 0, 0)",
                    strokeWidth: 1,
                    min: 0,
                    max: 19999,
                    label: "Mobility Indicators Content.layersLegend.TraficRoutier.classes.1",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(0, 0, 0)",
                    strokeColor: "rgb(0, 0, 0)",
                    strokeWidth: 4,
                    min: 20000,
                    max: 39999,
                    label: "Mobility Indicators Content.layersLegend.TraficRoutier.classes.2",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(0, 0, 0)",
                    strokeColor: "rgb(0, 0, 0)",
                    strokeWidth: 7,
                    min: 40000,
                    max: 54999,
                    label: "Mobility Indicators Content.layersLegend.TraficRoutier.classes.3",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(0, 0, 0)",
                    strokeColor: "rgb(0, 0, 0)",
                    strokeWidth: 11,
                    min: 55000,
                    max: 69999,
                    label: "Mobility Indicators Content.layersLegend.TraficRoutier.classes.4",
                    customCssClass: "",
                },
                {
                    fillColor: "rgb(0, 0, 0)",
                    strokeColor: "rgb(0, 0, 0)",
                    strokeWidth: 15,
                    min: 70000,
                    max: 150000,
                    label: "Mobility Indicators Content.layersLegend.TraficRoutier.classes.5",
                    customCssClass: "",
                },
            ],
        },
    },
};

export default TraficRoutier;
