import DensiteEmplois from "./layers/DensiteEmplois";
import DensitePopulation from "./layers/DensitePopulation";
import TacheUrbaine from "./layers/TacheUrbaine";
import TauxCroissanceAnnualise from "./indicators/TauxCroissanceAnnualise";
import KmBatisConstruits from "./indicators/KmBatisConstruits";
import DensiteNette from "./indicators/DensiteNette";
import PartActifsHabitants from "./indicators/PartActifsHabitants";
import NbPersonnesMenage from "./indicators/NbPersonnesMenage";
import NbHabitants from "./indicators/NbHabitants";
import NbEmplois from "./indicators/NbEmplois";

const PopulationUrbanization = {
    idSection: "populationUrbanization",
    questionSection: "",
    treeviews: {
        title: "Mobility Indicators Content.sections.PopulationUrbanization",
        indicators: {
            checked: 0, // 0 unchecked, 1 indeterminate, 2 checked
            expand: false,
        },
        layers: {
            checked: 0,
            expand: true,
        },
    },
    layers: {
        densiteEmplois: DensiteEmplois,
        densitePopulation: DensitePopulation,
        tacheUrbaine: TacheUrbaine,
    },
    indicators: {
        nbHabitants: NbHabitants,
        tauxCroissanceAnnualise: TauxCroissanceAnnualise,
        kmBatisConstruits: KmBatisConstruits,
        partActifsHabitants: PartActifsHabitants,
        densiteNette: DensiteNette,
        nbEmplois: NbEmplois,
        nbPersonnesMenage: NbPersonnesMenage,
    },
};

export default PopulationUrbanization;
