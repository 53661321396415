import { useEffect, useState } from "react";
import "gantt-task-react/dist/index.css";
import "../../styles/GanttSumpManagement.css";
import store from "../../store";
import GanttMyc from "./Gantt.js";
import GanttMycLegend from "./GanttLegend.js";
import { useSelector } from "react-redux";
import PopupInformation from "../PopupInformation";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

export default function GanttSumpManagement() {
    const { t } = useTranslation();
    let storeCurrentCity = useSelector(
        (_state) => store.getState().cities.currentCity
    );

    const [urlApi] = useState([store.getState().ganttSumpManagement.url]);
    const [defaultYearEnd] = useState([
        store.getState().ganttSumpManagement.yearEnd,
    ]);
    const [popupInformationSpeech] = useState([
        store.getState().ganttSumpManagement.speech,
    ]);
    const [citiesComponents] = useState([
        store.getState().ganttSumpManagement.cities,
    ]);
    const [status] = useState([store.getState().ganttSumpManagement.status]);
    const [categories, setCategories] = useState([]);
    const [lastUpdate, setLastUpdate] = useState([]);
    const [yearStart, setYearStart] = useState([2500]);
    const [yearEnd, setYearEnd] = useState([1900]);

    // Retrieve data to generate the Gantt chart
    useEffect(() => {
        // Update data on components and projects
        let getData = async () => {
            // Fetch if a city has been selected
            if (storeCurrentCity.length !== 0) {
                let response = await fetch(urlApi + storeCurrentCity);

                // Valid api call
                if (response.status === 200) {
                    const data = await response.json();

                    const dataProjects = data.projects;
                    const categories = data.categories;
                    setLastUpdate(data.last_update);

                    let dataCategories = [];
                    categories.forEach(function (category) {
                        dataCategories.push({
                            id: category.id,
                            name: category.name,
                            color: category.color,
                        });
                    });

                    // Get min & max years
                    dataProjects.forEach(function (project) {
                        if (project.start !== "") {
                            let start = new Date(project.start).getFullYear();
                            if (yearStart > start) {
                                setYearStart(start);
                            }
                        }
                        if (project.end !== "") {
                            let end = new Date(project.end).getFullYear();
                            if (yearEnd < end) {
                                setYearEnd(defaultYearEnd);
                            }
                        }
                    });

                    // For each category, one gantt diagram
                    let localCategories = [];
                    dataCategories.forEach(function (currentCategory) {
                        let dataProjectsValid = [];
                        dataProjects.forEach(function (project) {
                            if (project.category === currentCategory.id) {
                                dataProjectsValid.push({
                                    id: project.id,
                                    name: project.task,
                                    start:
                                        project.start !== ""
                                            ? new Date(project.start)
                                            : new Date(yearStart + "/01/01"),
                                    end:
                                        project.end !== ""
                                            ? new Date(project.end)
                                            : new Date(yearStart + "/01/01"),
                                    progress: project.progress,
                                    progressReal: project.progress_real,
                                    type: "task",
                                    isAction: project.is_action,
                                    styles: {
                                        backgroundColor:
                                            project.background_color,
                                        progressColor: project.progress_color,
                                        progressRealColor:
                                            status[0][project.status],
                                    },
                                });
                            }
                        });
                        localCategories.push({
                            id: currentCategory.id,
                            name: currentCategory.name,
                            color: currentCategory.color,
                            currentProjects: dataProjectsValid,
                        });
                    });
                    setCategories(localCategories);
                }
            }
        };
        getData();
    }, [
        storeCurrentCity,
        citiesComponents,
        urlApi,
        defaultYearEnd,
        status,
        yearStart,
        yearEnd,
    ]);

    let tabGanttsMyc = [];
    for (let i = 0; i < categories.length; i++) {
        tabGanttsMyc.push({
            html: (
                <div className="gantt-sump-management-chart">
                    <GanttMyc
                        tasks={categories[i].currentProjects}
                        startYear={yearStart}
                        endYear={yearEnd}
                        key={categories[i].id}
                    />
                </div>
            ),
            category: categories[i],
        });
    }

    return (
        <Box
            display="flex"
            justifyContent="center"
            p={0}
            bgcolor="white"
            width={"100%"}
            mt={1}
        >
            <Box width={"80%"} p={1} mt={"10px"}>
                <Box marginLeft={2.5}>
                    <PopupInformation text={popupInformationSpeech[0]} />
                    <div className="date-update">{t("Last update")} : {lastUpdate}</div>
                    <GanttMycLegend />
                </Box>
                <Box p={2} className="box-gantt">
                    {categories.length > 0
                        ? tabGanttsMyc.map((gantt, _) => (
                              <Accordion
                                  key={gantt.category.id}
                                  className="gantt-accordion-item"
                                  defaultExpanded
                              >
                                  <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id={gantt.category.id}
                                      sx={{
                                          backgroundColor: gantt.category.color,
                                          color: "white",
                                      }}
                                  >
                                    {t(`SUMP Management Content.axis.${gantt.category.name}`)}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                      {gantt.html}
                                  </AccordionDetails>
                              </Accordion>
                          ))
                        : ""}
                </Box>
            </Box>
        </Box>
    );
}
