import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partMobilityIndicators,
    popupInformation,
    defaultBarPercentage,
    defaultDatalabelsProperties,
    defaultTicksCustomValue
} from "../../../utils";

const RepartitionModaleDeplacementsQuotidiens = {
    checked: false,
    titleSideBar: "Mobility Indicators Content.indicators.RepartitionModaleDeplacementsQuotidiens",
    titleIndicator: "Mobility Indicators Content.indicators.RepartitionModaleDeplacementsQuotidiens",
    popupInformation: {
        status: "false",
        text: popupInformation[partMobilityIndicators]["repartitionModaleDeplacementsQuotidiens"],
    },
    keyFigure: {
        typeUpdate: "year", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "area",
            typeLabels: "geographical_areas", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "geographical_area", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "true",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "350",
            widthChart: "600",
            i18nKeyLabels: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.axis.labelsX",
            scales: {
                x: {
                    type: "category",
                    display: true,
                    stacked: true,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: true,
                    titleDisplay: true,
                    titleText: "",
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 100,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "year", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Marche",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=marche&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[0],
                    borderColor: configChartsColorPalettes["5"].borderColor[0],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Vélo",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=velo&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[1],
                    borderColor: configChartsColorPalettes["5"].borderColor[1],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Deux-roues motorisé",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=deux_roues&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[2],
                    borderColor: configChartsColorPalettes["5"].borderColor[2],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Voiture",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=voiture&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[3],
                    borderColor: configChartsColorPalettes["5"].borderColor[3],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Petits taxis",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=petits_taxis&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[4],
                    borderColor: configChartsColorPalettes["5"].borderColor[4],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Grands taxis",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=grands_taxis&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[5],
                    borderColor: configChartsColorPalettes["5"].borderColor[5],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.ONCF interne",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=oncf_interne&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[6],
                    borderColor: configChartsColorPalettes["5"].borderColor[6],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Tramway",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=tramway&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[7],
                    borderColor: configChartsColorPalettes["5"].borderColor[7],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Bus",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=bus&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[8],
                    borderColor: configChartsColorPalettes["5"].borderColor[8],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Transport scolaire",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=transport_scolaire&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[9],
                    borderColor: configChartsColorPalettes["5"].borderColor[9],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Transport de personnel",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=transport_personnel&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[10],
                    borderColor: configChartsColorPalettes["5"].borderColor[10],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
                {
                    type: "bar",
                    label: "Mobility Indicators Content.charts.RepartitionModaleDeplacementsQuotidiens.legend.Autres (ONCF externe, VTC et informel)",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=repartition_modale_deplacements_quotidiens&trend=projection&chart=autres&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["5"].backgroundColor[11],
                    borderColor: configChartsColorPalettes["5"].borderColor[11],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.4,
                    datalabelsProperties: defaultDatalabelsProperties,
                },
            ],
        },
    ],
};

export default RepartitionModaleDeplacementsQuotidiens;
