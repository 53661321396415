import { api_url } from "../../../utils";

const PartsModales = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=parts_modales&schema_city=",
    zindex: 3,
    type: "pie",
    pieProps: {
        data: ["rep_mod_com_ar_vf_modes_actifs", "rep_mod_com_ar_vf_ti", "rep_mod_com_ar_vf_petits_taxis", "rep_mod_com_ar_vf_grands_taxis", "rep_mod_com_ar_vf_tc", "rep_mod_com_ar_vf_autres"],
        total: "dep_tot",
        colors: [
            "rgb(90, 231, 43)",
            "rgb(229, 20, 107)",
            "rgb(244, 244, 15)",
            "rgb(249, 199, 20)",
            "rgb(76, 195, 242)",
            "rgb(196, 204, 207)",
        ],
    },
    popup: {
        default: {
            content: {
                fr: {
                    nom_comar: "Nom de la commune ou de l'arrondissement : ",
                    dep_tot: "Déplacements totaux : ",
                    rep_mod_com_ar_vf_modes_actifs: "Modes actifs : ",
                    rep_mod_com_ar_vf_ti: "Transports individuels : ",
                    rep_mod_com_ar_vf_petits_taxis: "Petits taxis : ",
                    rep_mod_com_ar_vf_grands_taxis: "Grands taxis : ",
                    rep_mod_com_ar_vf_tc: "TC : ",
                    rep_mod_com_ar_vf_autres: " Autres : ",
                },
                en: {
                    nom_comar: "Name of the Municipality or District: ",
                    dep_tot: "Total Trips: ",
                    rep_mod_com_ar_vf_modes_actifs: "Active Modes: ",
                    rep_mod_com_ar_vf_ti: "Individual Transportation: ",
                    rep_mod_com_ar_vf_petits_taxis: "Small Taxis: ",
                    rep_mod_com_ar_vf_grands_taxis: "Large Taxis: ",
                    rep_mod_com_ar_vf_tc: "Public Transportation: ",
                    rep_mod_com_ar_vf_autres: "Others: ",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.PartsModales",
    style: {
        regular: false,
        fillColor: "rgb(35, 35, 35)",
        strokeColor: "rgb(35, 35, 35)",
        strokeWidth: 1,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.PartsModales.title",
            subtitle: "",
            attribute: "",
            symbol: "rectangle",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(90, 231, 43)",
                    strokeColor: "rgb(90, 231, 43)",
                    strokeWidth: 2,
                    label: "Mobility Indicators Content.layersLegend.PartsModales.classes.1",
                },
                {
                    fillColor: "rgb(229, 20, 107)",
                    strokeColor: "rgb(229, 20, 107)",
                    strokeWidth: 2,
                    label: "Mobility Indicators Content.layersLegend.PartsModales.classes.2",
                },
                {
                    fillColor: "rgb(244, 244, 15)",
                    strokeColor: "rgb(244, 244, 15)",
                    strokeWidth: 2,
                    label: "Mobility Indicators Content.layersLegend.PartsModales.classes.3",
                },
                {
                    fillColor: "rgb(249, 199, 20)",
                    strokeColor: "rgb(249, 199, 20)",
                    strokeWidth: 2,
                    label: "Mobility Indicators Content.layersLegend.PartsModales.classes.4",
                },
                {
                    fillColor: "rgb(76, 195, 242)",
                    strokeColor: "rgb(76, 195, 242)",
                    strokeWidth: 2,
                    label: "Mobility Indicators Content.layersLegend.PartsModales.classes.5",
                },
                {
                    fillColor: "rgb(196, 204, 207)",
                    strokeColor: "rgb(196, 204, 207)",
                    strokeWidth: 2,
                    label: "Mobility Indicators Content.layersLegend.PartsModales.classes.6",
                },
            ],
        },
    },
};

export default PartsModales;
