import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partSumpTrajectory,
    popupInformation,
    defaultDatalabelsAlign,
    defaultDatalabelsAnchor,
    defaultDatalabelsFontWeight,
    defaultTicksCustomValue
} from "../../../utils";

const EvolutionDemographieDeplacements = {
    checked: false,
    titleSideBar: "Evolution de la démographie et des déplacements",
    titleIndicator: "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.title",
    popupInformation: {
        status: "false",
        text: popupInformation[partSumpTrajectory]["evolutionDemographieDeplacements"],
    },
    keyFigure: {
        typeUpdate: "geographical_area", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "line",
            typeLabels: "years", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "year", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "true",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "300",
            widthChart: "500",
            scales: {
                x: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    min: 2000,
                    max: 2040,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: true,
                    titleText: ["SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.axis.titleY1",
                    "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.axis.titleY2",
                    "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.axis.titleY3"
                    ],
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 10000000,
                    ticks: {
                        display: true,
                        value: {
                            prefix: "",
                            suffix: ""
                        }
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.legend.HIDETOOLTIPIFZEROPopulation",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie_deplacements&trend=reality&chart=evolution_demographie_deplacements_demographie&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_population"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_population"].borderColor["0"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZEROPopulation",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie_deplacements&trend=reality&chart=evolution_demographie_deplacements_demographie_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_population_objective_transparency"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_population_objective_transparency"].borderColor["0"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2022","2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.legend.HIDETOOLTIPIFZERODéplacements",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie_deplacements&trend=reality&chart=evolution_demographie_deplacements_deplacements&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_deplacements"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_deplacements"].borderColor["0"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographieDeplacements.legend.HIDELEGENDHIDETOOLTIPIFZERODéplacements",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie_deplacements&trend=reality&chart=evolution_demographie_deplacements_deplacements_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_deplacements_objective_transparency"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_deplacements_deplacements_objective_transparency"].borderColor["0"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2 021","2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
            ],
        },
    ],
};

export default EvolutionDemographieDeplacements;
