import { api_url } from "../../../utils";

const Prefectures = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=perimeter_prefectures&schema_city=",
    zindex: 1,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_pref: "Nom de la préfecture : "
                },
                en: {
                    l_pref: "Prefecture Name: "
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.Prefectures",
    style: {
        regular: false,
        fillColor: "rgba(0, 0, 0, 0)",
        strokeColor: "rgb(233, 35, 35)",
        strokeWidth: 3.5,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.Prefectures.title",
            subtitle: "",
            attribute: "",
            symbol: "rectangle",
            legendCustomCssClass: "map-legend-symbol-border-2px-red-233-35-35",
            class: [
                {
                    fillColor: "rgba(0, 0, 0, 0)",
                    strokeColor: "rgb(233, 35, 35)",
                    strokeWidth: 3,
                    label: "Mobility Indicators Content.layersLegend.Prefectures.classes.1",
                },
            ],
        },
    },
};

export default Prefectures;
