import { createSlice } from "@reduxjs/toolkit";

export const digitalParitipation = createSlice({
    name: "digitalParitipation",
    initialState: {
            infos_form: {
                questions: [
                    {
                        label: "Digital Participation Survey.infos_form.I am",
                        answers: ["Digital Participation Survey.infos_form.A woman", "Digital Participation Survey.infos_form.A man"],
                    },
                    {
                        label: "Digital Participation Survey.infos_form.I live in the prefecture of",
                        answers: ["Rabat", "Digital Participation Survey.general.Salé", "Digital Participation Survey.general.Skhirat-Témara", "Digital Participation Survey.general.Other"],
                    },
                    {
                        label: "Digital Participation Survey.infos_form.I am (years old)",
                        answers: ["0-14", "15-29", "30-44", "45-59", "60+"],
                    },
                    {
                        label: "Digital Participation Survey.infos_form.My household is composed of",
                        answers: ["Digital Participation Survey.infos_form.Single person", "Digital Participation Survey.infos_form.2 members", "Digital Participation Survey.infos_form.3 members", "Digital Participation Survey.infos_form.4 members", "Digital Participation Survey.infos_form.5 members", "Digital Participation Survey.infos_form.6 members and +"],
                    },
                    {
                        label: "Digital Participation Survey.infos_form.My household owns a vehicle",
                        answers: ["0", "1", "2", "3", "4", "Digital Participation Survey.infos_form.5 and +"],
                    },
                ]
            },
            transports_used : {
                    label: "Digital Participation Survey.transports_used.For my daily trips, I go by",
                    answers: [
                        {
                            label: "Digital Participation Survey.transports_used.Walk",
                            mode_form_id : "Walk",
                        },
                        {
                            label: "Digital Participation Survey.transports_used.Bicycle",
                            mode_form_id : "Bicycle",
                        },
                        {
                            label: "Digital Participation Survey.transports_used.Bus",
                            mode_form_id : "Bus",
                        },
                        {
                            label: "Tramway",
                            mode_form_id : "Tramway",
                        },
                        {
                            label: "Digital Participation Survey.transports_used.Other public transport",
                            mode_form_id : "Others",
                        },
                        {
                            label: "Digital Participation Survey.transports_used.My own vehicle (family vehicle)",
                            mode_form_id : "Others",
                        },
                        {
                            label: "Digital Participation Survey.transports_used.Others",
                            mode_form_id : "Others",
                        },
                    ],
                    colors: ["#00B050", "#92D050", "#00B0F0", "#a23890", "#DAE9F8", "#D86DCD", "#F7C7AC"],
                    hint: "Digital Participation Survey.general.General hint",
                    exclusive: false,
            },
            modes_answers : ["Digital Participation Survey.answers.Strongly disagree", "Digital Participation Survey.answers.Disagree", "Digital Participation Survey.answers.Somewhat disagree", "Digital Participation Survey.answers.Somewhat agree", "Digital Participation Survey.answers.Agree", "Digital Participation Survey.answers.Strongly agree"],
            modes_colors_answers : ["#fa7d32", "#ffc000", "#ffe899", "#d3ecb9", "#92d050", "#70ab2e"],
            transports_used_detail : [
                {
                    mode: "Digital Participation Survey.transports_used.Other public transport",
                    mode_id: "Others",
                    label: "Digital Participation Survey.transports_used.I use",
                    answers: ["Tram", "Bus", "Digital Participation Survey.transports_used.Big taxis"],
                    colors: ["#002060", "#00B0F0", "#FFC000"],
                },
                {
                    mode: "Digital Participation Survey.transports_used.My own vehicle (family vehicle)",
                    mode_id: "Others",
                    label: "Digital Participation Survey.transports_used.I use",
                    answers: ["Digital Participation Survey.transports_used.Car", "Digital Participation Survey.transports_used.Electric car", "Digital Participation Survey.transports_used.Two-wheeler", "Digital Participation Survey.transports_used.Electric two-wheeler"],
                    colors: ["#D86DCD", "#fd5fb2", "#F2CEEF", "#cf6fc0"],
                },
                {
                    mode: "Digital Participation Survey.transports_used.Others",
                    mode_id: "Others",
                    label: "Digital Participation Survey.transports_used.I use",
                    answers: ["Taxi", "Digital Participation Survey.transports_used.Auto rickshaw", "Digital Participation Survey.transports_used.My work transport system", "Digital Participation Survey.transports_used.My school transport system", "Digital Participation Survey.transports_used.Taxi-bicycle"],
                    colors: ["#d7d718", "#ffd966", "#94DCF8", "#CAEDFB", "#92D050"],
                },
            ],
            why : {
                label : "Digital Participation Survey.transports_used.For what purpose ?",
                answers : ["Digital Participation Survey.transports_used.Work", "Digital Participation Survey.transports_used.School/Education", "Digital Participation Survey.transports_used.Others"]
            },
            modes: [
                {
                    id: "Bicycle",
                    label: "Digital Participation Survey.questions.I go by Bicycle",
                    color: "#92d050",
                    affirmations : [
                        {
                            label: "Digital Participation Survey.questions.Cycling in *** is pleasant",
                            color: "#92d050",
                            subAffirmations: [
                                "Digital Participation Survey.questions.I can move around quickly and directly",
                                "Digital Participation Survey.questions.When cycling, people driving motorised vehicles respect me",
                                "Digital Participation Survey.questions.When cycling, I find that motorised traffic (volume and speed) is not annoying",
                                "Digital Participation Survey.questions.The use of bicycles is becoming more inclusive (in terms of age, gender, income group)",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.It is safe to use a bicycle in ***",
                            color: "#92d050",
                            subAffirmations: [
                                "Digital Participation Survey.questions.I can cycle safely on main roads",
                                "Digital Participation Survey.questions.I can cycle safely on residential streets",
                                "Digital Participation Survey.questions.It is not dangerous to cross a crossroads or roundabout by bicycle",
                                "Digital Participation Survey.questions.Cycling is safe for children and elderly people",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.The efforts made by the municipality to promote cycling are significant",
                            color: "#92d050",
                            subAffirmations: [
                                "Digital Participation Survey.questions.Cycling paths exist",
                                "Digital Participation Survey.questions.Cycling paths are under project",
                                "Digital Participation Survey.questions.Parking my bicycle at a train or public transport station is easy",
                                "Digital Participation Survey.questions.The Public Bicycle Sharing system exists and is affordable",
                                "Digital Participation Survey.questions.Communication in favour of cycling is being undertaken by the city",
                            ],
                        },
                    ],
                },
                {
                    id: "Bus",
                    label: "Digital Participation Survey.questions.I go by Bus",
                    color: "#00b0f0",
                    affirmations : [
                        {
                            label: "Digital Participation Survey.questions.Using the bus in *** is pleasant",
                            color: "#00b0f0",
                            subAffirmations: [
                                "Digital Participation Survey.questions.The vehicle is comfortable and adapted to all type of users (disabled, pregnant, eldery)",
                                "Digital Participation Survey.questions.The vehicle is clean",
                                "Digital Participation Survey.questions.The ticket price is acceptable",
                                "Digital Participation Survey.questions.The waiting time is acceptable",
                                "Digital Participation Survey.questions.It is easy to access a bus station, even for disabled, pregnant or eldery persons",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.It is safe to take the bus in ***",
                            color: "#00b0f0",
                            subAffirmations: [
                                "Digital Participation Survey.questions.It is safe to wait at a station, whether by day or night",
                                "Digital Participation Survey.questions.It is safe inside the bus, whether by day or night",
                                "Digital Participation Survey.questions.The driver/collector can be helpful in case of harassment",
                                "Digital Participation Survey.questions.It is safe for women",
                                "Digital Participation Survey.questions.There are no road accidents involving buses",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.The efforts made by the municipality to develop public transport are significant",
                            color: "#00b0f0",
                            subAffirmations: [
                                "Digital Participation Survey.questions.All parts of the agglomeration are accessible by bus",
                                "Digital Participation Survey.questions.Bus lanes exist to favour public transport in the heavy traffic",
                                "Digital Participation Survey.questions.It is easy to transfer from/to another type of transport (private vehicle, rickshaw, taxi, public transport…)",
                            ],
                        },
                    ],
                },
                {
                    id: "Tramway",
                    label: "Digital Participation Survey.questions.I go by Tram",
                    color: "#002060",
                    affirmations : [
                        {
                            label: "Digital Participation Survey.questions.Using the tram in *** is pleasant",
                            color: "#002060",
                            subAffirmations: [
                                "Digital Participation Survey.questions.The tram is comfortable and adapted to all type of users (disabled, pregnant, eldery)",
                                "Digital Participation Survey.questions.The tram is clean",
                                "Digital Participation Survey.questions.The ticket price is acceptable",
                                "Digital Participation Survey.questions.The waiting time is acceptable",
                                "Digital Participation Survey.questions.It is easy to access a tram station, even for disabled, pregnant or eldery persons",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.It is safe to take the tram in ***",
                            color: "#002060",
                            subAffirmations: [
                                "Digital Participation Survey.questions.It is safe to wait at a tram station, whether by day or night",
                                "Digital Participation Survey.questions.It is safe inside the tram, whether by day or night",
                                "Digital Participation Survey.questions.The driver/collector can be helpful in case of harassment",
                                "Digital Participation Survey.questions.It is safe for women in trams",
                                "Digital Participation Survey.questions.There are no road accidents involving trams",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.The efforts made by the municipality to develop tram are significant",
                            color: "#002060",
                            subAffirmations: [
                                "Digital Participation Survey.questions.All parts of the agglomeration are accessible by tram",
                                "Digital Participation Survey.questions.Tram lanes exist to favour public transport in the heavy traffic",
                                "Digital Participation Survey.questions.It is easy to transfer from/to another type of transport (private vehicle, rickshaw, taxi, public transport…) in trams"
                            ],
                        },
                    ],
                },
                {
                    id: "Walk",
                    label: "Digital Participation Survey.questions.I go by Walk",
                    color: "#00b050",
                    affirmations : [
                        {
                            label: "Digital Participation Survey.questions.Walking in *** is pleasant",
                            color: "#00b050",
                            subAffirmations: [
                                "Digital Participation Survey.questions.Walkways are large and confortable along main roads",
                                "Digital Participation Survey.questions.Walkways are large and confortable in residential areas",
                                "Digital Participation Survey.questions.When walking, I find that motorised traffic (volume and speed) is not annoying",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.It is safe to walk in ***",
                            color: "#00b050",
                            subAffirmations: [
                                "Digital Participation Survey.questions.I can walk safely along main roads",
                                "Digital Participation Survey.questions.I can walk safely along residential streets",
                                "Digital Participation Survey.questions.It is not dangerous to cross a crossroads or roundabout by walk",
                                "Digital Participation Survey.questions.The street are safe for children and elderly people",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.The efforts made by the municipality to promote the walk are significant",
                            color: "#00b050",
                            subAffirmations: [
                                "Digital Participation Survey.questions.Pedestrian areas and parks exist in the city",
                                "Digital Participation Survey.questions.New pedestrian areas or walkways are under implementation",
                                "Digital Participation Survey.questions.Communication in favour of walking is being under taken by the city",
                            ],
                        },
                    ],
                },
                {
                    id: "Others",
                    label: "Digital Participation Survey.questions.I go by Others transports",
                    color: "#cccccc",
                    affirmations : [
                        {
                            label: "Digital Participation Survey.questions.I do not use the bus because",
                            color: "#002060",
                            subAffirmations: [
                                "Digital Participation Survey.answers.It is unsafe",
                                "Digital Participation Survey.answers.It is unreliable",
                                "Digital Participation Survey.answers.It is not affordable",
                                "Digital Participation Survey.answers.The bus stop is too far",
                                "Digital Participation Survey.answers.Information is not clear or suffisant",
                                "Digital Participation Survey.answers.Waiting time is too long",
                                "Digital Participation Survey.answers.It is excessive time consuming",
                                "Digital Participation Survey.answers.It is uncomfortable (heat, crowd, …)",
                                "Digital Participation Survey.answers.It is not disabled friendly",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.I do not use the bicycle because",
                            color: "#92d050",
                            subAffirmations: [
                                "Digital Participation Survey.answers.I cant't ride a bike",
                                "Digital Participation Survey.answers.It is dangerous",
                                "Digital Participation Survey.answers.There is no place to park",
                                "Digital Participation Survey.answers.It is excessive time consuming",
                                "Digital Participation Survey.answers.It is too far",
                                "Digital Participation Survey.answers.It is uncomfortable (heat, crowd, …) by this way",
                                "Digital Participation Survey.answers.This is not suitable for my needs",
                                "Digital Participation Survey.answers.It is not affordable",
                            ],
                        },
                        {
                            label: "Digital Participation Survey.questions.I do not walk because",
                            color: "#00b050",
                            subAffirmations: [
                                "Digital Participation Survey.answers.It is dangerous",
                                "Digital Participation Survey.answers.It is excessive time consuming",
                                "Digital Participation Survey.answers.It is too far",
                                "Digital Participation Survey.answers.It is uncomfortable (heat, crowd, …) by this way",
                                "Digital Participation Survey.answers.This is not suitable for my needs",
                            ],
                        },
                    ],
                },
            ]
        }

});

export default digitalParitipation.reducer;
