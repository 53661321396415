import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partSumpTrajectory,
    popupInformation,
    defaultDatalabelsAlign,
    defaultDatalabelsAnchor,
    defaultDatalabelsFontWeight,
    defaultTicksCustomValue
} from "../../../utils";

const EvolutionDemographie = {
    checked: false,
    titleSideBar: "Evolution de la démographie",
    titleIndicator: "SUMP Trajectory Content.charts.EvolutionDemographie.title",
    popupInformation: {
        status: "false",
        text: popupInformation[partSumpTrajectory]["evolutionDemographie"],
    },
    keyFigure: {
        typeUpdate: "geographical_area", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "line",
            typeLabels: "years", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "year", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "true",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "300",
            widthChart: "500",
            scales: {
                x: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    min: 1990,
                    max: 2040,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: true,
                    titleText: "SUMP Trajectory Content.charts.EvolutionDemographie.axis.titleY",
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 1400000,
                    ticks: {
                        display: true,
                        value: {
                            prefix: "",
                            suffix: ""
                        }
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDETOOLTIPIFZEROPréfecture Rabat",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_rabat&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie"].borderColor["0"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDELEGENDHIDETOOLTIPIFZEROPréfecture Rabat",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_rabat_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].borderColor["0"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2022","2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDETOOLTIPIFZEROPréfecture Salé",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_sale&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie"].backgroundColor["1"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie"].borderColor["1"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDELEGENDHIDETOOLTIPIFZEROPréfecture Salé",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_sale_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].backgroundColor["1"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].borderColor["1"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2022","2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDETOOLTIPIFZEROPréfecture Skhirat-Témara",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_skhirat&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie"].backgroundColor["2"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie"].borderColor["2"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDELEGENDHIDETOOLTIPIFZEROPréfecture Skhirat-Témara",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_skhirat_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].backgroundColor["2"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].borderColor["2"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2022","2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDETOOLTIPIFZEROSidi Taïbi",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_sidi_taibi&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie"].backgroundColor["3"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie"].borderColor["3"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.EvolutionDemographie.legend.HIDELEGENDHIDETOOLTIPIFZEROSidi Taïbi",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=evolution_demographie&trend=reality&chart=evolution_demographie_sidi_taibi_objective&chart_data=True&chart_multi_year_geographical_area=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].backgroundColor["3"],
                    borderColor: configChartsColorPalettes["trajectory_evolution_demographie_objective_transparency"].borderColor["3"],
                    borderWidth: 1,
                    pointStyle: "circle",
                    pointRadius: 2,
                    spanGaps: false,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    tooltipToHide: ["2022","2 022"],
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                }
            ],
        },
    ],
};

export default EvolutionDemographie;
