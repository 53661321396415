import { useTranslation } from "react-i18next";
import { updateCurrentLanguage } from "../stores/General";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../styles/LanguageSelector.css";

export default function Home() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    // Available languages
    const listLanguages = {
        fr: t("languageFrench"),
        en: t("languageEnglish"),
    };
    // availableLanguages
    const currentLanguage = listLanguages[i18n.resolvedLanguage];

    const handleLanguageChange = (event) => {
        let languageName = event.target.value;
        let lng = "";

        // Translation in the url
        if (languageName === t("languageFrench")) {
            lng = "fr";
        } else {
            lng = "en";
        }
        i18n.changeLanguage(lng);
        dispatch(updateCurrentLanguage(lng));
    };

    return (
        <div id="box-select-language">
        <Select
            id="appbar-select-language"
            defaultValue={currentLanguage}
            value={currentLanguage}
            onChange={handleLanguageChange}
        >
            {Object.values(listLanguages).map(
                (languageName, i) => {
                    return (
                        <MenuItem value={languageName} key={i}>
                            {languageName}
                        </MenuItem>
                    );
                }
            )}
        </Select>
    </div>
    );
}
