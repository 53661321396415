import { api_url } from "../../../utils";

const LignesBus = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=lignes_bus&schema_city=",
    zindex: 2,
    type: "categorized",
    popup: {
        default: {
            content: {
                fr: {
                    num_ligne: "Numéro ligne bus : ",
                    origine: "Origine : ",
                    destination: "Destination : ",
                    freq: "Fréquence moyenne de passage (min) : ",
                    vit_com: "Vitesse commerciale (km/h) : ",
                    descriptio: "",
                },
                en: {
                    num_ligne: "Bus Line Number: ",
                    origine: "Origin: ",
                    destination: "Destination: ",
                    freq: "Average Frequency of Service (minutes): ",
                    vit_com: "Commercial Speed (km/h): ",
                    descriptio: "",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.LignesBus",
    style: {
        regular: false,
        fillColor: "rgb(255, 158, 23)",
        strokeColor: "rgb(255, 158, 23)",
        strokeWidth: 0,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.LignesBus.title",
            subtitle: "",
            attribute: "descriptio",
            symbol: "line",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(0, 176, 240)",
                    strokeColor: "rgb(0, 176, 240)",
                    strokeWidth: 2,
                    value: "Aller",
                    label: "Mobility Indicators Content.layersLegend.LignesBus.classes.1",
                },
                {
                    fillColor: "rgb(0, 176, 240)",
                    strokeColor: "rgb(0, 176, 240)",
                    strokeWidth: 2,
                    strokeLineDash: [3, 9],
                    value: "Retour",
                    label: "Mobility Indicators Content.layersLegend.LignesBus.classes.2",
                    customCssClass: "map-legend-symbol-line-dotted-3px-no-background-0-176-240"
                },
            ],
        },
    },
};

export default LignesBus;
