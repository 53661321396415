import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partSumpTrajectory,
    popupInformation,
    defaultDatalabelsAlign,
    defaultDatalabelsAnchor,
    defaultDatalabelsFontWeight,
    defaultTicksCustomValue
} from "../../../utils";

const AccidentsCirculation = {
    checked: false,
    titleSideBar: "Accidents de la circulation",
    titleIndicator: "SUMP Trajectory Content.charts.AccidentsCirculation.title",
    popupInformation: {
        status: "false",
        text: popupInformation[partSumpTrajectory]["accidentsCirculation"],
    },
    keyFigure: {
        typeUpdate: "geographical_area", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "bar",
            typeLabels: "years", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "year", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "true",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "320",
            widthChart: "400",
            scales: {
                x: {
                    type: "category",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    min: null,
                    beginAtZero: false,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: true,
                    titleText: "SUMP Trajectory Content.charts.AccidentsCirculation.axis.titleY",
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 500,
                    ticks: {
                        display: true,
                        value: {
                            prefix: "",
                            suffix: ""
                        }
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: {
                    type: 'line',
                    xMin: undefined,
                    xMax: undefined,
                    yMin: 200,
                    yMax: 200,
                    borderColor: 'rgb(7, 153, 146)',
                    borderDash: [7, 7],
                    borderWidth: 3,
                },
                annotation2: {
                    type: 'line',
                    xMin: undefined,
                    xMax: undefined,
                    yMin: 300,
                    yMax: 300,
                    borderColor: 'rgb(184, 233, 148)',
                    borderDash: [7, 7],
                    borderWidth: 3,
                }
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.AccidentsCirculation.legend.Valeur cible 2030",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=accidents_circulation&trend=projection&chart=accidents_circulation_valeur_cible_2030&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_target_value_2030"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_target_value_2030"].borderColor["0"],
                    borderWidth: 4,
                    borderDash: [7, 7],
                    pointStyle: "circle",
                    pointRadius: 0,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 1,
                    categoryPercentage: 1,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "line",
                    label: "SUMP Trajectory Content.charts.AccidentsCirculation.legend.Valeur cible 2040",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=accidents_circulation&trend=projection&chart=accidents_circulation_valeur_cible_2040&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["trajectory_target_value_2040"].backgroundColor["0"],
                    borderColor: configChartsColorPalettes["trajectory_target_value_2040"].borderColor["0"],
                    borderWidth: 4,
                    borderDash: [7, 7],
                    pointStyle: "circle",
                    pointRadius: 0,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "",
                    barPercentage: 1,
                    categoryPercentage: 1,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
                {
                    type: "bar",
                    label: "SUMP Trajectory Content.charts.AccidentsCirculation.legend.Accidents",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=sump-trajectory&type_label=rabat_years&indicator=accidents_circulation&trend=reality&chart=accidents_circulation&chart_data=True&indicator_key_figure=False&schema_city=",
                    backgroundColor: configChartsColorPalettes["8"].backgroundColor["1"],
                    borderColor: configChartsColorPalettes["8"].borderColor["1"],
                    borderWidth: 2,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 3,
                    tooltipUnit: "",
                    barPercentage: 0.8,
                    categoryPercentage: 0.4,
                    datalabelsProperties: {
                        display: false,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "",
                        }
                    },
                },
            ],
        },
    ],
};

export default AccidentsCirculation;
