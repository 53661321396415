import { api_url } from "../../../utils";

const RevenuMenages = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=revenu_menages&schema_city=",
    zindex: 2,
    type: "categorized",
    popup: {
        default: {
            content: {
                fr: {
                    l_comar: "Nom de la commune ou de l'arrondissement : ",
                    rev_men: "Revenu moyen par ménage (DH) : ",
                    p_budg_t: "Part du budget transport (%) : ",
                    typ_rev: "",
                },
                en: {
                    l_comar: "Name of the Municipality or District: ",
                    rev_men: "Average Household Income (DH): ",
                    p_budg_t: "Percentage of Transport Budget (%): ",
                    typ_rev: "",
                }
            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.RevenuMenages",
    style: {
        regular: false,
        fillColor: "rgba(0, 0, 0)",
        strokeColor: "rgb(0, 0, 0)",
        strokeWidth: 0,
        strokeLineDash: [],
        points: 0,
        radius: 0,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.RevenuMenages.title",
            subtitle: "",
            attribute: "typ_rev",
            symbol: "rectangle",
            legendCustomCssClass: "",
            class: [
                {
                    fillColor: "rgb(78, 153, 196)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    value: "Classe inférieure",
                    label: "Mobility Indicators Content.layersLegend.RevenuMenages.classes.1",
                },
                {
                    fillColor: "rgb(197, 242, 243)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    value: "Classe moyenne et inférieure",
                    label: "Mobility Indicators Content.layersLegend.RevenuMenages.classes.2",
                },
                {
                    fillColor: "rgb(253, 244, 159)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    value: "Classe moyenne",
                    label: "Mobility Indicators Content.layersLegend.RevenuMenages.classes.3",
                },
                {
                    fillColor: "rgb(250, 222, 82)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    value: "Classe moyenne et supérieure",
                    label: "Mobility Indicators Content.layersLegend.RevenuMenages.classes.4",
                },
                {
                    fillColor: "rgb(239, 160, 133)",
                    strokeColor: "rgb(35, 35, 35)",
                    strokeWidth: 1,
                    value: "Classe supérieure",
                    label: "Mobility Indicators Content.layersLegend.RevenuMenages.classes.5",
                },
            ],
        },
    },
};

export default RevenuMenages;
