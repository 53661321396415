import { api_url } from "../../../utils";

const GaresFerroviaires = {
    enable: true,
    checked: false,
    initialDisplay: true,
    excludeCities: [],
    source: "api",
    url: api_url + "maps/geojson/?table=gares_ferroviaires&schema_city=",
    zindex: 3,
    type: "single",
    popup: {
        default: {
            content: {
                fr: {
                    l_gare: "Nom gare ferroviaire :",
                },
                en: {
                    l_gare: "Name of Railway Station: "
                }

            },
            separators: {
                decimal: [],
                thousand: [],
            },
        },
    },
    titleSideBar: "Mobility Indicators Content.layers.GaresFerroviaires",
    style: {
        regular: false,
        fillColor: "rgba(182, 36, 109, 0.67)",
        strokeColor: "rgb(182, 36, 109)",
        strokeWidth: 3,
        strokeLineDash: [],
        points: 0,
        radius: 10,
        angle: 0,
        rotation: 0,
    },
    legend: {
        default: {
            title: "Mobility Indicators Content.layersLegend.GaresFerroviaires.title",
            subtitle: "",
            attribute: "",
            symbol: "point",
            legendCustomCssClass:
                "map-legend-symbol-border-3px-pink-182-36-109",
            class: [
                {
                    fillColor: "rgba(182, 36, 109, 0.67)",
                    strokeColor: "rgb(182, 36, 109)",
                    strokeWidth: 0,
                    label: "Mobility Indicators Content.layersLegend.GaresFerroviaires.classes.1",
                },
            ],
        },
    },
};

export default GaresFerroviaires;
