import {
    api_url,
    legendLabelsBoxHeightLine,
    configChartsColorPalettes,
    yRight,
    partMobilityIndicators,
    popupInformation,
    defaultBarPercentage,
    defaultDatalabelsAlign,
    defaultDatalabelsAnchor,
    defaultDatalabelsFontWeight,
    defaultTicksCustomValue
} from "../../../utils";

const TauxImmobilite = {
    checked: false,
    titleSideBar: "Mobility Indicators Content.indicators.TauxImmobilite",
    titleIndicator: "Mobility Indicators Content.indicators.TauxImmobilite",
    popupInformation: {
        status: "false",
        text: popupInformation[partMobilityIndicators]["tauxImmobilite"],
    },
    keyFigure: {
        typeUpdate: "geographical_area", // year or geographical_area
        label: "",
        url: "",
        separator: "",
        position: "",
    },
    charts: [
        {
            typeChart: "bar",
            typeLabels: "geographical_areas", // years or geographical_areas
            multiYearGeographicalArea: true,
            singleYearGeographicalAreaType: "geographical_area", // year or geographical_area : MUST BE CONSISTENT WITH THE TYPE_LABEL ARGUMENT OF THE DATASET URL
            titleChart: "",
            titleDisplay: "true",
            legendDisplay: "false",
            legendCustom: "false",
            legendLabelsBoxHeight: legendLabelsBoxHeightLine,
            maintainAspectRatio: true,
            indexAxis: "x", // x or y
            heightChart: "250",
            widthChart: "500",
            i18nKeyLabels: "Mobility Indicators Content.charts.TauxImmobilite.axis.labelsX",
            scales: {
                x: {
                    type: "category",
                    display: true,
                    stacked: false,
                    titleDisplay: false,
                    titleText: "",
                    gridDisplay: false,
                    beginAtZero: false,
                    ticks: {
                        display: true,
                        value: defaultTicksCustomValue
                    }
                },
                y: {
                    type: "linear",
                    display: true,
                    stacked: false,
                    titleDisplay: true,
                    titleText: ["Mobility Indicators Content.charts.TauxImmobilite.axis.titleY1",
                        "Mobility Indicators Content.charts.TauxImmobilite.axis.titleY2"
                    ],
                    gridDisplay: true,
                    beginAtZero: true,
                    max: 100,
                    ticks: {
                        display: true,
                        value: {
                            prefix: "",
                            suffix: "%"
                        }
                    }
                },
                yRight: yRight,
            },
            annotationsProperties: {
                annotation1: undefined,
                annotation2: undefined
            },
            keyFigure: {
                typeUpdate: "geographical_area", // year or geographical_area
                label: "",
                url: "",
                separator: "",
                position: "",
            },
            datasets: [
                {
                    type: "bar",
                    label: "",
                    data: [],
                    url:
                        api_url +
                        "charts/data/?part=mobility-indicators&type_label=rabat_geographical_areas&indicator=taux_immobilite&trend=projection&chart=taux_immobilite&schema_city=",
                    backgroundColor: configChartsColorPalettes["8"].backgroundColor,
                    borderColor: configChartsColorPalettes["8"].borderColor,
                    borderWidth: 3,
                    pointStyle: "circle",
                    pointRadius: 3,
                    spanGaps: true,
                    legendCustomCssClass: "",
                    fill: false,
                    yAxisID: "y",
                    order: 2,
                    tooltipUnit: "%",
                    barPercentage: defaultBarPercentage,
                    categoryPercentage: 0.3,
                    datalabelsProperties: {
                        display: true,
                        anchor: defaultDatalabelsAnchor,
                        align: defaultDatalabelsAlign,
                        font: {
                            weight: defaultDatalabelsFontWeight
                        },
                        value: {
                            prefix: "",
                            suffix: "%",
                        }
                    },
                },
            ],
        },
    ],
};

export default TauxImmobilite;
